import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Label } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import AccountStore from "../../Stores/AccountStore";
import { setDateFormatForDateTime } from "../CommonComp/Functions";
import "../../Css/account-details.css";

const AccountDetails = () => {
  const { t } = useTranslation();
  const { accountData } = useContext(AccountStore);

  return (
    <>
      {accountData ? (
        accountData.Type === "Company" ? (
          <span className="account-details">
            <div className="personal">
              <label>{t("account.company-info")}</label>
              <div>
                <p>{t("account.company-name")}</p>
                <span>
                  {accountData.BrandName ? accountData.BrandName : "-"}
                </span>
              </div>
              <div>
                <p>{t("account.Vat-number")}</p>
                <span>
                  {accountData.CompanyVatNumber
                    ? accountData.CompanyVatNumber
                    : "-"}
                </span>
              </div>
              <label>{t("account.address")}</label>
              {accountData.Addresses ? (
                accountData.Addresses.map((elem, i) => {
                  if (elem.AddressType === "Work") {
                    return (
                      <div key={i}>
                        <span>
                          {elem.StreetAddress
                            ? elem.StreetAddress +
                              " " +
                              elem.StreetNumber +
                              ", " +
                              elem.City
                            : "-"}
                        </span>
                      </div>
                    );
                  }
                })
              ) : (
                <></>
              )}
            </div>
            <div className="address-contact">
              <div className="contact">
                <label>{t("account.legal-representative")}</label>
                <div>
                  <p>{t("account.name")}</p>
                  <span>
                    {accountData.FirstName ? accountData.FirstName : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.last-name")}</p>
                  <span>{accountData.Surname ? accountData.Surname : "-"}</span>
                </div>
                <div>
                  <p>{t("account.father-name")}</p>
                  <span>
                    {accountData.MiddleName ? accountData.MiddleName : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.gender")}</p>
                  <span>{accountData.Gender ? accountData.Gender : "-"}</span>
                </div>
                <div>
                  <p>{t("account.birth-date")}</p>
                  <span>
                    {accountData.DateOfBirth
                      ? setDateFormatForDateTime(accountData.DateOfBirth)
                      : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.vat-number")}</p>
                  <span>
                    {accountData.VatNumber ? accountData.VatNumber : "-"}
                  </span>
                </div>
                <div>
                  <p>E-mail</p>
                  <span>{accountData.Email ? accountData.Email : "-"}</span>
                </div>
                <div>
                  <p>E-mail 2</p>
                  <span>{accountData.Email2 ? accountData.Email2 : "-"}</span>
                </div>
              </div>
            </div>
          </span>
        ) : (
          <span className="account-details">
            <div className="personal">
              <Label text={t("account.personal-information")} />
              <div>
                <p>{t("account.name")}</p>
                <span>
                  {accountData.FirstName ? accountData.FirstName : "-"}
                </span>
              </div>
              <div>
                <p>{t("account.last-name")}</p>
                <span>{accountData.Surname ? accountData.Surname : "-"}</span>
              </div>
              <div>
                <p>{t("account.father-name")}</p>
                <span>
                  {accountData.MiddleName ? accountData.MiddleName : "-"}
                </span>
              </div>
              <div>
                <p>{t("account.gender")}</p>
                <span>{accountData.Gender ? accountData.Gender : "-"}</span>
              </div>
              <div>
                <p>{t("account.birth-date")}</p>
                <span>
                  {accountData.DateOfBirth
                    ? setDateFormatForDateTime(accountData.DateOfBirth)
                    : "-"}
                </span>
              </div>
              <div>
                <p>{t("account.nationality")}</p>
                <span>
                  {accountData.Nationality ? accountData.Nationality : "-"}
                </span>
              </div>
              <div>
                <p>{t("account.citizenship")}</p>
                <span>
                  {accountData.Citizenship ? accountData.Citizenship : "-"}
                </span>
              </div>
            </div>
            <div className="address-contact">
              <div className="address">
                <Label text="Διευθύνσεις" />
                {accountData.Addresses ? (
                  accountData.Addresses.map((elem, i) => {
                    if (elem.AddressType === "Home") {
                      return (
                        <div key={i}>
                          <p>{t("account.residence-address")}</p>
                          <span>
                            {elem.StreetAddress
                              ? elem.StreetAddress +
                                " " +
                                elem.StreetNumber +
                                ", " +
                                elem.City
                              : "-"}
                          </span>
                        </div>
                      );
                    }
                    if (elem.AddressType === "Work") {
                      return (
                        <div key={i}>
                          <p>{t("account.department-labor")}</p>
                          <span>
                            {elem.StreetAddress
                              ? elem.StreetAddress +
                                " " +
                                elem.StreetNumber +
                                ", " +
                                elem.City
                              : "-"}
                          </span>
                        </div>
                      );
                    }
                  })
                ) : (
                  <>
                    <div>
                      <p>{t("account.residence-address")}</p>
                      <span>-</span>
                    </div>
                    <div>
                      <p>{t("account.department-labor")}</p>
                      <span>-</span>
                    </div>
                  </>
                )}
              </div>
              <div className="contact">
                <Label text={t("account.contact-info")} />
                <div>
                  <p>E-mail</p>
                  <span>{accountData.Email ? accountData.Email : "-"}</span>
                </div>
                <div>
                  <p>E-mail 2</p>
                  <span>{accountData.Email2 ? accountData.Email2 : "-"}</span>
                </div>
                <div>
                  <p>{t("account.home-telephone-number")}</p>
                  <span>
                    {accountData.PhoneHome ? accountData.PhoneHome : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.work-phone")}</p>
                  <span>
                    {accountData.PhoneWork ? accountData.PhoneWork : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.mobile-phone")} 1</p>
                  <span>
                    {accountData.PhoneCellular1
                      ? accountData.PhoneCellular1
                      : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.mobile-phone")} 2</p>
                  <span>
                    {accountData.PhoneCellular2
                      ? accountData.PhoneCellular2
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="id">
              <Label text="Στοιχεία Ταυτότητας" />
              <div>
                <p>{t("account.identity-type")}</p>
                <span>{accountData.IdType ? accountData.IdType : "-"}</span>
              </div>
              <div>
                <p>{t("account.id-number")}</p>
                <span>{accountData.IdNumber ? accountData.IdNumber : "-"}</span>
              </div>
              <div>
                <p>{t("account.vat-number")}</p>
                <span>
                  {accountData.VatNumber ? accountData.VatNumber : "-"}
                </span>
              </div>
              <div>
                <p>ΑΜΚΑ</p>
                <span>
                  {accountData.SocialSecurityNumber
                    ? accountData.SocialSecurityNumber
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profession-ensurance">
              <div className="id">
                <Label text="Επάγγελμα" />
                <div>
                  <p>{t("account.profession")} 1</p>
                  <span>
                    {accountData.Occupation1 ? accountData.Occupation1 : "-"}
                  </span>
                </div>
                <div>
                  <p>{t("account.profession")} 2</p>
                  <span>
                    {accountData.Occupation2 ? accountData.Occupation2 : "-"}
                  </span>
                </div>
              </div>
            </div>
          </span>
        )
      ) : (
        <></>
      )}
    </>
  );
};
export default observer(AccountDetails);
