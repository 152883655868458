import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import LoaderStore from "../../Stores/LoaderStore";
import "../../Css/spinner.css";

const Loader = () => {
  const loaderStore = useContext(LoaderStore);
  const { mainContentLoader, loaderActive, loaderForData, customerSupport } =
    loaderStore;

  let [showLoader, setShowLoader] = React.useState(false);

  React.useEffect(() => {
    if (!customerSupport) {
      setShowLoader(loaderActive || loaderForData || mainContentLoader);
    } else {
      setShowLoader(loaderActive || mainContentLoader);
    }
  }, [mainContentLoader, loaderActive, loaderForData]);

  if (showLoader) {
    return (
      <div id="main-overlay">
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      </div>
    );
  }
};

export default observer(Loader);
