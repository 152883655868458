import React, { useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Link,
  AttentionBlock,
  Paragraph,
  Divider,
} from "@nn-design-system/react-component-library";
import "../../Css/Home/eServices.css";
import SettingsStore from "../../Stores/SettingsStore";

const EServices = () => {
  const { t } = useTranslation();
  const settingsStore = useContext(SettingsStore);
  const { privateSettings } = settingsStore;

  if (!privateSettings.EServicesURL) return null;

  return (
    <div className="nn-container eServices">
      <AttentionBlock variant="Warning">
        <Paragraph>
          <Trans i18nKey="eservices.notification">
            <strong>Σημαντική ενημέρωση:</strong> Ολοκληρώνεται σταδιακά η
            μεταφορά των ασφαλιστηρίων σας στο myNN. Ελέγξτε την ενότητα «Τα
            Μηνύματά μου» για να ενημερωθείτε εάν η μεταφορά των ασφαλιστηρίων
            σας έχει ολοκληρωθεί.
          </Trans>
        </Paragraph>
      </AttentionBlock>
      <div className="eServices-action">
        <Paragraph mt="0">{t("eservices.notification-continue")}</Paragraph>
        <Link
          href={privateSettings.EServicesURL}
          text="e-Services"
          target="_blank"
          rel="noopener noreferrer "
        />
      </div>
      <Divider />
    </div>
  );
};

export default EServices;
