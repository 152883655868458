import React from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ElementDownload from "../Document/ElementDownload";

const GroupPensionCertificatesNNTable = (props) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "a",
      flex: 1.5,
      headerAlign: "left",

      headerName: `${t("contract.certificate-type")} / ${t("contract.certificate")}`,
      align: "left",
      sortable: true,
      cellClassName: "whiteSpaceNormal",
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "b",
      headerName: t("contract.year"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "c",
      headerName: t("document.download"),
      headerAlign: "center",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ElementDownload
              fileName={`${params.value.CertificateName}-${params.row.b}`}
              DocumentLink={params.value.DownloadUrl}
              MimeType={params.value.FileType}
              method="POST"
              postData={params.value.CertificateData}
            />
          </div>
        );
      },
    },
  ];

  const rows = props.tableData?.map((elem, i) => {
    return {
      id: i,
      a: elem.CertificateName,
      b: elem.Year,
      c: {
        DownloadUrl: elem.DownloadUrl,
        FileType: elem.FileType,
        CertificateData: elem.CertificateData,
        CertificateName: elem.CertificateName,
      },
    };
  });

  return (
    <div>
      <Table
        rows={rows}
        columns={columns}
        density="comfortable"
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        localeText={{ noRowsLabel: t("global.no-results-found") }}
      />
    </div>
  );
};

export default GroupPensionCertificatesNNTable;
