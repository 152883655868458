import React, { useContext } from "react";
import img from "../../assets/index";
import { Link } from "react-router-dom";
import UserStore from "../../Stores/UserStore";
import { useEffect } from "react";
import { Icon } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
const RegFailAccExists = (props) => {
  const { t } = useTranslation();
  const userStore = useContext(UserStore);

  useEffect(() => {
    userStore.resetRegistrationStatus();
  }, [userStore]);

  return (
    <div>
      <h2 className="success">
        <img src={img.failure} alt="failure" />
        {t("register.con-not-completed")}
      </h2>
      <div className="form-element">
        <span>
          {t("register.account-already-exist")} <br />
          <br />
          {t("register.make-sure-info-correct")} <br />
          <br />
          {t("register.speak-representative")} <b>+30 210 950 6000</b>.
        </span>
      </div>
      <div className="form-element">
        <div className="chevron-link-icon">
          <Link
            to={props.partnerRegistration ? "/partner/register" : "/register"}
          >
            <Icon name={"ChevronRight"} variant="Small" color="#EA650D" />
            <span>{t("register.return-registration-form")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegFailAccExists;
