import React from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
export const CoverageTableHRNN = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "CoverageDescription",
      headerName: t("contract.coverage-name"),
      flex: 2.5,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
    {
      field: "CoverageStartDate",
      headerName: t("hr.joining-date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => setDateFormatForDateTime(props.value),
    },
  ];

  const rows = data.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
