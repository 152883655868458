import React, { Fragment } from "react";
import "../../Css/Contracts_Details/selected-contract-intermediary.css";
import { Icon } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";

const ContractSelectedIntermediary = (props) => {
  const { t } = useTranslation();
  const isAgentValid = (agentNelisId) => {
    return agentNelisId !== "02154741" && agentNelisId !== "02154714";
  };

  return (
    <Fragment>
      {props.Agent &&
      props.Agent.AgentNelisId &&
      isAgentValid(props.Agent.AgentNelisId) === true ? (
        <div className="selected-contract-intermediary">
          <div className="intermediary">
            <p>{t("contract.partner")}</p>
            <span>
              {props.Agent.FirstName && props.Agent.Surname
                ? props.Agent.FirstName + " " + props.Agent.Surname
                : "-"}
            </span>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="Envelope"
                color="#EA650D"
              />
              {props.Agent.Email ? props.Agent.Email : "-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="PhoneReceiver"
                color="#EA650D"
              />
              {props.Agent.PhoneNumber ? props.Agent.PhoneNumber : "-"}
            </div>
          </div>
          <div className="sales-office-details">
            <p>{t("contract.sales-channel-details")}</p>
            <span>
              {" "}
              {props.Agent.ManagerFullName ? props.Agent.ManagerFullName : "-"}
            </span>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="Envelope"
                color="#EA650D"
              />
              {props.Agent.AgencyEmail ? props.Agent.AgencyEmail : "-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="PhoneReceiver"
                color="#EA650D"
              />
              {props.Agent.AgencyPhone ? props.Agent.AgencyPhone : "-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="LocationPin"
                color="#EA650D"
              />
              {props.Agent.AgencyAddress ? (
                <>
                  {props.Agent.AgencyAddress.StreetAddress
                    ? props.Agent.AgencyAddress.StreetAddress + " "
                    : null}
                  {props.Agent.AgencyAddress.StreetNumber
                    ? props.Agent.AgencyAddress.StreetNumber
                    : null}
                  {props.Agent.AgencyAddress.City
                    ? ", " + props.Agent.AgencyAddress.City
                    : null}
                  {props.Agent.AgencyAddress.PostalCode
                    ? ", TK " + props.Agent.AgencyAddress.PostalCode
                    : null}
                </>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="selected-contract-intermediary">
          <div className="intermediary">
            <p>{t("contract.partner")}</p>
            <span>{"-"}</span>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="Envelope"
                color="#EA650D"
              />
              {"-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="PhoneReceiver"
                color="#EA650D"
              />
              {"-"}
            </div>
          </div>
          <div className="sales-office-details">
            <p>{t("contract.sales-channel-details")}</p>
            <span>{"-"}</span>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="Envelope"
                color="#EA650D"
              />{" "}
              {"-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="PhoneReceiver"
                color="#EA650D"
              />{" "}
              {"-"}
            </div>
            <div className="icon-text">
              <Icon
                mr={"10px"}
                variant="Medium"
                name="LocationPin"
                color="#EA650D"
              />
              {"-"}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ContractSelectedIntermediary;
