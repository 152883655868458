import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  Select,
  AmountInput,
  TextInput,
  DatepickerInput,
  Popover,
  Paragraph,
} from "@nn-design-system/react-component-library";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType } from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import moment from "moment";

const Pharmacy = (props) => {
  const { t } = useTranslation();
  const invalidValueText = t("claim.submission.invalid-value");
  const [physicianSpecialty, setPhysicianSpecialty] = useState("");
  const [physicianSpecialtyError, setPhysicianSpecialtyError] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [receiptDateError, setReceiptDateError] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [receiptAmountError, setReceiptAmountError] = useState("");
  const [otherInsurerContributionAmount, setOtherInsurerContributionAmount] =
    useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptNumberError, setReceiptNumberError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);

  const claimsStore = useContext(ClaimsStore);

  const {
    basicSubmissionInfoToSubmit,
    doctorSpecialities,
    expenseTypes,
    documentTypes,
  } = claimsStore;

  const currentDateValue = useMemo(() => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }, []);
  const eventDateValue = useMemo(() => {
    let eventDate = new Date(basicSubmissionInfoToSubmit.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate;
  }, [basicSubmissionInfoToSubmit.eventDate]);

  const validatePhysicianSpecialty = useCallback(
    (value) => {
      if (value !== "") {
        setPhysicianSpecialtyError("");
        return true;
      }
      setPhysicianSpecialtyError(invalidValueText);
      return false;
    },
    [setPhysicianSpecialtyError],
  );

  const validateReceiptDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - eventDateValue.getTime() < 0
        ) {
          setReceiptDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setReceiptDateError("");
          return true;
        }
        setReceiptDateError(invalidValueText);
        return false;
      }
      setReceiptDateError(invalidValueText);
      return false;
    },
    [currentDateValue, eventDateValue, setReceiptDateError],
  );

  const handleEventDateChange = useCallback(
    (value) => {
      if (validateReceiptDate(value)) {
        setReceiptDate(value);
      } else {
        setReceiptDate("");
      }
    },
    [validateReceiptDate],
  );

  const validateReceiptAmount = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptAmountError("");
        return true;
      }
      setReceiptAmountError(invalidValueText);
      return false;
    },
    [setReceiptAmountError],
  );

  const validateReceiptNumber = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptNumberError("");
        return true;
      }
      setReceiptNumberError(invalidValueText);
      return false;
    },
    [setReceiptNumberError],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validatePharmacyInfo = useCallback(() => {
    const isPhysicianSpecialty = validatePhysicianSpecialty(physicianSpecialty);
    const isReceiptDate = validateReceiptDate(receiptDate);
    const isReceiptAmount = validateReceiptAmount(receiptAmount);
    const isReceiptNumber = validateReceiptNumber(receiptNumber);
    const isFilesValid = validateFiles(files);

    return (
      isPhysicianSpecialty &&
      isReceiptDate &&
      isReceiptAmount &&
      isReceiptNumber &&
      isFilesValid
    );
  }, [
    validatePhysicianSpecialty,
    validateReceiptDate,
    validateReceiptAmount,
    validateReceiptNumber,
    validateFiles,
    physicianSpecialty,
    receiptDate,
    receiptAmount,
    receiptNumber,
    files,
  ]);

  const createNewPharmacyClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: ExpenseType.Pharmacies,
      PhysicianSpecialty: physicianSpecialty,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
      CollaboratingNetworkList: false,
    });
  }, [
    claimsStore,
    physicianSpecialty,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const saveEditedPharmacyClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit.Id,
      ExpenseType: ExpenseType.Pharmacies,
      PhysicianSpecialty: physicianSpecialty,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
      CollaboratingNetworkList: false,
    });
  }, [
    claimsStore,
    props.selectedClaimToEdit?.Id,
    physicianSpecialty,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (validatePharmacyInfo()) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedPharmacyClaim();
      } else {
        createNewPharmacyClaim();
      }
      props.nextStep();
    }
  }, [
    validatePharmacyInfo,
    createNewPharmacyClaim,
    saveEditedPharmacyClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const renderReceiptAmount = useCallback(() => {
    if (receiptAmountError === "") {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
        />
      );
    } else {
      return (
        <AmountInput
          labelText={t("claim.amount-payable")}
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
          validationText={receiptAmountError}
        />
      );
    }
  }, [
    receiptAmount,
    receiptAmountError,
    setReceiptAmount,
    validateReceiptAmount,
  ]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      setPhysicianSpecialty(props.selectedClaimToEdit.PhysicianSpecialty);
      setReceiptDate(props.selectedClaimToEdit.ReceiptDate);
      setReceiptAmount(props.selectedClaimToEdit.ReceiptAmount);
      setOtherInsurerContributionAmount(
        props.selectedClaimToEdit.OtherInsurerContributionAmount,
      );
      setReceiptNumber(props.selectedClaimToEdit.ReceiptNumber);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [
    props.selectedClaimToEdit,
    setPhysicianSpecialty,
    setReceiptDate,
    setReceiptAmount,
    setOtherInsurerContributionAmount,
    setReceiptNumber,
    setFiles,
  ]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const physicianSpecialtyOptions = useMemo(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    doctorSpecialities.forEach((s) => {
      options.push({
        text: s.Description,
        value: s.Code,
      });
    });

    return options;
  }, [doctorSpecialities]);

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find(
      (et) => et.Name === ExpenseType.Pharmacies,
    );

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }

    setFilesTypes(options);
  }, [documentTypes, expenseTypes, setFilesTypes]);

  return (
    <div>
      <div className={`${styles.note} ${styles.responsiveFontSize}`}>
        <Label text={t("claim.pharmacy.expenses-not-registered")} />
      </div>
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            labelText={t("claim.medical.visiting-physician")}
            name="PhysicianSpecialty"
            options={physicianSpecialtyOptions}
            onChange={(event) => {
              setPhysicianSpecialty(event.target.value);
              validatePhysicianSpecialty(event.target.value);
            }}
            value={physicianSpecialty}
            validationText={physicianSpecialtyError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={`${t("claim.table.header.receipt-invoice-date")}*`}
            name="ReceiptDate"
            placeholderText="ΗΗ/ΜΜ/ΕΕΕΕ"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={eventDateValue}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventDateChange(value);
            }}
            onAccept={(value) => {
              handleEventDateChange(value);
            }}
            value={receiptDate}
            validationText={receiptDateError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          {renderReceiptAmount()}
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <AmountInput
            labelText={t("claim.amount-other-insurance")}
            name="otherInsurerContributionAmount"
            popoverElement={
              <Popover
                headingText=" "
                placement="bottom-start"
                testId="popover"
                variant="Icon"
              >
                <div>
                  <Paragraph>{t("claim.examination.other-company")}</Paragraph>
                </div>
              </Popover>
            }
            onChange={(event) => {
              setOtherInsurerContributionAmount(event.target.value);
            }}
            value={otherInsurerContributionAmount}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <TextInput
            labelText={t("claim.medical.number-receipt-invoice")}
            name="ReceiptNumber"
            onChange={(event) => {
              setReceiptNumber(event.target.value);
              validateReceiptNumber(event.target.value);
            }}
            value={receiptNumber}
            validationText={receiptNumberError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div className={styles.nextStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("claim.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(Pharmacy);
