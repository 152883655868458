export const ExpenseCategory = Object.freeze({
  OutsideHospital: "OutHospitalExpenses",
  Allowance: "Allowances",
  OutOfHospitalMedicalProcedures: "OutHospitalMedicalProcedures",
});

export const ExpenseType = Object.freeze({
  MedicalVisit: "MedicalVisit",

  DiagnosticExamination: "DiagnosticExamination",

  Pharmacies: "Medicines",

  PhysicalTherapy: "Physiotherapy",
  PhysiotherapyWithAssociatePhysiotherapist: "CoopInNetwork",

  HospitalAllowance: "HospitalAllowance",

  SurgicalAllowance: "SurgicalAllowance",

  NormalMaternityAllowance: "Normal",
  CaesareanMaternityAllowance: "Caesarean",
  MiscarriageMaternityAllowance: "Miscarriage",

  OutOfHospitalMedicalProcedures: "OtherMedicalProcedures",
});

export const MedicalVisitTypes = Object.freeze({
  MedicalVisitWithCollaboratingDoctor: "CollaboratingDoctor",
  MedicalVisitWithCollaboratingDoctorAtClinic: "CollaboratingDoctorAtClinic",
  MedicalVisitWithCollaboratingDoctorAtClinicWithMedicalProcedure:
    "CollaboratingDoctorAtClinicWithMedicalProcedure",
  MedicalVisitWithCollaboratingDoctorAtHome: "CollaboratingDoctorAtHome",
});

export const DiagnosticExaminationTypes = Object.freeze({
  InNetworkDiagnosticExamination: "InNetwork",
  DoctorInNetworkDiagnosticExamination: "DoctorInNetwork",
  OutOfNetworkDiagnosticExamination: "OutOfNetwork",
});

export const ClaimState = Object.freeze({
  notSubmitted: 0,
  submitting: 1,
  processing: 2,
  failed: 3,
  timedOut: 4,
  successful: 5,
  inProgress: 6,
});
