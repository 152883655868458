import React, { Fragment } from "react";

const PersonalData = () => {
  return (
    <Fragment>
      <h2 className="modal-title">
        <span className="light">Δήλωση </span>
        <span className="normal">Προστασίας </span>
        <span className="dark">Προσωπικών </span>
        <span className="normal">Δεδομένων </span>
      </h2>

      {/* <h3 style={{ color: "#ee7f00" }}>Δήλωση Προστασίας Προσωπικών Δεδομένων</h3> */}
      <p className="text">
        <u>
          <b>Εισαγωγή:</b>
        </u>
        Η Εταιρία, με την επωνυμία “ΝΝ Ελληνική Μονοπρόσωπη Ανώνυμη Ασφαλιστική Εταιρία Ζωής” και έδρα επί της Λεωφ.
        Συγγρού 198, Καλλιθέα (στο εξής και χάριν συντομίας: «ΝΝ» ή «Εταιρία»), είναι υπεύθυνη για την επεξεργασία των
        προσωπικών σας δεδομένων στο πλαίσιο των παρεχόμενων υπηρεσιών.
      </p>
      <p className="text">
        <u>
          <b>Κατηγορίες Προσωπικών Δεδομένων Που Συλλέγουμε Και Επεξεργαζόμαστε:</b>
        </u>{" "}
        Η συλλογή και επεξεργασία των προσωπικών σας δεδομένων γίνεται από τους αρμόδιους, εξουσιοδοτημένους
        υπαλλήλους/συνεργάτες της Εταιρίας μας και αφορά στα στοιχεία που μας γνωστοποιείτε οικειοθελώς στo πλαίσιo
        υποβολής της αίτησης για την παροχή ασφαλιστικού προϊόντος από την Εταιρία μας και περιλαμβάνονται σε αυτή, στα
        συνοδευτικά έγγραφα και δικαιολογητικά, καθώς και σε άλλα προσωπικά δεδομένα που πιθανόν μας γνωστοποιήσετε οι
        ίδιοι στο μέλλον.
      </p>
      <p className="text">
        Στο πλαίσιο αυτό λαμβάνουμε απλά προσωπικά δεδομένα, όπως στοιχεία ταυτοποίησης, δημογραφικά, οικονομικά,
        στοιχεία επικοινωνίας μαζί σας, καθώς και ευαίσθητα προσωπικά δεδομένα σας σε περίπτωση ασφαλίσεων ζωής και
        υγείας, που αφορούν δηλαδή εκτός των άλλων και την υγεία σας (φυσική κατάσταση, τυχόν ανικανότητες και
        αναπηρίες, ιατρικό ιστορικό, χορήγηση φαρμάκων και λοιπά στοιχεία της υγείας σας), και είναι απαραίτητα για την
        ορθή και για τα δύο μέρη λειτουργία και εκτέλεση της ασφαλιστικής σύμβασης και την απόδοση του ασφαλίσματος σε
        περίπτωση επέλευσης ασφαλιστικού γεγονότος.
      </p>
      <p className="text">
        <u>
          <b>
            Σκοποί Επεξεργασίας Προσωπικών Δεδομένων: Η Εταιρία επεξεργάζεται τα ανωτέρω προσωπικά σας δεδομένα, για
            τους ακόλουθους σκοπούς:
          </b>
        </u>
        <br />α{")"} Προκειμένου να εκτιμήσει τον κίνδυνο στο πλαίσιο της ασφαλιστικής σύμβασης, να καθορίσει τους
        γενικούς και ειδικούς όρους αυτής καθώς και το ανάλογο ασφάλιστρο. <br /> β{")"} Για τη διαχείριση της
        ασφαλιστικής σύμβασης καθόλη τη διάρκεια ισχύος ή και μετά τη λήξη αυτής, συμπεριλαμβανομένης της εκτίμησης, του
        ελέγχου και του διακανονισμού της ασφαλιστικής αποζημίωσης σε περίπτωση επέλευσης του ασφαλιστικού κινδύνου ή
        και της καταβολής του προβλεπόμενου στους όρους της σύμβασης ποσού {"("}ασφαλίσματος{")"}. Σε περίπτωση
        ασφαλίσεων ζωής και υγείας για την εκπλήρωση των παραπάνω σκοπών επεξεργασίας, και καθόσον αφορά σε ευαίσθητα
        προσωπικά δεδομένα σας, είναι απαραίτητη η ρητή συγκατάθεσή σας στο σχετικό έντυπο. Η μη παροχή συγκατάθεσης ή
        των απαιτούμενων στοιχείων, καθώς και η τυχόν άρση της συγκατάθεσής σας στο μέλλον ή η διαπίστωση ύπαρξης
        ανακριβών ή αναληθών δηλώσεών σας κατά τη συμπλήρωση του ερωτηματολογίου υγείας θα παρέχει στην NN Hellas το
        δικαίωμα είτε άρνησης σύναψης ασφαλιστικής σύμβασης είτε καταγγελίας της ασφαλιστικής σας σύμβασης με άμεση
        αναδρομικά εξ αρχής ακύρωσή της, καθώς και άρνησής της εκπλήρωσης οποιασδήποτε υποχρέωσής της που απορρέει από
        την ασφαλιστική σύμβαση. Σε κάθε περίπτωση σας θυμίζουμε ότι έχετε δικαίωμα να ανακαλέσετε τη συγκατάθεσή σας
        ανά πάσα στιγμή, χωρίς βέβαια να θίγεται η νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεση προ της
        ανάκλησής της. Η ανάκληση της συγκατάθεσής σας θα έχει ως αποτέλεσμα την ακύρωση του ασφαλιστηρίου σας, διότι με
        την ανάκλησή σας καθίσταται αδύνατη για την NN Hellas η λειτουργία και η εκτέλεση της ασφαλιστικής σας σύμβασης.{" "}
        <br /> γ{")"} Στο πλαίσιο εκτίμησης του υπό ανάληψη ασφαλιστικού κινδύνου και της λήψης απόφασης επί της
        αιτούμενης ασφάλισης, σας γνωρίζουμε ότι η Εταιρία διενεργεί και πράξεις επεξεργασίας δεδομένων σας με την
        υποστήριξη αυτοματοποιημένων διαδικασιών, προκειμένου να καθορίσει (i) εάν η αίτησή σας να ασφαλιστείτε είναι
        αποδεκτή ή απορρίπτεται, (ii) σε περίπτωση αποδοχής, το κατάλληλο και αναλογικό ύψος του ασφαλίστρου και τους
        τυχόν ειδικούς όρους υπό τους οποίους μπορεί να συναφθεί το αιτούμενο από εσάς πρόγραμμα. Οι εν λόγω αποφάσεις
        βασίζονται σε μαθηματικές/ στατιστικές αναλύσεις των παραμέτρων εκείνων που καθιστούν δυνατή την αντικειμενική
        αξιολόγηση του κινδύνου και την ένταξή του σε ομοιογενή ομάδα κινδύνων με βάση τη συχνότητα και την ένταση των
        ζημιών που ενδέχεται να επιφέρει καθώς και την ορθή τιμολόγησή του. <br /> δ{")"} Επιπλέον, η Εταιρία
        επεξεργάζεται τα δεδομένα σας, κάνοντας ενδεχομένως χρήση και αυτοματοποιημένων διαδικασιών, στα πλαίσια
        συμμόρφωσής της με τις υποχρεώσεις που της επιβάλλονται από το εκάστοτε ισχύον νομικό και κανονιστικό πλαίσιο
        και ιδίως για σκοπούς σχετικούς με πρόληψη και καταστολή νομιμοποίησης εσόδων από εγκληματικές δραστηριότητες,
        την αποφυγή και καταπολέμηση της ασφαλιστικής απάτης και τη χρηματοδότηση της τρομοκρατίας, την προσφορά τίτλων
        αξιών σε φυσικά πρόσωπα, αμερικανούς πολίτες ή κατοίκους, καθώς και την εφαρμογή της ελληνικής φορολογικής
        νομοθεσίας, της ευρωπαϊκής και ελληνικής νομοθεσίας για την υποχρεωτική αυτόματη ανταλλαγή πληροφοριών στον
        φορολογικό τομέα και της αμερικάνικης νομοθεσίας FATCA. <br /> ε{")"} Τέλος η Εταιρία, μόνο εφόσον έχετε
        παράσχει τη ρητή συγκατάθεσή σας στο σχετικό έντυπο, θα χρησιμοποιήσει τα στοιχεία επικοινωνίας σας, προκειμένου
        να σας πληροφορεί για τυχόν νέες άλλες υπηρεσίες / προϊόντα. Στην περίπτωση αυτή σας θυμίζουμε ότι έχετε
        δικαίωμα να ανακαλέσετε τη συγκατάθεσή σας ανά πάσα στιγμή, χωρίς βέβαια να θίγεται η νομιμότητα της
        επεξεργασίας που βασίστηκε στη συγκατάθεση προ της ανάκλησής της.
      </p>
      <p className="text">
        <u>
          <b> Αποδέκτες: </b>
        </u>
        Τα δεδομένα σας μπορεί να διαβιβασθούν σε άλλες (αντ)ασφαλιστικές εταιρίες σε περίπτωση που προκύψει ζήτημα
        διερεύνησης του ενδεχόμενου ασφαλιστικής απάτης, σε συνδεδεμένες επιχειρήσεις του Ομίλου ΝΝ Group N.V., καθώς
        και σε συνεργαζόμενα με την Εταιρία φυσικά ή νομικά πρόσωπα στο πλαίσιο της νόμιμης λειτουργίας της ασφαλιστικής
        σύμβασης, στο βαθμό που αυτό είναι αναγκαίο για την καλύτερη εξυπηρέτησή σας και την παροχή των υπηρεσιών
        /ασφαλιστικών καλύψεων, όπως , εταιρίες διαχείρισης αποζημιώσεων για λογαριασμό της Εταιρίας, ασφαλιστικές
        εταιρίες ή άλλες επιχειρήσεις που σας παρέχουν υπηρεσίες σε εκτέλεση των όρων της ασφαλιστικής σας σύμβασης,
        εταιρίες φύλαξης και διατήρησης αρχείων για λογαριασμό της Εταιρίας, εταιρίες υπηρεσιών τηλεφωνικής εξυπηρέτησης
        πελατών, ασφαλιστικούς διαμεσολαβητές, δικηγόρους, ερευνητές, πραγματογνώμονες . Στην περίπτωση αυτή η Εταιρία
        δεσμεύεται ότι οι συνεργάτες της τελούν υπό τον έλεγχο και ενεργούν μόνον κατ’ εντολή της, έχουν εξουσιοδοτηθεί
        ειδικά για τον σκοπό αυτό και δεσμεύονται πλήρως από το απόρρητο και τις υποχρεώσεις που προβλέπονται στη
        νομοθεσία σχετικά με τη συλλογή και επεξεργασία των ανωτέρω δεδομένων. Τέλος, η Εταιρία ενδέχεται να
        γνωστοποιήσει τα δεδομένα σας στις αρμόδιες δημόσιες/δικαστικές αρχές, στο βαθμό που επιβάλλεται από το ισχύον
        νομοθετικό και κανονιστικό πλαίσιο, εφόσον υποβληθεί σχετικό αίτημα ή εφόσον οφείλει να υποβάλλει αναφορά με τα
        εν λόγω στοιχεία χωρίς προηγούμενη ειδική ενημέρωση. Ιδίως δε στα πλαίσια συμμόρφωσης με τους κανόνες αυτόματης
        ανταλλαγής πληροφοριών στο φορολογικό τομέα, όπως απορρέει από διεθνείς συνθήκες, προσωπικά σας δεδομένα
        ενδέχεται να διαβιβασθούν σε δημόσιες αρχές τρίτων χωρών.
      </p>
      <p className="text">
        <u>
          <b>Χρόνος διατήρησης δεδομένων:</b>
        </u>{" "}
        Η Εταιρία θα επεξεργάζεται τα προσωπικά σας δεδομένα καθ’ όλη τη διάρκεια ισχύος της ασφαλιστικής σύμβασης και
        για χρονικό διάστημα μέχρι είκοσι (20) έτη από τη λήξη αυτής με οποιονδήποτε τρόπο και μέχρι πέντε (5) έτη σε
        περίπτωση μη σύναψης της ασφαλιστικής σύμβασης, εκτός αν άλλως ορίσει το εκάστοτε ισχύον νομικό και κανονιστικό
        πλαίσιο και οι εγκεκριμένοι κώδικες δεοντολογίας.
      </p>
      <p className="text">
        <u>
          <b>Οι Δεσμεύσεις Μας:</b>
        </u>{" "}
        Δεσμευόμαστε να διατηρούμε τα δεδομένα σας ενημερωμένα και ακριβή, να τα αποθηκεύουμε και διαγράφουμε με
        ασφάλεια, να μην συλλέγουμε και διατηρούμε δεδομένα που δε μας είναι απαραίτητα, να προστατεύουμε τα δεδομένα
        σας από απώλεια, κακή χρήση, μη εξουσιοδοτημένη πρόσβαση ή αποκάλυψη αυτών και γενικά να εξασφαλίζουμε την
        ύπαρξη κατάλληλων τεχνικών και οργανωτικών μέτρων για την προστασία τους και τέλος να σας ενημερώσουμε για την
        περίπτωση διαρροής τους. Για να μπορέσουμε να ανταποκριθούμε σε αυτή τη δέσμευσή μας, είμαστε υποχρεωμένοι να
        επικαιροποιήσουμε τα δεδομένα σας, που ήδη τυχόν διαθέτουμε και επεξεργαζόμαστε για άλλες υπηρεσίες ή/και
        προϊόντα, με βάση τα δεδομένα που εσείς μας δίδετε. Στο πλαίσιο αυτό σας υπενθυμίζουμε ότι οφείλετε να
        ειδοποιήσετε χωρίς καθυστέρηση την Εταιρία για οποιαδήποτε αλλαγή επέλθει στα προσωπικά σας δεδομένα.
      </p>
      <p className="text">
        <u>
          <b>Τα δικαιώματά σας:</b>
        </u>
        Έχετε το δικαίωμα πρόσβασης, το δικαίωμα δηλαδή να ενημερωθείτε, κατόπιν αιτήματός σας, αφενός για το κατά πόσον
        ή όχι τα προσωπικά σας δεδομένα υφίστανται επεξεργασία και αφετέρου να λάβετε αντίγραφο και περαιτέρω
        πληροφορίες σχετικά με την πραγματοποιούμενη επεξεργασία. Επιπλέον, έχετε το δικαίωμα να ζητήσετε τη διόρθωση
        των ανακριβών προσωπικών σας δεδομένων ή τη συμπλήρωση αυτών. Εφόσον συντρέχουν οι προϋποθέσεις του Νόμου,
        μπορείτε να ασκήσετε το δικαίωμα διαγραφής, το δικαίωμα περιορισμού της επεξεργασίας, το δικαίωμα φορητότητας
        των δεδομένων σας, το δικαίωμα εναντίωσης στην επεξεργασία αυτών, καθώς και το δικαίωμα εξασφάλισης ανθρώπινης
        παρέμβασης σε αυτοματοποιημένες διαδικασίες λαμβάνοντας υπόψη ότι η συμμόρφωση της Εταιρίας με σχετικό αίτημά
        σας ενδέχεται να οδηγήσει στην ακύρωση της ασφαλιστικής σας σύβασης αν με αυτόν τον τρόπο παρεμποδίζεται η
        λειτουργία της. Για οποιοδήποτε αίτημα σχετικό με την επεξεργασία των δεδομένων σας παρακαλούμε όπως απευθύνεστε
        το συντομότερο δυνατόν στον υπεύθυνο προστασίας προσωπικών δεδομένων της Εταιρίας, Λεωφ. Συγγρού 198, 176 71
        Καλλιθέα, τηλ. 210 9506000, e-mail dpo@nnhellas.gr. Η άσκηση των δικαιωμάτων γίνεται ατελώς, εκτός αν το σχετικό
        αίτημα επαναλαμβάνεται συχνά και λόγω όγκου συνεπάγεται για την Εταιρία διοικητικό κόστος. Σε περίπτωση που
        θεωρείτε ότι θίγεται κατά οποιονδήποτε τρόπο η προστασία των προσωπικών σας δεδομένων, μπορείτε να προσφύγετε
        στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα, χρησιμοποιώντας τα ακόλουθα στοιχεία επικοινωνίας:
      </p>
      <p className="text">
        Ο λήπτης της ασφάλισης είναι αποκλειστικά υπεύθυνος για την διεύθυνση ηλεκτρονικού ταχυδρομείου (email) που
        δηλώνει, η οποία είναι υπό τον πλήρη και αποκλειστικό έλεγχό του. Η Εταιρεία δεν ευθύνεται για οποιαδήποτε ζημία
        του χρήστη από τυχόν πρόσβαση τρίτων στο ηλεκτρονικό του ταχυδρομείο.
      </p>
      <p className="text">
        Ιστοσελίδα: <a href="www.dpa.gr">www.dpa.gr</a>
        <br />
        Ταχυδρομική Διεύθυνση: Λεωφόρος Κηφισίας 1-3, Τ.Κ. 115 23, Αθήνα
        <br />
        Τηλεφωνικό Κέντρο: +30 210 6475600
        <br />
        Fax: +30 210 6475628 <br />
        Ηλεκτρονικό Ταχυδρομείο: contact@dpa.gr
        <br />
      </p>
    </Fragment>
  );
};

export default PersonalData;
