import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import CookieStore from "../../Stores/CookieStore";
import ContractStore from "../../Stores/ContractStore";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import {
  normalizeGreek,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import {
  RadioButton,
  RadioButtonGroup,
  SearchInput,
} from "@nn-design-system/react-component-library";
import "../../Css/contracts-table.css";
import "../../Css/search-contracts.css";
import { ContractTableNN } from "./ContractTableNN";
import { CommonList } from "../Account/CommonList";
import { RESTRICTED_ACCESS_CONTRACTS } from "./constants";
const ContractsTable = () => {
  const { t } = useTranslation();
  const cookieStore = useContext(CookieStore);
  const { isCustomerSupport } = cookieStore;
  const { contractList, contractHeader } = useContext(ContractStore);
  const [displayContractList, setDisplayContractList] = useState(null);
  const [filteredContractList, setFilteredContractList] = useState(null);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const [radioType, setRadioType] = useState("IF");
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 768) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  useEffect(() => {
    setList([...contractList.filter((el) => el.StatusId !== "PR")]);
  }, [contractList]);

  const checkAccess = useCallback(
    (contractNumber) =>
      sessionStorage.getItem("support") &&
      RESTRICTED_ACCESS_CONTRACTS.some((contract) =>
        contractNumber.includes(contract),
      ),
    [isCustomerSupport],
  );

  const navigate = useNavigate();

  const paymentsElemsIn = [
    {
      name: t("contracts.product-name"),
      element: (params) => (
        <span>
          <b>{params.ProductName}</b>
          <br />
          {params.DisplayNumber}
        </span>
      ),
    },
    {
      name: t("payments.due-date"),
      element: (params) => (
        <span>
          {setDateFormatForDateTime(
            params.IsGroupPolicy || params.IsGroupPensionPolicy
              ? ""
              : params.NextBillingDate,
          )}
          <br />
        </span>
      ),
    },
    {
      name: t("contracts.expiration-date"),
      element: (params) => (
        <span>
          <b>
            {setDateFormatForDateTime(
              params.IsGroupPensionPolicy ? "" : params.ExpiredDate,
            )}
          </b>
        </span>
      ),
    },
    {
      name: t("global.condition"),
      element: (params) => {
        return (
          <>
            {params.StatusId === "IF" ? (
              <span className="normal">{params.Status}</span>
            ) : (
              <span className="light-gray">{params.Status ?? "-"}</span>
            )}
          </>
        );
      },
    },
  ];

  const getContractByCategory = (name) => {
    return displayContractList.filter(
      (x) => x.ProductCategoryId === name && !x.IsGroupPensionPolicy,
    );
  };

  useEffect(() => {
    let statusFilteredList = getStatusFilteredContractList(radioType);
    let searchFilteredList = getSearchFilteredContractList(
      searchValue,
      statusFilteredList,
    );
    setFilteredContractList(searchFilteredList);
  }, [list]);

  useEffect(() => {
    setDisplayContractList(filteredContractList);
  }, [filteredContractList]);

  useEffect(() => {
    let radioFilteredList = getStatusFilteredContractList(radioType);
    let contractList = getSearchFilteredContractList(
      searchValue,
      radioFilteredList,
    );
    setFilteredContractList(contractList);
  }, [searchValue]);

  const searchHandler = (e) => {
    if (e === undefined) {
      setSearchValue("");
    } else if (e.target.value === "" || e.target.value === " ") {
      e.target.value = e.target.value.replace(/\s/g, "");
      setSearchValue("");
    } else {
      setSearchValue(e.target.value);
    }
  };

  const getSearchFilteredContractList = (searchTerm, listToSearch) => {
    if (!searchTerm) {
      searchTerm = "";
      return listToSearch;
    }

    return listToSearch.filter(
      (c) =>
        (c.Status
          ? normalizeGreek(c.Status.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            )
          : null) ||
        c.DisplayNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (c.PaymentFrequency
          ? normalizeGreek(c.PaymentFrequency.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            )
          : null) ||
        normalizeGreek(c.ProductName.toLowerCase()).includes(
          searchTerm.toLowerCase(),
        ) ||
        c.Insureds.find((c) => {
          if (c.FirstName) {
            normalizeGreek(c.FirstName.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            );
          }
        }) ||
        c.Insureds?.find((insured) => {
          if (insured.FirstName) {
            normalizeGreek(insured.FirstName.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            );
          }
        }) ||
        c.Insureds?.find((insured) => {
          if (insured.Surname) {
            normalizeGreek(insured.Surname.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            );
          }
        }),
    );
  };

  const getStatusFilteredContractList = (statusValue) => {
    if (statusValue === "IF") {
      if (searchValue) {
        return getSearchFilteredContractList(searchValue, list).filter(
          (c) =>
            c.StatusId.includes(statusValue) ||
            c.StatusId.includes("PU") ||
            c.StatusId.includes("SUS"),
        );
      }
      return list?.filter(
        (c) =>
          c.StatusId.includes(statusValue) ||
          c.StatusId.includes("PU") ||
          c.StatusId.includes("SUS"),
      );
    } else {
      if (searchValue) {
        return getSearchFilteredContractList(searchValue, list);
      }
      return list;
    }
  };

  const radioHandler = (e) => {
    if (e.target.value === "IF") {
      setRadioType("IF");
    } else {
      setRadioType("all");
    }
    setFilteredContractList(getStatusFilteredContractList(e.target.value));
  };

  const clickToDetails = (detail) => {
    const isRestrictedAccess = checkAccess(detail.Number);
    if (isRestrictedAccess) {
      return;
    }

    navigate("/myContracts/details", { state: toJS(detail) });
  };

  const renderGroupPensionContracts = () => {
    const contracts = displayContractList.filter((x) => x.IsGroupPensionPolicy);
    return contracts.length > 0 ? (
      <Fragment>
        <h2 className="table-label">{t("contract.group.pension")}</h2>
        {isSmallSize ? (
          contracts.map((elem, i) => {
            return (
              <CommonList
                key={i}
                data={paymentsElemsIn}
                element={elem}
                hasIcon={true}
                onClick={clickToDetails}
              />
            );
          })
        ) : (
          <ContractTableNN data={contracts} checkAccess={checkAccess} />
        )}
      </Fragment>
    ) : (
      <></>
    );
  };
  return (
    <>
      {displayContractList && contractHeader ? (
        <>
          <Fragment>
            <div className="nn-container contracts">
              <label
                htmlFor="search-contracts"
                className="search-contracts-label"
              >
                <SearchInput
                  labelText=""
                  onChange={searchHandler}
                  placeholderText={t("contract.contract-search")}
                  value={searchValue}
                  onEmptySearchInput={searchHandler}
                />
              </label>
              <div className="filter-contracts">
                <RadioButtonGroup
                  labelText=""
                  mt={"-15px"}
                  value={radioType}
                  onChange={radioHandler}
                  variant={"Default"}
                >
                  <div className={"radiogroup"}>
                    <RadioButton
                      labelText={t("contract.active-contracts")}
                      value={"IF"}
                    />
                    <RadioButton
                      ml={"25px"}
                      labelText={t("contract.all-contracts")}
                      value={"all"}
                    />
                  </div>
                </RadioButtonGroup>
              </div>
            </div>
          </Fragment>
          <div className="nn-container table-container contracts">
            {contractHeader.map((element, i) => {
              let newArray = getContractByCategory(element.BOName);
              return newArray.length > 0 ? (
                <Fragment key={i}>
                  <h2 className="table-label">{element.DisplayName}</h2>
                  {isSmallSize ? (
                    newArray.map((elem, i) => {
                      return (
                        <CommonList
                          key={i}
                          data={paymentsElemsIn}
                          element={elem}
                          hasIcon={true}
                          onClick={clickToDetails}
                        />
                      );
                    })
                  ) : (
                    <ContractTableNN
                      data={newArray}
                      elem={element}
                      checkAccess={checkAccess}
                    />
                  )}
                </Fragment>
              ) : null;
            })}
            {renderGroupPensionContracts()}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default observer(ContractsTable);
