import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

let baseURL = "NO URL";
if (process.env.REACT_APP_ENVIRONMENT === "local") {
  baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
}
if (process.env.REACT_APP_ENVIRONMENT === "debug") {
  baseURL = process.env.REACT_APP_BASE_URL_LOCAL_DEBUG;
}
if (process.env.REACT_APP_ENVIRONMENT === "dev") {
  baseURL = process.env.REACT_APP_BASE_URL_DEV;
}
if (process.env.REACT_APP_ENVIRONMENT === "test") {
  baseURL = process.env.REACT_APP_BASE_URL_TEST;
}
if (process.env.REACT_APP_ENVIRONMENT === "assesment") {
  baseURL = process.env.REACT_APP_BASE_URL_ASSESMENT;
}
if (process.env.REACT_APP_ENVIRONMENT === "live") {
  baseURL = process.env.REACT_APP_BASE_URL_LIVE;
}
if (process.env.REACT_APP_ENVIRONMENT === "uat") {
  baseURL = process.env.REACT_APP_BASE_URL_UAT;
}

let redirectUrlForAzure =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");

class B2cStore {
  // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-core/modules/_configuration_.html#configuration

  msalConfig = {
    auth: {
      //clientId: "acba4f8f-0d30-4b31-957e-3b7a385e8bd7", //myNN.dev.frontend.userFlows
      // authority: this.authorities.signIn.authority,
      validateAuthority: false,
      // redirectUri: `http://localhost:3000/azureb2cendpoint`,
      // postLogoutRedirectUri: `http://localhost:3000/azureb2cendpoint`,
      redirectUri: `${redirectUrlForAzure}/azureb2cendpoint`,
      postLogoutRedirectUri: `${redirectUrlForAzure}/azureb2cendpoint`,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  loginRequest = {
    scopes: ["openid"],
  };

  accessTokenRequest = {
    scopes: ["User.Read"],
  };

  constructor() {
    makeObservable(this, {
      msalConfig: observable,
      loginRequest: observable,
      accessTokenRequest: observable,

      callMeBack: action,
    });
  }

  //functions
  callMeBack = async (val) => {
    let response = await agent.chat.request(val);
    this.callMeBackResponse = response.Status;
  };
}

export default createContext(new B2cStore());
