import React, { useContext, useEffect, useState } from "react";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Button } from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import img from "../../assets/index";
import { getdate, ConvertDecimalToString } from "../CommonComp/Functions";

const OnlinePaymentSuccessPayment = () => {
  const { t } = useTranslation();
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const {
    paymentCode,
    transactionMessage,
    responsePayment,
    isChangeOnlinePaymentMehod,
  } = onlinePaymentStore;
  const [isDifferentTab, setIsDifferentTab] = useState(
    isChangeOnlinePaymentMehod,
  );

  const changeCard = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (changeCard !== isDifferentTab) {
      onlinePaymentStore.setIsChangeOnlinePaymentMehod(changeCard);
      onlinePaymentStore.setTransactionStatus(null);
      onlinePaymentStore.setContract(null);
      navigate(`/online-payment`);
    }

    return () => {
      sessionStorage.removeItem("IsChangeCard");
      onlinePaymentStore.setTransactionStatus(null);
    };
  }, []);

  const clearStatus = () => {
    onlinePaymentStore.setTransactionStatus(null);
    navigate("/online-payment");
  };

  return (
    <>
      {transactionMessage && paymentCode ? (
        <div>
          <h2 className="success">
            <img src={img.authorisation} alt="authorisation" />
            {transactionMessage}
          </h2>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.transaction-number")}:
              </span>
              <span> {paymentCode} </span>
            </div>
          </div>
          {responsePayment.AccountVerification !== "1" && (
            <>
              <div className="online-payment-form">
                <div className="form-element right-padded">
                  <span className="payBold">{t("global.how-much")}:</span>{" "}
                  <span>
                    {ConvertDecimalToString(responsePayment.PaidAmount)}{" "}
                  </span>
                </div>
              </div>
              <div className="online-payment-form">
                <div className="form-element right-padded">
                  <span className="payBold">{t("global.date")}:</span>{" "}
                  <span>{getdate(responsePayment.PaymentDate)} </span>
                </div>
              </div>
            </>
          )}
          <br />
          <Button mt={"20px"} variant="PrimaryMedium" onClick={clearStatus}>
            {t("global.return")}
          </Button>
        </div>
      ) : (
        <div>
          <h2 className="success">
            <img src={img.authorisation} alt="authorisation" />
            {t("online.payment.transaction-completed-succesfully")}
          </h2>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.transaction-number")}:
              </span>
              <span> - </span>
            </div>
          </div>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">{t("global.how-much")}:</span>{" "}
              <span> - </span>
            </div>
          </div>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">{t("global.date")}:</span>
              <span> - </span>
            </div>
          </div>
          <br />
          <Button mt={"20px"} variant="PrimaryMedium" onClick={clearStatus}>
            {t("global.return")}
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(OnlinePaymentSuccessPayment);
