import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  Checkbox,
  Select,
  AmountInput,
  TextInput,
  DatepickerInput,
  Popover,
  Paragraph,
  InlineMessage,
} from "@nn-design-system/react-component-library";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType, MedicalVisitTypes } from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import moment from "moment";

const MedicalVisit = (props) => {
  const { t } = useTranslation();
  const invalidValueText = t("claim.submission.invalid-value");
  const invalidCheckboxSelectionsMsg = t("claim.medical.type-visit-select");
  const [collaboratingPhysician, setCollaboratingPhysician] = useState("");
  const [atClinic, setAtClinic] = useState("Disabled");
  const [medicalActionsPerformed, setMedicalActionsPerformed] =
    useState("Disabled");
  const [atHome, setAtHome] = useState("Disabled");
  const [doctorsSpeciality, setDoctorsSpeciality] = useState("");
  const [doctorsSpecialityError, setDoctorsSpecialityError] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [receiptDateError, setReceiptDateError] = useState("");
  const [receiptAmount, setReceiptAmount] = useState("");
  const [receiptAmountError, setReceiptAmountError] = useState("");
  const [otherInsurerContributionAmount, setOtherInsurerContributionAmount] =
    useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [receiptNumberError, setReceiptNumberError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);
  const [continueBtnPressed, setContinueBtnPressed] = useState(false);

  const claimsStore = useContext(ClaimsStore);

  const {
    basicSubmissionInfoToSubmit,
    doctorSpecialities,
    expenseTypes,
    documentTypes,
  } = claimsStore;

  const collaboratingDoctorExpenseTypeIsMandatory = useMemo(() => {
    const collaboratingDoctorExpenseType = expenseTypes.find(
      (et) => et.Name === MedicalVisitTypes.MedicalVisitWithCollaboratingDoctor,
    );
    return collaboratingDoctorExpenseType?.IsMandatory;
  }, [expenseTypes]);

  const currentDateValue = useMemo(() => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }, []);
  const eventDateValue = useMemo(() => {
    let eventDate = new Date(basicSubmissionInfoToSubmit.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate;
  }, [basicSubmissionInfoToSubmit.eventDate]);

  const validateCollaboratingPhysicianCheckbox = useCallback(
    (cntBtnPressed) => {
      return !(
        cntBtnPressed &&
        (collaboratingPhysician === "Checked" ||
          collaboratingPhysician === "CheckedAndDisabled") &&
        atClinic !== "Checked" &&
        atHome !== "Checked"
      );
    },
    [collaboratingPhysician, atClinic, atHome],
  );

  const handleCollaboratingPhysicianCheckbox = useCallback(
    (event) => {
      setCollaboratingPhysician(
        event.target.checked ? "Checked" : "NotChecked",
      );
      if (!event.target.checked) {
        setAtClinic("Disabled");
        setMedicalActionsPerformed("Disabled");
        setAtHome("Disabled");
      } else {
        setAtClinic("");
        setMedicalActionsPerformed("Disabled");
        setAtHome("");
      }
      validateCollaboratingPhysicianCheckbox(continueBtnPressed);
    },
    [
      setCollaboratingPhysician,
      setAtClinic,
      setMedicalActionsPerformed,
      setAtHome,
      validateCollaboratingPhysicianCheckbox,
      continueBtnPressed,
    ],
  );

  const handleAtClinicCheckbox = useCallback(
    (event) => {
      setAtClinic(event.target.checked ? "Checked" : "NotChecked");
      if (!event.target.checked) {
        setMedicalActionsPerformed("Disabled");
        setAtHome("");
      } else {
        setMedicalActionsPerformed("");
        setAtHome("Disabled");
      }
      validateCollaboratingPhysicianCheckbox(continueBtnPressed);
    },
    [
      setAtClinic,
      setMedicalActionsPerformed,
      setAtHome,
      validateCollaboratingPhysicianCheckbox,
      continueBtnPressed,
    ],
  );

  const handleAtHomeCheckbox = useCallback(
    (event) => {
      setAtHome(event.target.checked ? "Checked" : "NotChecked");
      if (!event.target.checked) {
        setAtClinic("");
      } else {
        setAtClinic("Disabled");
      }
      validateCollaboratingPhysicianCheckbox(continueBtnPressed);
    },
    [
      setAtClinic,
      setAtHome,
      validateCollaboratingPhysicianCheckbox,
      continueBtnPressed,
    ],
  );

  const validateDoctorsSpeciality = useCallback(
    (value) => {
      if (value !== "") {
        setDoctorsSpecialityError("");
        return true;
      }
      setDoctorsSpecialityError(invalidValueText);
      return false;
    },
    [setDoctorsSpecialityError],
  );

  const validateReceiptDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - eventDateValue.getTime() < 0
        ) {
          setReceiptDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setReceiptDateError("");
          return true;
        }
        setReceiptDateError(invalidValueText);
        return false;
      }
      setReceiptDateError(invalidValueText);
      return false;
    },
    [currentDateValue, eventDateValue, setReceiptDateError],
  );

  const handleEventDateChange = useCallback(
    (value) => {
      if (validateReceiptDate(value)) {
        setReceiptDate(value);
      } else {
        setReceiptDate("");
      }
    },
    [validateReceiptDate],
  );

  const validateReceiptAmount = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptAmountError("");
        return true;
      }
      setReceiptAmountError(invalidValueText);
      return false;
    },
    [setReceiptAmountError],
  );

  const validateReceiptNumber = useCallback(
    (value) => {
      if (value !== "") {
        setReceiptNumberError("");
        return true;
      }
      setReceiptNumberError(invalidValueText);
      return false;
    },
    [setReceiptNumberError],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validateMedicalVisitInfo = useCallback(() => {
    const isCheckBoxesValid = validateCollaboratingPhysicianCheckbox(true);
    const isDoctorsSpeciality = validateDoctorsSpeciality(doctorsSpeciality);
    const isReceiptDate = validateReceiptDate(receiptDate);
    const isReceiptAmount = validateReceiptAmount(receiptAmount);
    const isReceiptNumber = validateReceiptNumber(receiptNumber);
    const isFilesValid = validateFiles(files);

    return (
      isCheckBoxesValid &&
      isDoctorsSpeciality &&
      isReceiptDate &&
      isReceiptAmount &&
      isReceiptNumber &&
      isFilesValid
    );
  }, [
    validateDoctorsSpeciality,
    doctorsSpeciality,
    validateReceiptDate,
    receiptDate,
    validateReceiptAmount,
    receiptAmount,
    validateReceiptNumber,
    receiptNumber,
    validateFiles,
    files,
    validateCollaboratingPhysicianCheckbox,
  ]);

  const createNewMedicalVisitClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: ExpenseType.MedicalVisit,
      CollaboratingPhysician:
        collaboratingPhysician === "Checked" ||
        collaboratingPhysician === "CheckedAndDisabled",
      AtClinic: atClinic === "Checked",
      AtHome: atHome === "Checked",
      MedicalActionsPerformed: medicalActionsPerformed === "Checked",
      DoctorsSpeciality: doctorsSpeciality,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      CollaboratingNetworkList:
        collaboratingPhysician === "Checked" ||
        collaboratingPhysician === "CheckedAndDisabled",
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
    });
  }, [
    claimsStore,
    collaboratingPhysician,
    atClinic,
    atHome,
    medicalActionsPerformed,
    doctorsSpeciality,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const saveEditedMedicalVisitClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit?.Id,
      ExpenseType: ExpenseType.MedicalVisit,
      CollaboratingPhysician:
        collaboratingPhysician === "Checked" ||
        collaboratingPhysician === "CheckedAndDisabled",
      AtClinic: atClinic === "Checked",
      AtHome: atHome === "Checked",
      MedicalActionsPerformed: medicalActionsPerformed === "Checked",
      DoctorsSpeciality: doctorsSpeciality,
      ReceiptDate: receiptDate,
      ReceiptAmount: receiptAmount,
      OtherInsurerContributionAmount: otherInsurerContributionAmount,
      ReceiptNumber: receiptNumber,
      Files: files,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(receiptDate),
      CollaboratingNetworkList:
        collaboratingPhysician === "Checked" ||
        collaboratingPhysician === "CheckedAndDisabled",
      AmountList: receiptAmount,
      OtherEntityContributedList: !!(
        otherInsurerContributionAmount !== "" &&
        otherInsurerContributionAmount !== 0
      ),
    });
  }, [
    claimsStore,
    props.selectedClaimToEdit?.Id,
    collaboratingPhysician,
    atClinic,
    atHome,
    medicalActionsPerformed,
    doctorsSpeciality,
    receiptDate,
    receiptAmount,
    otherInsurerContributionAmount,
    receiptNumber,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const handleContinueBtn = useCallback(() => {
    setContinueBtnPressed(true);
    if (validateMedicalVisitInfo()) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedMedicalVisitClaim();
      } else {
        createNewMedicalVisitClaim();
      }
      props.nextStep();
    }
  }, [
    validateMedicalVisitInfo,
    createNewMedicalVisitClaim,
    saveEditedMedicalVisitClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const renderReceiptAmount = useCallback(() => {
    if (receiptAmountError === "") {
      return (
        <AmountInput
          labelText="Πληρωτέο ποσό*"
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
        />
      );
    } else {
      return (
        <AmountInput
          labelText="Πληρωτέο ποσό*"
          name="ReceiptAmount"
          onChange={(event) => {
            setReceiptAmount(event.target.value);
            validateReceiptAmount(event.target.value);
          }}
          value={receiptAmount}
          validationText={receiptAmountError}
        />
      );
    }
  }, [
    receiptAmount,
    receiptAmountError,
    setReceiptAmount,
    validateReceiptAmount,
  ]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      if (props.selectedClaimToEdit.CollaboratingPhysician) {
        if (collaboratingDoctorExpenseTypeIsMandatory) {
          setCollaboratingPhysician("CheckedAndDisabled");
        } else {
          setCollaboratingPhysician("Checked");
        }
      } else {
        setCollaboratingPhysician("NotChecked");
      }

      if (props.selectedClaimToEdit.AtClinic) {
        setAtClinic("Checked");
        setMedicalActionsPerformed(
          props.selectedClaimToEdit.MedicalActionsPerformed
            ? "Checked"
            : "NotChecked",
        );
        setAtHome("Disabled");
      } else if (props.selectedClaimToEdit.AtHome) {
        setAtHome("Checked");
        setAtClinic("Disabled");
        setMedicalActionsPerformed("Disabled");
      }

      setDoctorsSpeciality(props.selectedClaimToEdit.DoctorsSpeciality);
      setReceiptDate(props.selectedClaimToEdit.ReceiptDate);
      setReceiptAmount(props.selectedClaimToEdit.ReceiptAmount);
      setOtherInsurerContributionAmount(
        props.selectedClaimToEdit.OtherInsurerContributionAmount,
      );
      setReceiptNumber(props.selectedClaimToEdit.ReceiptNumber);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [
    props.selectedClaimToEdit,
    setCollaboratingPhysician,
    setAtClinic,
    setMedicalActionsPerformed,
    setAtHome,
    setDoctorsSpeciality,
    setReceiptDate,
    setReceiptAmount,
    setOtherInsurerContributionAmount,
    setReceiptNumber,
    collaboratingDoctorExpenseTypeIsMandatory,
  ]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const doctorsSpecialityOptions = useMemo(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    doctorSpecialities.forEach((s) => {
      options.push({
        text: s.Description,
        value: s.Code,
      });
    });

    return options;
  }, [doctorSpecialities]);

  const showCheckBox = useCallback(
    (expenseTypeToCheck) => {
      return expenseTypes.some((et) => et.Name === expenseTypeToCheck);
    },
    [expenseTypes],
  );

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find(
      (et) => et.Name === ExpenseType.MedicalVisit,
    );

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }

    setFilesTypes(options);
  }, [documentTypes, expenseTypes, setFilesTypes]);

  const showInvalidCheckboxSelectionsMsg = useMemo(() => {
    return !validateCollaboratingPhysicianCheckbox(continueBtnPressed);
  }, [validateCollaboratingPhysicianCheckbox, continueBtnPressed]);

  const setPreselectedValuesWhenCollaboratingDoctorExpenseTypeIsMandatory =
    useCallback(() => {
      if (props.selectedClaimToEdit === null) {
        if (collaboratingDoctorExpenseTypeIsMandatory) {
          setCollaboratingPhysician("CheckedAndDisabled");
          setAtClinic("");
          setMedicalActionsPerformed("Disabled");
          setAtHome("");
        }
      }
    }, [
      setCollaboratingPhysician,
      setAtClinic,
      setMedicalActionsPerformed,
      setAtHome,
      collaboratingDoctorExpenseTypeIsMandatory,
      props.selectedClaimToEdit,
    ]);

  useEffect(() => {
    setPreselectedValuesWhenCollaboratingDoctorExpenseTypeIsMandatory();
  }, [setPreselectedValuesWhenCollaboratingDoctorExpenseTypeIsMandatory]);

  return (
    <div>
      <div className={`${styles.note} ${styles.responsiveFontSize}`}>
        <Label text={t("claim.medical.remind-expenses-fees")} />
      </div>
      {showCheckBox(MedicalVisitTypes.MedicalVisitWithCollaboratingDoctor) ? (
        <div
          className={`${styles.checkBoxContainer} ${styles.responsiveFontSize}`}
        >
          {showInvalidCheckboxSelectionsMsg ? (
            <InlineMessage
              mb="20px"
              mt="20px"
              text={invalidCheckboxSelectionsMsg}
            />
          ) : (
            <></>
          )}
          <Checkbox
            variant="Small"
            labelText={t("claim.medical.checkbox-label")}
            name="CollaboratingPhysician"
            onChange={(event) => {
              handleCollaboratingPhysicianCheckbox(event);
            }}
            dataState={collaboratingPhysician}
            mt="30px"
          />
          {showCheckBox(
            MedicalVisitTypes.MedicalVisitWithCollaboratingDoctorAtClinic,
          ) ? (
            <>
              <Checkbox
                variant="Small"
                labelText={t("claim.medical.checkbox-doctor-office")}
                name="AtClinic"
                onChange={(event) => {
                  handleAtClinicCheckbox(event);
                }}
                dataState={atClinic}
                mt="20px"
                ml="50px"
              />
              {showCheckBox(
                MedicalVisitTypes.MedicalVisitWithCollaboratingDoctorAtClinicWithMedicalProcedure,
              ) ? (
                <Checkbox
                  variant="Small"
                  labelText={t("claim.medical.checkbox-procedures-performed")}
                  name="MedicalActionsPerformed"
                  onChange={(event) => {
                    setMedicalActionsPerformed(
                      event.target.checked ? "Checked" : "NotChecked",
                    );
                  }}
                  dataState={medicalActionsPerformed}
                  mt="20px"
                  ml="100px"
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {showCheckBox(
            MedicalVisitTypes.MedicalVisitWithCollaboratingDoctorAtHome,
          ) ? (
            <Checkbox
              variant="Small"
              labelText={t("claim.medical.checkbox-home")}
              name="AtHome"
              onChange={(event) => {
                handleAtHomeCheckbox(event);
              }}
              dataState={atHome}
              mt="20px"
              ml="50px"
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            labelText={t("claim.medical.visiting-physician")}
            name="DoctorsSpeciality"
            options={doctorsSpecialityOptions}
            onChange={(event) => {
              setDoctorsSpeciality(event.target.value);
              validateDoctorsSpeciality(event.target.value);
            }}
            value={doctorsSpeciality}
            validationText={doctorsSpecialityError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={`${t("claim.table.header.receipt-invoice-date")}*`}
            name="ReceiptDate"
            placeholderText="ΗΗ/ΜΜ/ΕΕΕΕ"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={eventDateValue}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventDateChange(value);
            }}
            onAccept={(value) => {
              handleEventDateChange(value);
            }}
            value={receiptDate}
            validationText={receiptDateError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          {renderReceiptAmount()}
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <AmountInput
            labelText={t("claim.medical.contribution-another-insurance")}
            name="otherInsurerContributionAmount"
            popoverElement={
              <Popover
                headingText=" "
                placement="bottom-start"
                testId="popover"
                variant="Icon"
              >
                <div>
                  <Paragraph>
                    {t("claim.medical.conditional-paragraph")}
                  </Paragraph>
                </div>
              </Popover>
            }
            onChange={(event) => {
              setOtherInsurerContributionAmount(event.target.value);
            }}
            value={otherInsurerContributionAmount}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <TextInput
            labelText={t("claim.medical.number-receipt-invoice")}
            name="ReceiptNumber"
            onChange={(event) => {
              setReceiptNumber(event.target.value);
              validateReceiptNumber(event.target.value);
            }}
            value={receiptNumber}
            validationText={receiptNumberError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          labelText={t("claim.medical.file-upload-label")}
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div className={styles.nextStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("claim.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(MedicalVisit);
