import React from "react";
import "../../Css/inbox-filter.css";
import CheckBox from "./CheckBox";
import CheckBoxActive from "./CheckBoxActive";

const CheckBoxGeneric = (props) => {
  return (
    <>
      {props.isActive ? (
        <CheckBoxActive
          innerText={props.innerText}
          variant={props.variant}
          data={props.data}
        />
      ) : (
        <CheckBox
          innerText={props.innerText}
          variant={props.variant}
          data={props.data}
        />
      )}
    </>
  );
};

export default CheckBoxGeneric;
