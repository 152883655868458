import React, { useCallback, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { PaymentsTableHR } from "./PaymentsTableHR";
import PageTheme from "../../PageTheme";
import PaymentsTableFilterHR from "./PaymentsTableFilterHR";
import { ExcelExport } from "../Commons/ExcelExport";
import HRStore from "../../../Stores/HRStore";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";

const PaymentsHR = () => {
  const { paymentsHR, setPaymentsHRList, hrContractsList, allPaymentsHR } =
    useContext(HRStore);
  const [list, setList] = useState([]);
  const [listToExcel, setlistToExcel] = useState([]);

  const checkCompanySelect = useCallback(
    (el, companySelect) =>
      companySelect === "all" || companySelect === undefined
        ? true
        : el === companySelect,
    []
  );
  const checkStatusSelect = useCallback(
    (el, statusSelect) =>
      statusSelect === "all" || statusSelect === undefined
        ? true
        : el === statusSelect,
    []
  );
  const checkDateRange = useCallback(
    ({ InstallmentFrom, InstallmentTo }, datePickerRange) => {
      if (datePickerRange) {
        const [from, to] = datePickerRange;
        return (
          (from
            ? Number(new Date(InstallmentFrom)) >= Number(new Date(from))
            : true) &&
          (to ? Number(new Date(InstallmentTo)) <= Number(new Date(to)) : true)
        );
      }
      return true;
    },
    []
  );

  const filterPayments = useCallback(
    (statusSelect, companySelect, datePickerRange) => {
      if ((companySelect || statusSelect || datePickerRange) && allPaymentsHR) {
        const filteredArray = allPaymentsHR
          .map((contract) => ({
            ...contract,
            Invoices: contract.Invoices.filter((invoice) => {
              return (
                checkStatusSelect(invoice.StatusDescription, statusSelect) &&
                checkCompanySelect(contract.CompanyName, companySelect) &&
                checkDateRange(invoice, datePickerRange)
              );
            }),
          }))
          .filter((contract) => contract.Invoices.length > 0);
        setList(filteredArray);
      } else {
        setList(allPaymentsHR);
      }
    },
    [allPaymentsHR]
  );

  useEffect(() => {
    setPaymentsHRList(paymentsHR, hrContractsList);
  }, [paymentsHR]);

  useEffect(() => {
    setList(allPaymentsHR);
  }, [allPaymentsHR]);

  useEffect(() => {
    const listToExcelFormatted = list?.flatMap((item) => {
      const { Invoices } = item;
      let mainObj = {
        "Αρ. Συμβολαίου": item.ContractNumber,
        "Λήπτης - Όνομα εταιρίας": item.CompanyName,
      };

      if (Invoices && Invoices.length > 0) {
        return Invoices.map((invoiceItem) => ({
          ...mainObj,
          "Αρ. παραστατικού": invoiceItem.InvoiceNumber,
          "Ημερομηνία περιόδου χρέωσης (από)": setDateFormatForDateTime(
            invoiceItem.InstallmentFrom
          ),
          "Ημερομηνία περιόδου χρέωσης (έως)": setDateFormatForDateTime(
            invoiceItem.InstallmentTo
          ),
          "Ποσό χρέωσης": invoiceItem.InstallmentGrossPremium,
          "Ποσό πληρωμής": invoiceItem.PaidAmount,
          "Ημερομηνία εξόφλησης": setDateFormatForDateTime(
            invoiceItem.PaymentDate
          ),
          Κατάσταση: invoiceItem.StatusDescription,
        }));
      } else {
        return {
          ...mainObj,
        };
      }
    });
    setlistToExcel(listToExcelFormatted);
  }, [list]);

  return (
    <section>
      <PageTheme />
      <div className="nn-container">
        <PaymentsTableFilterHR filterFn={filterPayments} />
        <ExcelExport excelData={listToExcel} excelFileName="Πληρωμές" />
        <PaymentsTableHR element={list} />
      </div>
    </section>
  );
};

export default observer(PaymentsHR);
