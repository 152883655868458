import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from "react";
import {
  FileUpload,
  Heading,
  Icon,
  Label,
  PrefabModal,
  Select,
  Button,
  InlineMessage,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import SettingsStore from "../../../Stores/SettingsStore";
import ClaimStore from "../../../Stores/ClaimsStore";
import ContractStore from "../../../Stores/ContractStore";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";

import { MAX_ALLOWED_FILE_MB } from "./constants";

const FilesUpload = ({
  labelText,
  filesTypes,
  files,
  addFile,
  removeFile,
  showFileValidationError,
  policyNumber,
  onlyShowFilledFileTypes,
  hideHeader,
  noFileSelectedText,
}) => {
  const { t } = useTranslation();
  const [showFileModal, setShowFileModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showFileUploadError, setShowFileUploadError] = useState(false);
  const [showNoSelectedFileError, setShowNoSelectedFileError] = useState(false);
  const [showNoSelectedContractError, setShowNoSelectedContractError] =
    useState(false);
  const [isUnsupportedFileSize, setIsUnsupportedFileSize] = useState(false);
  const [fileUploadType, setFileUploadType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const fileValidationError = t("file-upload.attach-all-documents");
  const noSelectedFileError = t("file-upload.select-file");
  const selectedFileTooBig = t("file-upload.max-file-error", {
    size: MAX_ALLOWED_FILE_MB,
  });

  const noSelectedContractError =
    "No contract selected, pleas emake sure you provide policyNumber or basicSubmissionInfoToSubmit?.contract has a value";

  const settingsStore = useContext(SettingsStore);
  const { privateSettings } = settingsStore;

  const claimsStore = useContext(ClaimStore);
  const { basicSubmissionInfoToSubmit } = claimsStore;

  const contractStore = useContext(ContractStore);
  const { contractList } = contractStore;

  const allowedFileTypes = useMemo(
    () => [".pdf", ".jpg", ".jpeg", ".png", ".bmp"],
    [],
  );
  const allowedFileMimeTypes = useMemo(
    () => [
      "application/pdf",
      "image/apng",
      "image/avif",
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/webp",
      "image/bmp",
    ],
    [],
  );

  useEffect(() => {
    if (basicSubmissionInfoToSubmit?.contract || policyNumber) {
      const numberToCheck =
        basicSubmissionInfoToSubmit?.contract || policyNumber;
      setSelectedContract(
        contractList.find((contract) => contract.Number === numberToCheck),
      );
    }
  }, [basicSubmissionInfoToSubmit?.contract, contractList, policyNumber]);

  const fileUploadError = useMemo(() => {
    return privateSettings.eClaims.ResourceStrings[
      "NN.Claim.Submission.FileUploadFailure"
    ];
  }, [privateSettings.eClaims.ResourceStrings]);

  const handleCloseFileModal = useCallback(() => {
    setShowFileModal(false);
  }, [setShowFileModal]);

  const openFileModal = useCallback(() => {
    setShowFileModal(true);
    setFileUploadType(filesTypes[0].value);
    setSelectedFile(null);
  }, [filesTypes, setShowFileModal, setFileUploadType, setSelectedFile]);

  const handleFileInputChange = useCallback(
    (value) => {
      const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);
      const fileSize = bytesToMegaBytes(value.size);

      if (fileSize > MAX_ALLOWED_FILE_MB) {
        setIsUnsupportedFileSize(true);
        return;
      }

      if (allowedFileMimeTypes.includes(value.type)) {
        setSelectedFile(value);
        setIsUnsupportedFileSize(false);
        setShowNoSelectedFileError(false);
      }
    },
    [allowedFileMimeTypes, setShowNoSelectedFileError, setSelectedFile],
  );

  const uploadTheSelectedFile = useCallback(() => {
    setShowFileUploadError(false);
    if (selectedFile && selectedContract) {
      setIsUploading(true);
      setShowNoSelectedFileError(false);
      claimsStore
        .uploadFile(
          selectedFile,
          selectedContract.ApplicationNumber,
          selectedContract.Number,
          selectedContract.ProductCode,
          selectedContract.PolicyHolder.ClientId,
        )
        .then((fileId) => {
          if (fileId) {
            addFile(fileId, fileUploadType, selectedFile.name);
            setShowFileUploadError(false);
            setIsUploading(false);
            handleCloseFileModal();
          } else {
            setIsUploading(false);
            setShowFileUploadError(true);
          }
        });
    } else if (!selectedFile) {
      setShowNoSelectedFileError(true);
    } else if (!selectedContract) {
      setShowNoSelectedContractError(true);
    }
  }, [
    selectedContract,
    addFile,
    claimsStore,
    fileUploadType,
    setIsUploading,
    setShowFileUploadError,
    setShowNoSelectedFileError,
    handleCloseFileModal,
    selectedFile,
  ]);

  const removeSelectedFile = useCallback(
    (fileId) => {
      removeFile(fileId);
    },
    [removeFile],
  );

  const renderSelectedFileElement = useCallback(
    (element, i) => {
      return (
        <div key={i}>
          <span>{`${element.fileName}`}</span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              removeSelectedFile(element.fileId);
            }}
          >
            <Icon name="Trashcan" mr="5px" customSize="13px" color="#EA650D" />
          </span>
        </div>
      );
    },
    [removeSelectedFile],
  );

  const renderFileModal = useCallback(() => {
    return (
      <PrefabModal
        isOpen={showFileModal}
        hasCloseButton
        onClose={() => {
          setShowFileUploadError(false);
          setIsUploading(false);
          setIsUnsupportedFileSize(false);
          handleCloseFileModal();
        }}
        width="530px"
        height="auto"
        headingText={" "}
      >
        <div className={styles.modalFileContainer}>
          <span className={styles.header}>
            <Heading isFirstSection>ΠΡΟΣΘΗΚΗ ΕΓΓΡΑΦΟΥ</Heading>
          </span>
          <div className={`${styles.note} ${styles.responsiveFontSize}`}>
            <Label
              text={`*${t("file-upload.documents-supported")} ${allowedFileTypes.join(
                ", ",
              )}`}
            />
          </div>
          <div className={`${styles.note} ${styles.responsiveFontSize}`}>
            <Label
              text={`**${t("file-upload.max-file-size", { size: MAX_ALLOWED_FILE_MB })}`}
            />
          </div>
          {showFileUploadError ? (
            <InlineMessage mt="20px" text={fileUploadError} />
          ) : (
            <></>
          )}
          <Select
            labelText={t("file-upload.document-type")}
            name="FileUploadType"
            options={filesTypes.map((fileType) => ({
              text: fileType.text,
              value: fileType.value,
            }))}
            onChange={(event) => {
              setShowFileUploadError(false);
              setFileUploadType(event.target.value);
            }}
            value={fileUploadType}
            mt="20px"
            mb="20px"
          />
          <div className={styles.modalRow}>
            <FileUpload
              buttonText={t("file-upload.add-document")}
              labelText=""
              hasUploadButton
              onChange={(event, value) => {
                setShowFileUploadError(false);
                handleFileInputChange(value[0].file);
              }}
              value={[]}
              variant="Small"
              allowedFileTypes={allowedFileTypes.map((fileType) => ({
                type: fileType,
              }))}
            />
            {isUnsupportedFileSize && (
              <InlineMessage text={selectedFileTooBig} mt="10px" />
            )}

            {showNoSelectedFileError && (
              <InlineMessage text={noSelectedFileError} mt="10px" />
            )}

            {showNoSelectedContractError && (
              <InlineMessage text={noSelectedContractError} mt="10px" />
            )}
            <div style={{ marginTop: "20px" }}>
              <Label text={`${selectedFile ? selectedFile.name : ""}`} />
            </div>
          </div>
          <div
            className={`${styles.addFileBtnContainer} ${
              !isUploading && selectedFile ? "" : styles.disabledBtn
            }`}
          >
            <Button
              isLoading={isUploading}
              onClick={() => {
                if (!isUploading && selectedFile) uploadTheSelectedFile();
              }}
            >
              {t("global.total-addition")}
            </Button>
          </div>
        </div>
      </PrefabModal>
    );
  }, [
    showFileModal,
    t,
    allowedFileTypes,
    showFileUploadError,
    fileUploadError,
    filesTypes,
    fileUploadType,
    isUnsupportedFileSize,
    selectedFileTooBig,
    showNoSelectedFileError,
    noSelectedFileError,
    showNoSelectedContractError,
    selectedFile,
    isUploading,
    handleCloseFileModal,
    handleFileInputChange,
    uploadTheSelectedFile,
  ]);

  return (
    <>
      {renderFileModal()}
      {!hideHeader ? <Heading>{t("file-upload.required-msg")}</Heading> : null}
      {labelText ? (
        <div className={`${styles.note} ${styles.responsiveFontSize}`}>
          <Label text={labelText} />
        </div>
      ) : (
        <> </>
      )}
      {showFileValidationError && (
        <InlineMessage mt="20px" text={fileValidationError} />
      )}
      <div className={styles.uploadDocsModalBtnContainer}>
        <button
          onClick={(event) => {
            event.preventDefault();
            openFileModal();
          }}
        >
          <Icon name="Plus" mr="5px" customSize="17px" />
          <span>{t("file-upload.add-document")}</span>
        </button>
      </div>
      <div className={styles.filesTableScrollContainer}>
        <div className={styles.filesTableContainer}>
          <div className={styles.filesTableColumnsHeader}>
            <Label
              text={`${t("file-upload.document-type")} / ${t("file-upload.document-name")}`}
            />
          </div>
          {filesTypes.map((fileType) => {
            if (
              !onlyShowFilledFileTypes ||
              files.some((file) => file.fileType === fileType.value)
            ) {
              return (
                <div key={fileType.value} className={styles.filesTableRow}>
                  <div
                    className={`${styles.rowElement} ${styles.firstElement} ${styles.responsiveFontSize}`}
                  >
                    <Label
                      text={`${fileType.text}${
                        fileType.isMandatory ? "*" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`${styles.rowElement} ${styles.selectedFilesContainer} ${styles.responsiveFontSize}`}
                  >
                    {files
                      ?.filter((file) => file.fileType === fileType.value)
                      ?.map((elem, i) => {
                        return renderSelectedFileElement(elem, i);
                      })}
                  </div>
                </div>
              );
            }
            return <></>;
          })}

          {onlyShowFilledFileTypes && files.length <= 0 ? (
            <div className={styles.noFileSelected}>
              <p>{noFileSelectedText}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default FilesUpload;
