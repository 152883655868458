import React, { useContext, useEffect, useState } from "react";
import dompurify from "dompurify";
import { observer } from "mobx-react-lite";
import AccountStore from "../../Stores/AccountStore";
import CookieStore from "../../Stores/CookieStore";
import ContractStore from "../../Stores/ContractStore";
import { PrefabModal, Button } from "@nn-design-system/react-component-library";
import CheckBoxGeneric from "../Inbox/CheckBoxGeneric";

const TermPopup = () => {
  const accountStore = useContext(AccountStore);
  const cookieStore = useContext(CookieStore);
  const contractStore = useContext(ContractStore);
  const { contentPageSettings, isCustomerOfNNHellas, marketingConsentSettings } = cookieStore;
  const { contractList } = contractStore;

  const [modalShow, setModalShow] = useState(false);
  const [termPage, setTermPage] = useState();
  const sanitizer = dompurify.sanitize;

  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  const [checkBoxStateNone, setCheckboxStateNone] = React.useState(false);

  const marketingProps = {
    checkBoxStateNone,
    setCheckboxStateNone,
  };

  const closemodal = () => {
    setModalShow(false);

    if (!contentPageSettings.AcceptedByUser) {
      accountStore.updateTermPage();
    }

    if (!marketingConsentSettings.ContactedConsent) {
      //ERXETAI TRUE TO  ContactedConsent, OMWS TO THELOUME FALSE gia na mpei edw, true=nochecked, false=checked
      var body = {
        SmsConsent: marketingConsentSettings.ConsentSMS,
        CallConsent: marketingConsentSettings.ConsentCall,
        EmailConsent: marketingConsentSettings.ConsentEmail,
        ContactedConsent: true,
      };
      accountStore.updateMarketingConsent(body);
    }

    cookieStore.updateShowModalTermPage();
  };

  const handleClose = () => setModalShow(false);

  useEffect(() => {
    if (
      checkBoxStateNone &&
      (marketingConsentSettings.ConsentCall || marketingConsentSettings.ConsentEmail || marketingConsentSettings.ConsentSMS)
    ) {
      cookieStore.setCheckboxStateCall(false);
      cookieStore.setCheckboxStateSMS(false);
      cookieStore.setCheckboxStateEmail(false);
    } else if (
      !checkBoxStateNone &&
      !(marketingConsentSettings?.ConsentCall || marketingConsentSettings?.ConsentEmail || marketingConsentSettings?.ConsentSMS)
    ) {
      cookieStore.setCheckboxStateCall(true);
      cookieStore.setCheckboxStateSMS(true);
      cookieStore.setCheckboxStateEmail(true);
    }
  }, [checkBoxStateNone]);

  useEffect(() => {
    if (marketingConsentSettings.ConsentCall || marketingConsentSettings.ConsentEmail || marketingConsentSettings.ConsentSMS) {
      setCheckboxStateNone(false);
    }
    if (!(marketingConsentSettings.ConsentCall || marketingConsentSettings.ConsentEmail || marketingConsentSettings.ConsentSMS)) {
      setCheckboxStateNone(true);
    }
  }, [marketingConsentSettings?.ConsentCall, marketingConsentSettings?.ConsentEmail, marketingConsentSettings?.ConsentSMS]);

  useEffect(() => {
    if (contentPageSettings) {
      var termPage = contentPageSettings;
      setTermPage(termPage);
    }
  }, [contentPageSettings]);

  useEffect(() => {
    if (contentPageSettings) {
      if (contentPageSettings.AcceptedByUser && marketingConsentSettings.ContactedConsent) {
        setModalShow(false);
      } else {
        setModalShow(true);
      }
    }
  }, [contentPageSettings]);

  useEffect(() => {}, [isCustomerOfNNHellas]);

  return (
    <>
      {contentPageSettings &&
      termPage &&
      Object.keys(marketingConsentSettings).length &&
      (!checkNN2Users || (isCustomerOfNNHellas && contractList.length > 0)) ? (
        <PrefabModal
          isOpen={modalShow}
          hasCloseButton
          width="850px"
          height="auto"
          children={
            <div>
              {!contentPageSettings.AcceptedByUser ? (
                <div className="terms-popup">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(termPage.PageContent, {
                        ADD_ATTR: ["target"],
                      }),
                    }}
                  />
                </div>
              ) : null}
              {!marketingConsentSettings.ContactedConsent ? (
                <div className="terms-popup">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(marketingConsentSettings.Body, {
                        ADD_ATTR: ["target"],
                      }),
                    }}
                  />
                  <br />
                  <div className="terms-checkbox-container">
                    <CheckBoxGeneric
                      innerText="SMS"
                      variant="Default"
                      data={"CheckBoxSMS"}
                      isActive={marketingConsentSettings.ConsentSMS} //PROTI FORA THA EINAI FALSE //
                    />

                    <CheckBoxGeneric
                      innerText="Τηλεφωνικά"
                      variant="Default"
                      data={"CheckBoxCall"}
                      isActive={marketingConsentSettings.ConsentCall}
                    />

                    <CheckBoxGeneric
                      innerText="Email"
                      variant="Default"
                      data={"CheckBoxEmail"}
                      isActive={marketingConsentSettings.ConsentEmail}
                    />

                    <CheckBoxGeneric
                      innerText="Δεν επιθυμώ"
                      variant="Default"
                      data={[marketingProps, "CheckBoxNone"]}
                      isActive={checkBoxStateNone}
                    />
                  </div>
                </div>
              ) : null}
              <div className="terms-button-container">
                <Button mt={"40px"} variant="PrimaryMedium" onClick={() => closemodal()}>
                  Υποβολή
                </Button>
              </div>{" "}
            </div>
          }
          onClose={handleClose}
          headingText={" "}
        />
      ) : null}
    </>
  );
};

export default observer(TermPopup);
