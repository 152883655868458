import React from "react";
import { Paragraph } from "@nn-design-system/react-component-library";

export const PortfolioChartLegend = ({ data }) => {
  return (
    <section className="legend-container">
      {data?.map((e, i) => (
        <div key={i} className="legend-section">
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: e.color,
              borderRadius: 3,
            }}
          ></div>
          <Paragraph id="legend-name" hasMargin={false}>
            {e.legendName}
          </Paragraph>
          <Paragraph id="legend-percentage" hasMargin={false}>
            {Number(e.legendPercentage).toLocaleString("el-GR")} %
          </Paragraph>
        </div>
      ))}
    </section>
  );
};
