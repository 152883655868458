import React from "react";
import { VictoryPie, VictoryTooltip, VictoryLabel } from "victory";
import {
  decimalToPercentage,
  ConvertDecimalToStringWithCurrency,
} from "../CommonComp/Functions";
import { colorsList } from "../HR/Utils/Constants";

const formatGroupPensionDoughnutTooltipText = (
  valueX,
  percentage,
  totalAmount,
) => {
  if (valueX.length > 36) {
    valueX = valueX.slice(0, 33) + "...";
  }
  if (valueX.length > 18) {
    valueX = valueX.slice(0, 18) + "\n" + valueX.slice(18, 36);
  }
  return `${valueX} \n ${percentage} ${totalAmount}`;
};

class CustomLabel extends React.Component {
  render() {
    return (
      <g>
        <VictoryLabel {...this.props} />
        <VictoryTooltip
          {...this.props}
          x={215}
          y={250}
          orientation="top"
          pointerLength={0}
          flyoutWidth={100}
          flyoutHeight={100}
          flyoutStyle={{ strokeWidth: 0, fill: "transparent" }}
          style={{ labels: { fill: "black" } }}
          text={formatGroupPensionDoughnutTooltipText(
            this.props.datum.x,
            decimalToPercentage(
              parseFloat(this.props.datum.y?.toString()).toFixed(2),
            ),
            ConvertDecimalToStringWithCurrency(this.props.datum.totalAmount),
          )}
        />
      </g>
    );
  }
}

CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

class GroupPensionDoughnut extends React.Component {
  render() {
    return (
      <VictoryPie
        width={430}
        style={{
          data: {
            fill: ({ datum }) => {
              return datum.fill;
            },
          },
          labels: { fill: "white" },
        }}
        innerRadius={80}
        labelRadius={105}
        labels={({ datum }) =>
          decimalToPercentage(parseFloat(datum.y?.toString()).toFixed(2))
        }
        labelComponent={<CustomLabel />}
        data={this.props.data.map((el, i) => ({ ...el, fill: colorsList[i] }))}
      />
    );
  }
}

export default GroupPensionDoughnut;
