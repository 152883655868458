import {
  Heading,
  Paragraph,
  Button,
  AttentionBlock,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";

export const KycFailure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <div>
      <AttentionBlock testId="test-id" variant={"Error"}>
        <Paragraph>{t("kyc.unable-update-details")}</Paragraph>
      </AttentionBlock>
      <Paragraph>
        <Heading variant={"Small"}> {t("kyc.info-can-not-be-updated")}</Heading>
        {state ? t("kyc.consent-release-msg") : t("kyc.error-msg")}
        <Paragraph mt="20px">
          {state ? t("kyc.repeat-process") : t("kyc.try-again")}
        </Paragraph>
      </Paragraph>
      <Paragraph>
        <Button variant="PrimaryMedium" onClick={() => navigate("/myAccount")}>
          {t("global.return")}
        </Button>
      </Paragraph>
    </div>
  );
};
