import { useState, useEffect } from "react";

const min = process.env.REACT_APP_ENVIRONMENT === "local" ? 59 : 9;
const sec = 59;
const defaultSec = 59;

const MouseListener = () => {
  const [seconds, setSeconds] = useState(sec);
  const [minutes, setMinutes] = useState(min);

  useEffect(() => {
    window.addEventListener("click", () => {
      setSeconds(sec);
      setMinutes(min);
    });
    return () =>
      window.removeEventListener("click", () => {
        setSeconds(sec);
        setMinutes(min);
      });
  }, []);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(defaultSec);
      }
    }, 1000);
    if (minutes === 0 && seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [minutes, seconds]);

  return { minutes, seconds };
};

export default MouseListener;
