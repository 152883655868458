import React from "react";
import { Link } from "react-router-dom";

const LogoutMessage = () => {
  return (
    <div>
      <h3>
        <b>Log Out Message </b>
      </h3>
      <p>Ευχαριστούμε που χρησιμοποιήσατε το myNN.</p>
      <p>Για λόγους ασφαλείας, αποσυνδέεστε αυτόματα μετά από 10 λεπτά αδράνειας.</p>
      <Link to="/Login">Επανασυνδεθείτε</Link>
    </div>
  );
};

export default LogoutMessage;
