import React, { useState, useEffect, useContext, useCallback } from "react";
import { Label, Select } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import GroupPensionCertificatesNNTable from "./GroupPensionCertificatesNNTable";
import GroupPensionCertificatesList from "./GroupPensionCertificatesList";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import ContractStore from "../../Stores/ContractStore";
import LoaderStore from "../../Stores/LoaderStore";
import styles from "../../Css/GroupPension/GroupPension.module.scss";
import moment from "moment";

const GroupPensionCertificates = ({ contractId, showData }) => {
  const { t } = useTranslation();
  const contractStore = useContext(ContractStore);
  const { setMiniLoaderForData3 } = useContext(LoaderStore);
  const { hasGroupPensionCertificatesData, groupPensionCertificatesData } =
    contractStore;
  const [tableData, setTableData] = useState([]);
  const [dropDownMenuValues, setDropDownMenuValues] = useState([]);
  const [filterableTableData, setFilterableTableData] = useState([]);
  const [certificateFilter, setCertificateFilter] = useState("all");
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();
  const currentYear = moment().format("YYYY");

  const dropDownHandler = useCallback(
    (e) => {
      if (e.target.value !== null) {
        setCertificateFilter(e.target.value);
      }
    },
    [setCertificateFilter],
  );

  useEffect(() => {
    contractStore.loadGroupPensionCertificatesData(contractId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasGroupPensionCertificatesData) {
      //The following line should be uncommented when we have test data with contractId not empty
      if (groupPensionCertificatesData?.length > 0) {
        setTableData(
          groupPensionCertificatesData.filter(
            (certificate) =>
              certificate.Year === currentYear.toString() ||
              certificate.Year === (currentYear - 1).toString() ||
              certificate.Year === (currentYear - 2).toString(),
          ),
        );
      }
      setMiniLoaderForData3(false);
    } else {
      setMiniLoaderForData3(true);
    }
  }, [
    hasGroupPensionCertificatesData,
    groupPensionCertificatesData,
    contractId,
    setMiniLoaderForData3,
    currentYear,
  ]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      let newDropDownMenuValues = [];
      tableData.forEach((elem) => {
        let exists = false;
        for (const unique of newDropDownMenuValues) {
          if (unique.value === elem.CertificateType) {
            exists = true;
            break;
          }
        }
        if (!exists) {
          newDropDownMenuValues.push({
            text: elem.CertificateName,
            value: elem.CertificateType,
          });
        }
      });
      setDropDownMenuValues(newDropDownMenuValues);
    }
  }, [tableData]);

  useEffect(() => {
    if (tableData) {
      if (certificateFilter !== "all") {
        setFilterableTableData(
          tableData.filter((x) => x.CertificateType === certificateFilter),
        );
      } else {
        setFilterableTableData([...tableData]);
      }
    }
  }, [tableData, certificateFilter]);

  useEffect(() => {
    if (width < 580) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  return (
    <>
      {showData && hasGroupPensionCertificatesData && (
        <div>
          <div style={{ width: "320px" }}>
            <Select
              mt="20px"
              mb="20px"
              labelText={`${t("contract.certificate-type")} / ${t("contract.certificate")}`}
              placeholderText={t("contract.choose-gender")}
              name="select"
              onChange={(e) => {
                dropDownHandler(e);
              }}
              options={[
                { text: t("contract.all"), value: "all" },
                ...dropDownMenuValues,
              ]}
              testId="select"
              value={certificateFilter}
            />
          </div>
          {isSmallSize ? (
            <GroupPensionCertificatesList tableData={filterableTableData} />
          ) : (
            <GroupPensionCertificatesNNTable tableData={filterableTableData} />
          )}

          <div className={styles.note}>
            <Label
              text={`${t("contract.premium-document-available", { year: currentYear - 2 })}`}
              className
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GroupPensionCertificates;
