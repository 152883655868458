import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MyAccount from "../Components/Account/MyAccount";
import Articles from "../Components/Articles/Articles";
import MyClaims from "../Components/Claims/MyClaims";
import MyContracts from "../Components/Contracts/MyContracts";
import CustomerSearch from "../Components/CustomerSupport/CustomerSearch";
import MyDocuments from "../Components/Document/MyDocuments";
import MyHome from "../Components/Home/MyHome";
import MyInbox from "../Components/Inbox/MyInbox";
import InfoPages from "../Components/infoPages/InfoPages";
import LoginPage from "../Components/Login/LoginPage";
import LogoutMessage from "../Components/Login/LogoutMessage";
import OnlinePaymentFailPayment from "../Components/OnlinePayments/OnlinePaymentFailPayment";
import OnlinePayments from "../Components/OnlinePayments/OnlinePayments";
import OnlinePaymentStep2 from "../Components/OnlinePayments/OnlinePaymentStep2";
import OnlinePaymentSuccessPayment from "../Components/OnlinePayments/OnlinePaymentSuccessPayment";
import OnlinePaymentTransactionHandler from "../Components/OnlinePayments/OnlinePaymentTransactionHandler";
import MyPayments from "../Components/Payments/MyPayments";
import RequireAuthRoute from "../Components/RequireAuthRoute";
import ExpiredPage from "../Components/SpecialPages/ExpiredPage";
import CookiesPolicy from "../Components/TextDocuments/CookiesPolicy";
import GlobalConsent from "../Components/TextDocuments/GlobalConsent";
import PersonalData from "../Components/TextDocuments/PersonalData";
import TermsAndConditions from "../Components/TextDocuments/TermsAndConditions";
import HRStore from "../Stores/HRStore";
import HomeHR from "../Components/HR/HomeHR";
import { observer } from "mobx-react-lite";
import ContractsHR from "../Components/HR/Contracts/ContractsHR";
import OnlinePaymentTransactions from "../Components/OnlinePayments/OnlinePaymentTransactions";
import PaymentsHR from "../Components/HR/Payments/PaymentsHR";
import InfoKyc from "../Components/Kyc/InfoKyc";
import LayoutKyc from "../Components/Kyc/LayoutKyc";
import KycStep1 from "../Components/Kyc/KycStep1";
import KycStep2 from "../Components/Kyc/KycStep2";
import { KycFailure } from "../Components/Kyc/KycFailure";
import KycTransactionHandler from "../Components/Kyc/KycTransactionHandler";
import Portfolio from "../Components/Portfolio/Portfolio";

const AuthorizedRoutes = (props) => {
  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";
  const { isHrRole } = useContext(HRStore);
  return (
    <>
      {props.isCustomerSupport || props.isCustomerSupport === undefined ? (
        <Routes>
          <Route path="/cs" element={<CustomerSearch />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/azureb2cendpoint"
            element={
              props.giveAccess ? (
                <Navigate to="/home" />
              ) : (
                <Navigate to="/azureb2cendpoint" />
              )
            }
          />
          <Route path="/home" element={isHrRole ? <HomeHR /> : <MyHome />} />
          <Route path="/knowledgepoint/*" element={<Articles />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/LogoutMessage" element={<LogoutMessage />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/globalconsent" element={<GlobalConsent />} />
          <Route path="/personaldata" element={<PersonalData />} />
          <Route path="/cookiespolicy" element={<CookiesPolicy />} />
          <Route path="/Info/*" element={<InfoPages />} />
          <Route path="/SpecialPages/expiredPage" element={<ExpiredPage />} />
          {/* <Route path="/myClaims/submission" element={<ClaimSubmissionWizard />} /> */}
          {!checkNN2Users || props.cookieHasContent ? (
            <>
              <Route
                path="/myContracts/*"
                element={
                  <RequireAuthRoute
                    children={<MyContracts />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN || isHrRole}
                  />
                }
              />
              <Route
                path="/hr/contracts/*"
                element={
                  <RequireAuthRoute
                    children={<ContractsHR />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN || isHrRole}
                  />
                }
              />
              <Route
                path="/myPayments/*"
                element={
                  <RequireAuthRoute
                    children={<MyPayments />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN || isHrRole}
                  />
                }
              />
              <Route
                path="/hr/payments/*"
                element={
                  <RequireAuthRoute
                    children={isHrRole ? <PaymentsHR /> : <MyPayments />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN || isHrRole}
                  />
                }
              />
              <Route
                path="/myDocuments/*"
                element={
                  <RequireAuthRoute
                    children={<MyDocuments />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN}
                  />
                }
              />
              <Route
                path="/myClaims/*"
                element={
                  <RequireAuthRoute
                    children={<MyClaims />}
                    path="/home"
                    auth={!checkNN2Users || props.giveAccessToMyNN}
                  />
                }
              />
              <Route path="/myAccount" element={<MyAccount />} />
              <Route path="/myInbox/*" element={<MyInbox />} />
              <Route
                path="/myPortfolio/*"
                element={
                  <RequireAuthRoute children={<Portfolio />} path="/home" auth={!checkNN2Users || props.giveAccessToMyNN || isHrRole} />
                }
              />
            </>
          ) : (
            <></>
          )}
          <Route path="/oauth/kyc/*" element={<LayoutKyc />}>
            <Route path="" element={<KycTransactionHandler />}></Route>
            <Route
              path="request_for_information_update"
              element={<InfoKyc />}
            ></Route>
            <Route path="step1" element={<KycStep1 />}></Route>
            <Route path="step2" element={<KycStep2 />}></Route>
            <Route path="failure" element={<KycFailure />}></Route>
          </Route>
          <Route path="/online-payment/*" element={<OnlinePayments />}>
            <Route path="" element={<OnlinePaymentTransactions />}></Route>
            <Route path="2" element={<OnlinePaymentStep2 />}></Route>
            <Route
              path="success"
              element={<OnlinePaymentSuccessPayment />}
            ></Route>
            <Route
              path="failure"
              element={<OnlinePaymentFailPayment />}
            ></Route>
            <Route
              path="result"
              element={<OnlinePaymentTransactionHandler />}
            ></Route>
          </Route>
          <Route path="/">
            <Route path="*" element={<Navigate replace to="/home" />} />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default observer(AuthorizedRoutes);
