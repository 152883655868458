import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
  Icon,
  PrefabModal,
  Label,
  RadioButtonGroup,
  RadioButton,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { setDateFormatForDateTime } from "../CommonComp/Functions";
import ClaimsDetailPanelContent from "./ClaimsDetailPanelContent";
import ElementDownload from "../Document/ElementDownload";
import "../../Css/Claims/Claims.css";
import styles from "../../Css/Claims/Claims.module.scss";

const ClaimsTableNN = (props) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalDocList, setModalDocList] = useState([]);
  const [radioBtnsValue, setRadioBtnsValue] = useState("");

  const handleModalClick = useCallback(
    (props) => {
      setShowModal(props.showModalVal);
      setModalDocList(props.documentList);
    },
    [setModalDocList, setShowModal],
  );

  const getStatusFilteredContractList = useCallback(
    (statusValue) => {
      if (statusValue === "Processing") {
        return props.claimsList.filter(
          (c) => c.Status === statusValue || c.Status === "Pending",
        );
      }
      return props.claimsList;
    },
    [props.claimsList],
  );

  const [filteredClaimsList, setFilteredClaimsList] = useState(
    props.claimsList,
  );

  const radioBtnsHandler = useCallback(
    (e) => {
      if (e.target.value === "Processing") {
        setRadioBtnsValue("Processing");
      } else {
        setRadioBtnsValue("");
      }
      setFilteredClaimsList(getStatusFilteredContractList(e.target.value));
      setPageNumber(0);
    },
    [setFilteredClaimsList, getStatusFilteredContractList],
  );

  const showPaginationFooter = useMemo(() => {
    return props.claimsList && props.claimsList.length > 5;
  }, [props.claimsList]);

  return (
    <div className="nn-container">
      {props.claimsList && (
        <>
          <PrefabModal
            isOpen={showModal}
            hasCloseButton
            children={
              <div className={styles.modalContentContainer}>
                <span className={styles.header}>
                  <Label text={t("claim.detail")} />
                </span>
                {modalDocList &&
                  modalDocList.map((elem) => {
                    return (
                      <div className={styles.modalRow}>
                        <Label text={elem.File.Title} />
                        <ElementDownload
                          DocumentLink={elem.File.Path}
                          DocumentType={elem.File.Name}
                          MimeType={elem.File.MimeType}
                        />
                      </div>
                    );
                  })}
              </div>
            }
            onClose={() => {
              setShowModal(false);
            }}
            width="auto"
            height="auto"
            headingText={" "}
          />
          <RadioButtonGroup
            labelText=""
            mt={"-15px"}
            value={radioBtnsValue}
            onChange={radioBtnsHandler}
            variant={"Default"}
          >
            <div className={styles.radioBtnsGroupContainer}>
              <RadioButton
                labelText={t("global.pending")}
                value={"Processing"}
              />
              <RadioButton ml={"25px"} labelText={t("global.all")} value={""} />
            </div>
          </RadioButtonGroup>
          <Table
            localeText={{ noRowsLabel: t("global.no-results-found") }}
            mb="20px"
            density="comfortable"
            columns={[
              {
                align: "left",
                field: "ClaimNo",
                flex: 1,
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.compensation-number"),
                sortable: false,
              },
              {
                align: "left",
                field: "PolicyNo",
                flex: 1,
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.contract-number"),
                sortable: false,
              },
              {
                align: "left",
                field: "InsuredName",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.insured-member"),
                cellClassName: () => {
                  return styles.insuredName;
                },
                sortable: false,
                width: 170,
              },
              {
                align: "left",
                field: "EventDate",
                flex: 1,
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.event-date"),
                valueFormatter: (props) => {
                  return setDateFormatForDateTime(props.value);
                },
                sortable: false,
              },
              {
                align: "left",
                field: "StatusName",
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("global.condition"),
                sortable: false,
                width: 135,
              },
              {
                align: "center",
                field: "PortalSubmitted",
                flex: 1,
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.registered-email"),
                renderCell: (props) => {
                  return props.value ? (
                    <Icon name="Checkmark" variant={"Medium"} color="#EA650D" />
                  ) : (
                    "-"
                  );
                },
                sortable: false,
              },
              {
                align: "center",
                field: "Payments",
                flex: 1,
                headerAlign: "left",
                headerClassName: "textAlignLeft",
                headerName: t("claim.table.header.detailed-claim"),
                renderCell: (props) => {
                  if (
                    props.row.Status !== "Processing" &&
                    props.row.Payments &&
                    props.row.Payments.length > 0
                  ) {
                    return (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleModalClick({
                            showModalVal: !showModal,
                            documentList: props.row.Payments,
                          });
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleModalClick({
                            showModalVal: !showModal,
                            documentList: props.row.Payments,
                          });
                        }}
                      >
                        <Icon
                          name="ArrowDownLine"
                          variant={"Medium"}
                          color="#EA650D"
                        />
                      </a>
                    );
                  }
                  return "-";
                },
                sortable: false,
              },
            ]}
            locale="elGR"
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            hasFooterPagination={showPaginationFooter}
            hasPagination
            getDetailPanelContent={(rowData) => {
              if (rowData.row.Status !== "Processing") {
                return <ClaimsDetailPanelContent rowData={rowData.row} />;
              }
            }}
            getDetailPanelHeight={() => {
              return "auto";
            }}
            itemsPerPageProps={{
              itemsPerPage: [
                {
                  text: "5",
                  value: 5,
                },
                {
                  text: "10",
                  value: 10,
                },
                {
                  text: "25",
                  value: 25,
                },
                {
                  text: "50",
                  value: 50,
                },
              ],
              name: "itemsPerPage",
              onChange: (test) => {
                setItemsPerPage(test);
              },
              value: itemsPerPage,
            }}
            page={pageNumber}
            pageNavigationProps={{
              name: "pageNavigation",
              onChange: (value) => {
                setPageNumber(value);
              },
              value: pageNumber,
            }}
            pageSize={itemsPerPage}
            getRowId={(row) => row.ClaimNo}
            hasAutoHeight
            rows={filteredClaimsList}
          />
        </>
      )}
    </div>
  );
};

export default observer(ClaimsTableNN);
