import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import ContractStore from "../../Stores/ContractStore";
import PaymentStore from "../../Stores/PaymentStore";
import LoaderStore from "../../Stores/LoaderStore";

import { SelectPolicy } from "./OnlinePaymentSelectPolicy";
import { OnlinePaymentMethodDetails } from "./OnlinePaymentMethodDetails";

import "../../Css/OnlinePayment/Online-payment.css";

const ChangeCardStep1 = () => {
  const { t } = useTranslation();

  const { paymentList } = useContext(PaymentStore);
  const contractStore = useContext(ContractStore);
  const { contractList, paymentMethodDetails, isLoading } = contractStore;
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const loaderStore = useContext(LoaderStore);

  const [selectedContract, setSelectedContract] = useState();
  const [paymentMethodDetail, setPaymentMethodDetail] = useState(undefined);
  const [pendingText, setPendingText] = useState(undefined);
  const [displayNumberSelected, setDisplayNumberSelected] = useState(undefined);

  const eligibleContracts = contractList.filter(
    (contract) => contract.IsEligibleForChangeCard,
  );

  function checkPendingActions(contractNumber) {
    if (!contractNumber) {
      return;
    }

    if (
      eligibleContracts.some(
        (contract) =>
          contract.Number === contractNumber &&
          contract.PaymentMethodId !== "C",
      )
    ) {
      loaderStore.setLoaderForData(false);
      return t("online.payment.change-card-pending-msg");
    }

    if (
      paymentList.some(
        (contract) =>
          contract.Number === contractNumber &&
          contract.Payments.some((payment) => payment.StatusCode === "O"),
      )
    ) {
      loaderStore.setLoaderForData(false);
      return t("online.payment.change-card-fix-msg");
    }
  }

  const storeResult = useCallback(
    (contractNumber) => {
      const currentPaymentMethodDetail = paymentMethodDetails.find(
        (item) => item[contractNumber],
      )[contractNumber];
      const contract = eligibleContracts.find(
        (payment) => payment.Number === contractNumber,
      );
      const existingPayment = paymentList.find(
        (payment) => payment.Number === contractNumber,
      );

      const onlinePayment = {
        ContractNumber: contractNumber,
        OutstandingAmount: 0,
        PaymentMethodId: contract.PaymentMethodId,
        ProductCode: contract.ProductCode,
        PaymentFrequencyId: contract.PaymentFrequencyId,
        ApplicationNumber: contract.ApplicationNumber,
        MaskedAccountNumber: currentPaymentMethodDetail?.MaskedAccountNumber,
        ExpirationDate: currentPaymentMethodDetail?.ExpirationDate,
        DisplayNumber: contract.DisplayNumber,
      };

      const payment = existingPayment
        ? {
            ...existingPayment,
            ...onlinePayment,
          }
        : {
            ...onlinePayment,
            PaymentCodeA: currentPaymentMethodDetail?.AccountNumber,
            ApplicationNumber: contract.ApplicationNumber,
          };

      onlinePaymentStore.setContract(contract);
      onlinePaymentStore.setPayment(payment);
    },
    [eligibleContracts, onlinePaymentStore, paymentList, paymentMethodDetails],
  );

  useEffect(() => {
    const currentPaymentMethodDetail = paymentMethodDetails?.find(
      (item) => item[selectedContract],
    );

    if (!currentPaymentMethodDetail) {
      return;
    }

    setPaymentMethodDetail(currentPaymentMethodDetail);
    storeResult(selectedContract);
  }, [paymentMethodDetails, selectedContract, storeResult]);

  useEffect(() => {
    loaderStore.setLoaderForData(isLoading);
  }, [isLoading, loaderStore]);

  function handleSelect(selectedContractNumber) {
    const displayNumber = eligibleContracts.find(
      (el) => el.Number === selectedContractNumber,
    ).DisplayNumber;

    setDisplayNumberSelected(displayNumber);
    const pendingActions = checkPendingActions(selectedContractNumber);

    setPendingText(pendingActions);
    setSelectedContract(selectedContractNumber);
    setPaymentMethodDetail(undefined);

    const alreadyFetched = paymentMethodDetails?.find(
      (item) => item[selectedContractNumber],
    );

    if (pendingActions || alreadyFetched) {
      return;
    }

    const { ApplicationNumber, Number, SystemApplicationCode, ProductCode } =
      eligibleContracts.find(({ Number }) => Number === selectedContractNumber);

    const contractData = {
      contractNumber: Number,
      applicationNumber: ApplicationNumber,
      systemApplicationCode: SystemApplicationCode,
      productCode: ProductCode,
    };

    loaderStore.setLoaderForData(true);
    contractStore.loadContractBankAccountDetails(contractData);
  }

  return (
    <div>
      <SelectPolicy
        items={eligibleContracts}
        current={selectedContract}
        onSelect={handleSelect}
      />

      <div className="online-payment-form">
        <div className="form-element right-padded"></div>
        {pendingText && (
          <>
            <div className="form-element right-padded">
              <span className="payBold">{t("contracts.insurance-number")}</span>
              <span> {selectedContract} </span>
            </div>
            <div className="form-element right-padded">
              <span className="pending-text">{pendingText}</span>{" "}
            </div>
          </>
        )}
        {paymentMethodDetail && (
          <OnlinePaymentMethodDetails
            displayNumber={displayNumberSelected}
            paymentMethod={paymentMethodDetail}
            contractNumber={selectedContract}
          />
        )}
      </div>
    </div>
  );
};

export default observer(ChangeCardStep1);
