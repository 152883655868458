import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UrlStore from "../../Stores/UrlStore";
import ElementDownload from "../Document/ElementDownload";
import SettingsStore from "../../Stores/SettingsStore";

const ContractSelectedRelativeDocuments = (props) => {
  const { t } = useTranslation();
  const urlStore = useContext(UrlStore);
  const settingStore = useContext(SettingsStore);
  const { showErrorMessage, contentErrorMessage } = settingStore;
  const { baseURL } = urlStore;
  const [message, setMessage] = useState();

  var extensions = [
    "MC1",
    "MC2",
    "MG2",
    "MG3",
    "MG#",
    "MG1",
    "UR1",
    "UR2",
    "UR3",
  ];
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const deleteMessage = async () => {
    await delay(5000);
    settingStore.setShowErrorMessage(false);
  };
  useEffect(() => {
    if ((showErrorMessage, contentErrorMessage)) {
      setMessage(<span style={{ color: "red" }}> {contentErrorMessage}</span>);
      deleteMessage();
    }
    if (showErrorMessage === false) {
      setMessage();
    }
  }, [showErrorMessage, contentErrorMessage]);

  return (
    <div className="account-document">
      <p>{t("contract.relevant-document")}</p>
      {extensions.includes(props.productCategory) ? (
        props.adHoc ? (
          <>
            <span>
              {props.adHoc.Title}
              <ElementDownload
                DocumentLink={props.adHoc.Url}
                DocumentType={props.adHoc.Title}
                IconVariant={"Small"}
              />
            </span>
            {message}
          </>
        ) : null
      ) : null}
      {props.filteredDoc.length !== 0 ? (
        <>
          {props.filteredDoc.map((filteredDocElement, i) => {
            return (
              <span key={i}>
                {filteredDocElement.DocumentType}
                <ElementDownload
                  DocumentLink={filteredDocElement.DocumentLink}
                  DocumentType={filteredDocElement.DocumentType}
                  IconVariant={"Small"}
                />
              </span>
            );
          })}
        </>
      ) : (
        <>
          {extensions.includes(props.productCategory) && props.adHoc ? null : (
            <span>-</span>
          )}
        </>
      )}
    </div>
  );
};

export default observer(ContractSelectedRelativeDocuments);
