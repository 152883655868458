import { Popper } from "@mui/material";
import React from "react";
import "../../Css/CommonCss/HoverPopover.css";

const HoverPopover = ({ popoverElement, children }) => {
  const [arrowRef, setArrowRef] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMouseLeave = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  };

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div>
      <div
        aria-describedby={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {popoverElement}
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <span className="hover-popover-arrow" ref={setArrowRef} />
        <div className="hover-popover-body">{children}</div>
      </Popper>
    </div>
  );
};

export default HoverPopover;
