import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Label } from "@nn-design-system/react-component-library";
import * as XLSX from "xlsx";
import img from "../../../assets/index";

export const ExcelExport = ({ excelFileName, excelData }) => {
  const { t } = useTranslation();
  const exportToExcel = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${excelFileName}.xlsx`;
    link.click();

    URL.revokeObjectURL(link.href);
  }, [excelData]);

  return (
    <>
      <div className="excel-export-container">
        <div onClick={exportToExcel} className="excel-export">
          <img src={img.excel_icon} alt="call_icon" />
          <Label text={t("excel.export-list")} />
        </div>
      </div>
    </>
  );
};
