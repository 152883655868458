import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import ArticleStore from "../../Stores/ArticleStore";
import UrlStore from "../../Stores/UrlStore";
import "../../Css/Articles/articles-carousel.css";
import {
  Icon,
  Carousel,
  Card,
  Heading,
  Paragraph,
  Divider,
  Image,
  Label,
  Link,
} from "@nn-design-system/react-component-library";

const makeArray = (array, width) => {
  let arrayOfThree = [];
  let arraySections = [];
  let arrayLength = array.length;
  let breakpoint = width >= 768 ? 3 : 1;
  array.map((article, i) => {
    arrayOfThree.push(article);
    if (arrayOfThree.length === breakpoint) {
      arraySections.push(arrayOfThree);
      arrayOfThree = [];
    } else if (arrayLength === i + 1) {
      arraySections.push(arrayOfThree);
      arrayOfThree = [];
    }
  });
  return arraySections;
};

const ArticleCarousel = () => {
  const { t } = useTranslation();
  const { articleList } = useContext(ArticleStore);
  const { baseURL } = useContext(UrlStore);
  const [filteredArticleList, setFilteredArticleList] = useState(null);
  const [carouselArray, setCarouselArray] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (articleList) {
      let filteredList = articleList.filter((a) => a.NodeParentId === 3249);
      setFilteredArticleList(filteredList);
    }
  }, [articleList]);

  const clickToDetails = (detail, elem) => {
    navigate(`/knowledgepoint/${detail}`, { state: toJS(elem) });
  };

  const trimStringForCarousel = (string) => {
    if (string && string.length > 58) {
      let trimmedString = string.slice(0, 58);
      var lastIndex = trimmedString.lastIndexOf(" ");
      trimmedString = trimmedString.substring(0, lastIndex);
      trimmedString = trimmedString + " ...";
      return trimmedString;
    }

    return string;
  };

  useEffect(() => {
    if (filteredArticleList) {
      let filteredArray = makeArray(filteredArticleList, width);
      setCarouselArray(filteredArray);
    }
  }, [filteredArticleList, width]);
  return (
    <Fragment>
      {carouselArray ? (
        <>
          <>
            <div className="nn-design-orangeheader">
              <Heading isFirstSection hasMargin={false} variant="Large">
                {t("global.update")}
              </Heading>
              <Link
                variant="PrimaryStandAloneStrong"
                onClick={() => navigate("/knowledgepoint")}
                text={t("articleCarousel-see-more")}
              ></Link>
            </div>
            <Divider mt="10px" mb={"20px"} />
            <div className="carousel-container">
              <Carousel
                hasIndicators={false}
                hasFadeEffect={false}
                hasNavigationButtons
                numberOfItemsPerView={3}
                shouldLoop
              >
                {filteredArticleList.map((article) => (
                  <Card variant="Default" key={article.Title}>
                    <div onClick={() => clickToDetails("details", article)}>
                      <Heading isFirstSection variant="Medium">
                        {article?.Title}
                      </Heading>

                      {article?.TeaserImage ? (
                        <Image
                          objectFit="Cover"
                          source={`${baseURL}${article.TeaserImage.Path}`}
                          testId="imagen test square"
                          variant="Landscape"
                        />
                      ) : null}
                      <Paragraph mb={"10px"}>
                        {trimStringForCarousel(article?.Summary)}
                      </Paragraph>
                      <div className="chevron-link-new">
                        <Icon
                          mr={"10px"}
                          mt={"5px"}
                          mb={"-3px"}
                          name="ChevronRight"
                          variant="Small"
                          color="#EA650D"
                        />
                        <Label text={article?.LinkForMore} />
                      </div>
                    </div>
                  </Card>
                ))}
              </Carousel>
            </div>
          </>
        </>
      ) : null}
    </Fragment>
  );
};

export default observer(ArticleCarousel);
