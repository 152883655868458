import React, { useState, useCallback, useMemo, useContext } from "react";
import { observer } from "mobx-react-lite";
import {
  Icon,
  Collapsible,
  Label,
  Paragraph,
  Table,
  Button,
  PrefabModal,
  Heading,
  Link,
  SpinnerLoader,
} from "@nn-design-system/react-component-library";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import {
  setDateFormatForDateTime,
  ConvertDecimalToString,
} from "../CommonComp/Functions";
import styles from "../../Css/Claims/Claims.module.scss";
import wizardStyles from "../../Css/Claims/ClaimSubmissionWizard.module.scss";
import FilesUpload from "./ClaimSubmissionWizard/FilesUpload";
import ClaimsStore from "../../Stores/ClaimsStore";
import SettingsStore from "../../Stores/SettingsStore";
import "../../Css/CommonCss/General.css";

const ClaimElementMobileView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expand, setExpand] = useState("");
  const { documentTypes, resubmitClaimFiles, getMyClaims } =
    useContext(ClaimsStore);
  const { privateSettings } = useContext(SettingsStore);
  const [files, setFiles] = useState([]);
  const [submittingFiles, setSubmittingFiles] = useState(false);
  const [showSubmissionFailModal, setShowSubmissionFailModal] = useState(false);
  const [showSubmissionSuccessModal, setShowSubmissionSuccessModal] =
    useState(false);

  const failureContactInfo = useMemo(() => {
    return DOMPurify.sanitize(
      privateSettings.eClaims.ResourceStrings[
        "NN.Claim.Submission.FailureContactInfo"
      ],
      { USE_PROFILES: { html: true } },
    );
  }, [privateSettings.eClaims.ResourceStrings]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
    },
    [files, setFiles],
  );

  const fileTypes = useMemo(() => {
    return documentTypes?.map((documentType) => ({
      text: documentType.Title,
      value: documentType.Name,
      isMandatory: false,
    }));
  }, [documentTypes]);

  const handleStyleClass = () => {
    if (!claimIsInProcessingStatus) {
      expand === "" ? setExpand("expand") : setExpand("");
    }
  };

  const claimIsInProcessingStatus = useMemo(() => {
    return props.element.Status === "Processing";
  }, [props]);

  const submitFiles = useCallback(async () => {
    if (files.length > 0) {
      setSubmittingFiles(true);
      const isSuccess = await resubmitClaimFiles(
        props.element.ClaimNo,
        props.element.SourceSystemCaseNumber,
        files,
      );
      if (!isSuccess) {
        setShowSubmissionFailModal(true);
      } else {
        setShowSubmissionSuccessModal(true);
      }
    }
    setSubmittingFiles(false);
  }, [
    files,
    props.element.ClaimNo,
    props.element.SourceSystemCaseNumber,
    resubmitClaimFiles,
    setShowSubmissionFailModal,
    setShowSubmissionSuccessModal,
  ]);

  const handleContiune = useCallback(() => {
    if (showSubmissionSuccessModal) {
      getMyClaims();
      navigate("/myClaims");
    } else {
      setShowSubmissionFailModal(false);
    }
  }, [
    getMyClaims,
    navigate,
    showSubmissionSuccessModal,
    setShowSubmissionFailModal,
  ]);

  const children = useCallback(() => {
    return (
      <>
        {props.rowData?.Status === "PendingRefund" ? (
          <div className={styles.detailPanelContainerRejection}>
            <div className={styles.claimPendingRefundNotesSection}>
              <Label text={props.rowData.StatusName} />
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(props.rowData.StatusNotes, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            </div>
          </div>
        ) : props.element.Status !== "Rejected" &&
          props.element.Status !== "Pending" ? (
          <div>
            <Table
              localeText={{ noRowsLabel: t("global.no-results-found") }}
              columns={[
                {
                  align: "left",
                  field: "BenefitName",
                  headerAlign: "left",
                  headerClassName: "textAlignLeft",
                  headerName: t("claim.table.header.output-type"),
                  cellClassName: () => {
                    return styles.mobileViewBenefitName;
                  },
                  sortable: false,
                  width: 130,
                },
                {
                  align: "left",
                  field: "RequestedAmount",
                  headerAlign: "left",
                  headerClassName: "textAlignLeft",
                  headerName: t("claim.table.header.amount-requested"),
                  valueFormatter: (props) => {
                    return ConvertDecimalToString(props.value);
                  },
                  sortable: false,
                  width: 110,
                },
                {
                  align: "left",
                  field: "RemovableAmount",
                  headerAlign: "left",
                  headerClassName: "textAlignLeft",
                  headerName: t("claim.table.header.amount-deductible"),
                  valueFormatter: (props) => {
                    return ConvertDecimalToString(props.value);
                  },
                  sortable: false,
                  width: 110,
                },
                {
                  align: "left",
                  field: "CompensationPercent",
                  headerAlign: "left",
                  headerClassName: "textAlignLeft",
                  headerName: t("claim.table.header.cost-performance"),
                  valueFormatter: (props) => {
                    return props.value + "%";
                  },
                  sortable: false,
                  width: 110,
                },
                {
                  align: "left",
                  field: "CompensationAmount",
                  headerAlign: "left",
                  headerClassName: "textAlignLeft",
                  headerName: t("claim.table.header.compensation-amount"),
                  valueFormatter: (props) => {
                    return ConvertDecimalToString(props.value);
                  },
                  sortable: false,
                  width: 120,
                },
              ]}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              getRowId={(row) => row.RowId}
              rows={props.element.Benefits}
            />
          </div>
        ) : (
          <div className={styles.mobileViewRejectionContainer}>
            <div>
              <Label text={t("claim.rejection-reason")} />
              <Paragraph>{props.element.StatusReason}</Paragraph>
            </div>
            {props.element.StatusNotes ? (
              <div style={{ marginTop: "30px" }}>
                <Label text={t("global.details")} />
                <Paragraph>{props.element.StatusNotes}</Paragraph>
              </div>
            ) : (
              <></>
            )}
            {props.element.Status === "Pending" ? (
              <div>
                <div className={wizardStyles.claimSubmissionWizardContainer}>
                  <PrefabModal
                    isOpen={submittingFiles}
                    width="500px"
                    height="auto"
                    headingText={" "}
                    children={
                      <div className={wizardStyles.submissionModalLoading}>
                        <SpinnerLoader variant="Large" />
                        <Paragraph>{t("claim.processing-request")}</Paragraph>
                      </div>
                    }
                  />
                  <PrefabModal
                    isOpen={
                      showSubmissionSuccessModal || showSubmissionFailModal
                    }
                    onClose={() => handleContiune()}
                    hasCloseButton={true}
                    width="500px"
                    height="auto"
                    headingText={" "}
                    children={
                      <div className={wizardStyles.submissionModalResult}>
                        <span className={wizardStyles.header}>
                          <Heading isFirstSection>
                            {showSubmissionSuccessModal
                              ? t("claim.submission.completed-succesfull")
                              : t("claim.submission.completed-failure")}
                          </Heading>
                        </span>
                        <div className={wizardStyles.submissionModalResultDiv}>
                          {showSubmissionSuccessModal ? (
                            <Paragraph>{t("claim.return-list")}</Paragraph>
                          ) : (
                            <p
                              className="htmlParagraph"
                              dangerouslySetInnerHTML={{
                                __html: failureContactInfo,
                              }}
                            ></p>
                          )}
                          <Link
                            text={t("claim.compensation-list")}
                            variant="SecondaryStandAlone"
                            iconProps={{
                              name: "ChevronRight",
                              placement: "Right",
                            }}
                            onClick={handleContiune}
                          />
                        </div>
                      </div>
                    }
                  />
                  <FilesUpload
                    filesTypes={fileTypes}
                    files={files}
                    addFile={addFile}
                    removeFile={removeFile}
                    policyNumber={props.element.PolicyNo}
                    onlyShowFilledFileTypes={true}
                    hideHeader={true}
                    noFileSelectedText={t("claim.add-documents")}
                  />
                </div>
                <div
                  className={`${styles.filesSubmitButton} ${
                    files.length > 0 && !submittingFiles
                      ? ""
                      : styles.disabledBtn
                  }`}
                >
                  <Button
                    variant="PrimarySmall"
                    type="Button"
                    onClick={() => {
                      if (!submittingFiles) submitFiles();
                    }}
                  >
                    Υποβολή
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </>
    );
  }, [
    addFile,
    fileTypes,
    files,
    handleContiune,
    failureContactInfo,
    props.element.Benefits,
    props.element.PolicyNo,
    props.element.Status,
    props.element.StatusNotes,
    props.element.StatusReason,
    removeFile,
    showSubmissionFailModal,
    showSubmissionSuccessModal,
    submitFiles,
    submittingFiles,
  ]);

  const body = useCallback(() => {
    return (
      <div className={styles.mobileViewElementContainer}>
        <div
          className={styles.chevroDown}
          style={{ visibility: `${claimIsInProcessingStatus ? "hidden" : ""}` }}
        >
          <Icon
            name={expand ? "ChevronUp" : "ChevronDown"}
            variant="Small"
            color="#EA650D"
          />
        </div>
        <div className={styles.basicProperties}>
          <Label
            subtext={props.element.ClaimNo}
            text={t("claim.table.header.compensation-number")}
          />
          <Label
            subtext={props.element.PolicyNo}
            text={t("claim.table.header.contract-number")}
          />
          <Label
            subtext={props.element.InsuredName}
            text={t("claim.table.header.insured-member")}
          />
          <Label
            subtext={setDateFormatForDateTime(props.element.EventDate)}
            text={t("claim.table.header.event-date")}
          />
          <Label
            subtext={props.element.StatusName}
            text={t("global.condition")}
          />
          <Label text={t("claim.table.header.registered-email")}>
            {props.element.PortalSubmitted ? (
              <Icon name="Checkmark" variant={"Small"} color="#EA650D" />
            ) : (
              <span className={styles.portalSubmittedDash}>-</span>
            )}
          </Label>
          <span className={styles.claimsDocumentLabelIconGroup}>
            {!claimIsInProcessingStatus &&
            props.element.Payments &&
            props.element.Payments.length > 0 ? (
              <>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.handleModalClick({
                      showModalVal: true,
                      documentList: props.element.Payments,
                    });
                  }}
                  onTouchEnd={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.handleModalClick({
                      showModalVal: true,
                      documentList: props.element.Payments,
                    });
                  }}
                >
                  <Icon
                    name="ArrowDownLine"
                    variant={"Medium"}
                    color="#EA650D"
                    mr="10px"
                  />
                </a>
                <Label text={t("claim.table.header.detailed")} />
              </>
            ) : (
              "-"
            )}
          </span>
        </div>
      </div>
    );
  }, [props, expand, claimIsInProcessingStatus]);

  return (
    <div
      className={`${styles.mobileViewCollapsibleElementContainer} ${
        expand === "expand" ? styles.mobileViewExpandedBackground : ""
      } mobileViewRejectionContainer`}
    >
      <Collapsible
        labelText=""
        onChange={handleStyleClass}
        subtext={body()}
        children={!claimIsInProcessingStatus ? children() : null}
      />
    </div>
  );
};

export default observer(ClaimElementMobileView);
