import React, { useCallback, useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  Select,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType } from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";

const MaternityAllowance = (props) => {
  const { t } = useTranslation();
  const invalidValueText = t("claim.submission.invalid-value");
  const [childBirthType, setChildBirthType] = useState("");
  const [childBirthOptions, setChildBirthOptions] = useState([]);
  const [childBirthTypeError, setChildBirthTypeError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);

  const claimsStore = useContext(ClaimsStore);

  const { basicSubmissionInfoToSubmit, expenseTypes, documentTypes } =
    claimsStore;

  const validateChildBirthType = useCallback(
    (value) => {
      if (value !== "") {
        setChildBirthTypeError("");
        return true;
      }
      setChildBirthTypeError(invalidValueText);
      return false;
    },
    [setChildBirthTypeError],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validateMaternityAllowanceInfo = useCallback(() => {
    const isChildBirthType = validateChildBirthType(childBirthType);
    const isFilesValid = validateFiles(files);

    return isChildBirthType && isFilesValid;
  }, [childBirthType, files, validateChildBirthType, validateFiles]);

  const createNewHospitalAllowanceClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: childBirthType,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ChildBirthType: childBirthType,
      Files: files,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(basicSubmissionInfoToSubmit.eventDate)),
      CollaboratingNetworkList: false,
      AmountList: 0,
      OtherEntityContributedList: false,
    });
  }, [
    childBirthType,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
    basicSubmissionInfoToSubmit.eventDate,
    claimsStore,
  ]);

  const saveEditedHospitalAllowanceClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit.Id,
      ExpenseType: childBirthType,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      ChildBirthType: childBirthType,
      Files: files,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(basicSubmissionInfoToSubmit.eventDate)),
      CollaboratingNetworkList: false,
      AmountList: 0,
      OtherEntityContributedList: false,
    });
  }, [
    childBirthType,
    basicSubmissionInfoToSubmit.eventDate,
    files,
    basicSubmissionInfoToSubmit.mainInsuredMember,
    claimsStore,
    props.selectedClaimToEdit,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (validateMaternityAllowanceInfo()) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedHospitalAllowanceClaim();
      } else {
        createNewHospitalAllowanceClaim();
      }
      props.nextStep();
    }
  }, [
    validateMaternityAllowanceInfo,
    createNewHospitalAllowanceClaim,
    saveEditedHospitalAllowanceClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      setChildBirthType(props.selectedClaimToEdit.ChildBirthType);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [props.selectedClaimToEdit, setChildBirthType, setFiles]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const handleChildBirthTypeChange = useCallback(
    (newChildBirthType) => {
      setFiles([]);
    },
    [setFiles],
  );

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    const options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];
    expenseTypes
      .filter(
        (type) =>
          type.Name === ExpenseType.NormalMaternityAllowance ||
          type.Name === ExpenseType.CaesareanMaternityAllowance ||
          type.Name === ExpenseType.MiscarriageMaternityAllowance,
      )
      .forEach((element) => {
        options.push({
          text: element.DisplayName,
          value: element.Name,
        });
      });
    setChildBirthOptions(options);
  }, [expenseTypes, setChildBirthOptions]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find((et) => et.Name === childBirthType);

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }
    setFilesTypes(options);
  }, [childBirthType, documentTypes, expenseTypes, setFilesTypes]);

  return (
    <div>
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            labelText={t("claim.maternity.delivery-type")}
            name="ChildBirthType"
            options={childBirthOptions}
            onChange={(event) => {
              setChildBirthType(event.target.value);
              validateChildBirthType(event.target.value);
              handleChildBirthTypeChange(event.target.value);
            }}
            value={childBirthType}
            validationText={childBirthTypeError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div className={styles.nextStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("claim.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(MaternityAllowance);
