import {
  Paragraph,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import "../../../Css/CommonCss/General.css";

const NoGroupPolicyBenefitsModal = ({
  showNoGroupPolicyBenefitsModal,
  handleCancel,
  message,
  title,
  isHtml,
}) => {
  const { t } = useTranslation();
  return (
    <PrefabModal
      isOpen={showNoGroupPolicyBenefitsModal}
      onClose={() => handleCancel()}
      width="500px"
      height="auto"
      headingText={title}
      children={
        isHtml ? (
          <p
            className="htmlParagraph"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></p>
        ) : (
          <Paragraph mb="10px" variant="Medium">
            {message}
          </Paragraph>
        )
      }
      primaryActionProps={{
        onClick: () => {
          handleCancel();
        },
        text: t("global.continue"),
      }}
    />
  );
};

export default NoGroupPolicyBenefitsModal;
