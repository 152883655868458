import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class HRStore {
  isHrRole = null;
  hrContractsList = [];
  selectedContractHRDetails = {};
  coverageHasContent = false;
  showCoveragesStore = false;
  showBillStore = false;
  billHasContent = false;
  showAgentStore = false;
  showDocuementsStore = false;
  hrCoveragesList = [];
  hrDocumentsList = [];
  paymentsHR = [];
  showPaymentsStore = false;
  paymentsHasContent = false;
  showClaimsStore = false;
  allPaymentsHR = undefined;
  contractNelisIDResponse = [];
  claimsHR = [];
  claimsSelectedFilterValue = {};
  hrContractsListHasResponse = false;
  hrInsuredMembersList = [];
  showInsuredMembersStore = false;
  insuredMembersHasContent = false;
  hasGroupPensionPaymentsData = false;
  groupPensionPaymentsData = [];
  hasGroupPensionAccountBalanceData = false;
  groupPensionAccountBalanceData = [];

  constructor() {
    makeObservable(this, {
      isHrRole: observable,
      hrContractsList: observable,
      selectedContractHRDetails: observable,
      showCoveragesStore: observable,
      coverageHasContent: observable,
      showBillStore: observable,
      billHasContent: observable,
      showAgentStore: observable,
      hrCoveragesList: observable,
      hrDocumentsList: observable,
      showDocuementsStore: observable,
      paymentsHR: observable,
      showPaymentsStore: observable,
      paymentsHasContent: observable,
      allPaymentsHR: observable,
      contractNelisIDResponse: observable,
      claimsHR: observable,
      showClaimsStore: observable,
      claimsSelectedFilterValue: observable,
      hrContractsListHasResponse: observable,
      hrInsuredMembersList: observable,
      showInsuredMembersStore: observable,
      insuredMembersHasContent: observable,
      hasGroupPensionPaymentsData: observable,
      groupPensionPaymentsData: observable,
      hasGroupPensionAccountBalanceData: observable,
      groupPensionAccountBalanceData: observable,

      setIsHrRole: action,
      loadHRContracts: action,
      deloadHRContractDetail: action,
      setShowHRCoverageStore: action,
      setShowHRBillStore: action,
      setShowHRAgentStore: action,
      loadHRCoverages: action,
      loadHRDocuments: action,
      loadHRPayments: action,
      setPaymentsHRList: action,
      loadClaimsHRSelected: action,
      setShowHRDocumentsStore: action,
      setShowHRClaimsStore: action,
      setClaimsSelectedFilterValue: action,
      loadHRInsuredMembers: action,
      setShowHRInsuredMembers: action,
      loadGroupPensionHRPayments: action,
      setShowHRGroupPensionHRPayments: action,
      loadGroupPensionHRAccountBalance: action,
      setShowHRGroupPensionHRAccountBalance: action,
    });
  }
  //this need to change
  setIsHrRole = (value) => {
    this.isHrRole = value;
  };
  deloadHRContractDetail = () => {
    this.selectedContractHRDetails = {};
  };

  setShowHRCoverageStore = () => {
    this.showCoveragesStore = false;
    this.coverageHasContent = false;
  };

  setShowHRBillStore = () => {
    this.showBillStore = false;
    this.billHasContent = false;
  };

  setShowHRAgentStore = () => {
    this.showAgentStore = false;
  };

  setShowHRDocumentsStore = () => {
    this.showDocuementsStore = false;
  };
  setShowHRClaimsStore = () => {
    this.showClaimsStore = false;
  };

  setClaimsSelectedFilterValue = (value) => {
    this.claimsSelectedFilterValue = value;
  };

  setShowHRInsuredMembers = () => {
    this.showInsuredMembersStore = false;
    this.insuredMembersHasContent = false;
  };

  setShowHRGroupPensionHRPayments = () => {
    this.hasGroupPensionPaymentsData = false;
  };

  setShowHRGroupPensionHRAccountBalance = () => {
    this.hasGroupPensionAccountBalanceData = false;
  };

  loadHRDocuments = (companyId, policyNumber) => {
    const documents = agent.hr.getDocumentsListHR(companyId, policyNumber);
    documents
      .then((result) => {
        this.hrDocumentsList = [...result];
        this.showDocuementsStore = true;
      })
      .catch((error) => {
        this.hrDocumentsList = [];
        this.showDocuementsStore = true;
        console.log(error, "error in documentsHR");
      });
  };

  loadHRContracts = (companyNelisId) => {
    this.hrContractsListHasResponse = false;
    const contracts = agent.hr.getContractListHR(companyNelisId);
    contracts
      .then((result) => {
        this.hrContractsListHasResponse = true;
        this.hrContractsList = [...result.ContractList];
        this.contractNelisIDResponse = result.ContractList.map(
          ({ NelisId }) => NelisId,
        );
      })
      .catch((error) => {
        this.hrContractsListHasResponse = true;
        this.hrContractsList = [];
        console.log(error);
      });
  };
  loadHRCoverages = (policy) => {
    const coverages = agent.hr.getContractCoveragesHR(policy);
    coverages
      .then((result) => {
        this.hrCoveragesList = result.persons.map((person) => ({
          FullName: person.FirstName + " " + person.LastName,
          ...person,
        }));
        this.showCoveragesStore = true;
        this.coverageHasContent = true;
      })
      .catch((error) => {
        console.log(error);
        this.hrCoveragesList = [];
        this.showCoveragesStore = true;
        this.coverageHasContent = true;
      });
  };

  loadHRInsuredMembers = (policy) => {
    const insuredMembers = agent.hr.getInsuredMembers(policy);
    insuredMembers
      .then((result) => {
        this.hrInsuredMembersList = result.InsuredMembers.map((person) => {
          let AnalysisValues = [...person.Analysis];
          if (AnalysisValues && AnalysisValues.length > 0) {
            AnalysisValues.push({
              InvestmentType: "",
              EmployerContribution:
                "lastRow" + person.TotalEmployerContribution,
              VoluntaryContribution:
                "lastRow" + person.TotalVoluntaryContribution,
            });
            person.Analysis = [...AnalysisValues];
          }
          return {
            FullName: person.FirstName + " " + person.LastName,
            ...person,
          };
        });
        this.showInsuredMembersStore = true;
        this.insuredMembersHasContent = true;
      })
      .catch((error) => {
        console.log(error);
        this.hrCoveragesList = [];
        this.showInsuredMembersStore = true;
        this.insuredMembersHasContent = true;
      });
  };

  loadHRPayments = (companyNelisId) => {
    const allPaymentsHR = agent.hr.getPaymentsHR(companyNelisId);

    allPaymentsHR
      .then((result) => {
        this.paymentsHR = [...result.Contracts];

        this.showPaymentsStore = true;
        this.paymentsHasContent = true;
      })
      .catch((error) => {
        console.log(error, "error payments response");
        this.paymentsHR = [];
        this.showPaymentsStore = true;
        this.paymentsHasContent = true;
      });
  };

  loadGroupPensionHRPayments = (contractId) => {
    this.hasGroupPensionPaymentsData = false;
    const groupPensionPaymentsDataElement = this.groupPensionPaymentsData.find(
      (elem) => elem.contractId === contractId,
    );
    if (groupPensionPaymentsDataElement) {
      this.hasGroupPensionPaymentsData = true;
    } else {
      const agentResponse = agent.hr.getGroupPensionPayments(contractId);
      agentResponse.then((result) => {
        if (result && Array.isArray(result)) {
          this.groupPensionPaymentsData.push({
            contractId,
            payments: [...result],
          });
        }
        this.hasGroupPensionPaymentsData = true;
      });
    }
  };

  loadGroupPensionHRAccountBalance = (contractId) => {
    this.hasGroupPensionAccountBalanceData = false;
    const groupPensionAccountBalanceDataElement =
      this.groupPensionAccountBalanceData.find(
        (elem) => elem.contractId === contractId,
      );
    if (groupPensionAccountBalanceDataElement) {
      this.hasGroupPensionAccountBalanceData = true;
    } else {
      const agentResponse = agent.hr.getGroupPensionAccountBalance(contractId);
      agentResponse.then((result) => {
        if (result && Array.isArray(result.Investments)) {
          this.groupPensionAccountBalanceData.push({ contractId, ...result });
        }
        this.hasGroupPensionAccountBalanceData = true;
      });
    }
  };

  setPaymentsHRList = (payments, contracts) => {
    const resultArray = payments.map((item) => {
      const matchingPolicy = contracts.find(
        (policy) => policy.PolicyNumber === item.ContractNumber,
      );
      if (matchingPolicy) {
        return { ...item, CompanyName: matchingPolicy.CompanyName };
      }
      return item;
    });
    this.allPaymentsHR = resultArray;
  };

  loadClaimsHRSelected = (contract, dates, employeeNo) => {
    const claimsHR = agent.hr.getClaimsHR(contract, dates, employeeNo);

    claimsHR
      .then((result) => {
        this.claimsHR = [...result];
        this.showClaimsStore = true;
      })
      .catch((error) => {
        this.claimsHR = [];
        this.showClaimsStore = true;
        console.log(error, "error payments response");
      });
  };
}
export default createContext(new HRStore());
