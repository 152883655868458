import { useState } from "react";
import { dateFormatError, minDateErrorMsg } from "../../Utils/Constants";

export const usePaymentsHRFilter = (initialForm = {}, minDate) => {
  const [formState, setFormState] = useState(initialForm);
  const [validValue, setValidValue] = useState({ isValidValue: true });

  const onInputChange = (event, inputName) => {
    let valueInput = inputName === "datePickerRange" ? event : event?.target?.value;
    let nameInput = inputName === "datePickerRange" ? "datePickerRange" : event?.target?.name;
    if (nameInput === "datePickerRange") {
      validationValue(valueInput);
    }

    setFormState({
      ...formState,
      [nameInput]: valueInput,
    });
  };

  const clearOnSearch = () => {
    setFormState({
      ...formState,
      searchInput: "",
    });
  };
  const resetFilter = () => {
    setFormState(initialForm);
  };
  const validationValue = (value) => {
    setValidValue({ isValidValue: value[0] !== null && value[1] !== null });
    if (value.some((val) => isNaN(new Date(val)))) return setValidValue({ isValidValue: false, errorMsg: dateFormatError });
    if (new Date(minDate) > new Date(value[0])) {
      setValidValue({ isValidValue: false, errorMsg: minDateErrorMsg(minDate) });
    }
  };
  return {
    ...formState,
    onInputChange,
    resetFilter,
    clearOnSearch,
    validValue,
  };
};
