import React, { useContext, useEffect, useState } from "react";
import B2cStore from "../../Stores/B2cStore";
import UserStore from "../../Stores/UserStore";
import { observer } from "mobx-react-lite";
import SettingsStore from "../../Stores/SettingsStore";

const SignInB2C = (props) => {
  const b2cStore = useContext(B2cStore);
  const { loginRequest } = b2cStore;
  const settingsStore = useContext(SettingsStore);
  const { publicSettings } = settingsStore;
  const userStore = useContext(UserStore);

  const [showLoginPage, setShowLoginPage] = useState(false);

  const authCallback = (error, response) => {
    // Error handling
    if (error) {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (error.errorMessage.indexOf("AADB2C90118") > -1) {
        try {
          // Password reset policy/authority
          props.authService.loginRedirect(props.authorities.forgotPassword);
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      // We need to reject id tokens that were not issued with the default sign-in policy.
      // "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr")
      // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview

      if (
        response.tokenType === "id_token" &&
        response.idToken.claims["tfp"] === publicSettings.AzureADUserFlowResetPassword
      ) {
        props.authService.logout();
        // window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
      } else if (
        response.tokenType === "id_token" &&
        response.idToken.claims["tfp"] === publicSettings.AzureADUserFlowSignIn
      ) {
        userStore.getResponseUserName(response.idToken.name);
        setShowLoginPage(false);
      }
    }
  };

  useEffect(() => {
    if (props.authService) {
      props.authService.handleRedirectCallback(authCallback);
    }
  }, [props.authService]);

  useEffect(() => {
    if (props.authService) {
      if (showLoginPage) {
        props.authService.loginRedirect(loginRequest);
      }
    }
  }, [showLoginPage, props.authService]);

  useEffect(() => {
    if (!sessionStorage.hasOwnProperty("msal.idtoken") && !sessionStorage.hasOwnProperty("msal.interaction_status")) {
      if (window.sessionStorage.hasOwnProperty("forgotPasswordInitiatedWhen")) {
        window.sessionStorage.removeItem("forgotPasswordInitiatedWhen");
        props.authService.loginRedirect(props.authorities.forgotPassword);
      } else {
        setShowLoginPage(true);
      }
    }
  }, []);

  return null;
};

export default observer(SignInB2C);
