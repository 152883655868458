import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class KycStore {
  currentPage = undefined;
  kycUrl = undefined;
  kycUrlIsLoading = false;
  kycAccessResponse = null;
  kycTermAcceptanceResponse = undefined;
  kycAcceptanceIsLoading = false;
  pdfBinary = null;
  isServiceAlive = true;

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      kycUrl: observable,
      kycAccessResponse: observable,
      kycAcceptanceIsLoading: observable,
      kycTermAcceptanceResponse: observable,
      pdfBinary: observable,
      isServiceAlive: observable,
      kycUrlIsLoading: observable,

      setCurrentPage: action,
      getKycUrl: action,
      setUrlKycData: action,
      sendKycTermAcceptance: action,
      resetTermAcceptance: action,
      resetKYCUrl: action,
      resetIsServiceAlive: action,
    });
  }

  setCurrentPage = (page) => {
    this.currentPage = page;
  };

  resetTermAcceptance = (value) => {
    if (!this.kycTermAcceptanceResponse) {
      this.sendKycTermAcceptance(false, value);
    }
  };
  resetKYCUrl = () => {
    this.kycUrl = undefined;
  };

  getKycUrl = ({ hasKycAllParams }) => {
    this.kycUrlIsLoading = true;
    const responseKycUrl = agent.kyc.getKycUrl(hasKycAllParams);
    responseKycUrl
      .then((response) => {
        if (response) {
          this.kycUrlIsLoading = false;

          if (response.Success) {
            this.isServiceAlive = true;
            this.kycUrl = response;
          } else {
            this.isServiceAlive = false;
          }
        } else {
          this.kycUrlIsLoading = false;

          this.isServiceAlive = response;
        }
      })
      .catch((error) => {
        console.log("request for KYC URL", error);
      });
  };

  setUrlKycData = (body) => {
    this.kycAcceptanceIsLoading = true;
    const setDataFromKyc = agent.kyc.sendResponseFromKyc(body);
    setDataFromKyc
      .then((response) => {
        if (response) {
          this.kycAccessResponse = response;
        } else {
          this.kycAccessResponse = {};
          this.kycAccessResponse.Success = response;
        }
        this.kycAcceptanceIsLoading = false;
      })
      .catch((error) => {
        console.log(error, "setDataFromKyc");
        this.kycAccessResponse = {};
        this.kycAcceptanceIsLoading = false;
      });
  };

  sendKycTermAcceptance = (value, state) => {
    let body = {
      state: this.kycAccessResponse?.State || state,
      termAcceptance: value,
    };
    this.kycAcceptanceIsLoading = true;
    const responseKycTermAcceptance = agent.kyc.sendTermAcceptanceKyc(body);
    responseKycTermAcceptance
      .then((response) => {
        if (response) {
          this.kycTermAcceptanceResponse = response;
        } else {
          this.kycTermAcceptanceResponse = {};
          this.kycTermAcceptanceResponse.Success = response;
        }
        this.kycAcceptanceIsLoading = false;
      })
      .catch((error) => {
        this.kycAcceptanceIsLoading = false;
        this.kycTermAcceptanceResponse = undefined;
      });
  };
  resetIsServiceAlive = () => {
    this.isServiceAlive = true;
  };
}
export default createContext(new KycStore());
