import React from "react";
import { Label, Paragraph } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ElementDownload from "./ElementDownload";

export const DocumentsList = ({ elemDoc }) => {
  const { t } = useTranslation();
  return (
    <div className="list-container">
      <div>
        <Label text={t("file-upload.document-type")} hasMargin={false} />
        <Paragraph hasMargin={false}>{elemDoc.DocumentType}</Paragraph>
      </div>
      <div>
        <Label text={t("document.date-issue")} hasMargin={false} />
        <Paragraph hasMargin={false}>{elemDoc.IssueDate}</Paragraph>
      </div>
      <div>
        <Label text={t("document.download")} />
        <ElementDownload
          DocumentLink={elemDoc.DocumentLink}
          DocumentType={elemDoc.DocumentType}
        />
      </div>
    </div>
  );
};
