import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import ArticleStore from "../../Stores/ArticleStore";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import UrlStore from "../../Stores/UrlStore";
import img from "../../assets/index";
import { Icon } from "@nn-design-system/react-component-library";

const ArticleByCategories = (props) => {
  const { t } = useTranslation();
  const { articleCategories, articleList } = useContext(ArticleStore);
  const { baseURL } = useContext(UrlStore);
  const [filteredArticleList, setFilteredArticleList] = useState(null);
  const [addImage, setAddImage] = useState(img.chevron_down);
  const [arrayQuantityFilter, setArrayQuantityFilters] = useState(3);

  const navigate = useNavigate();

  useEffect(() => {
    if (articleList) {
      let fileredList = articleList.filter(
        (a) => a.NodeParentId === props.category.NodeId,
      );
      setFilteredArticleList(fileredList);
    }
  }, [articleCategories, articleList]);

  const handleStyleClass = () => {
    if (addImage === img.chevron_up) {
      setAddImage(img.chevron_down);
      setArrayQuantityFilters(3);
    } else {
      setAddImage(img.chevron_up);
      setArrayQuantityFilters(articleList.length);
    }
  };

  const clickToDetails = (detail, elem) => {
    navigate(`/knowledgepoint/${detail}`, { state: toJS(elem) });
  };

  return (
    <div className="section">
      {filteredArticleList ? (
        <>
          <div className="bar">
            <div>
              <h2>
                {props.category.Title} ({filteredArticleList.length})
              </h2>
            </div>
            <div onClick={handleStyleClass} className="see-more">
              <span>{t("articleCarousel-see-more")}</span>
              <Icon
                ml={"10px"}
                mb={"-3px"}
                name="ChevronDown"
                variant="Small"
                color="#EA650D"
              />
            </div>
          </div>
          <div className="list">
            {filteredArticleList
              .slice(0, arrayQuantityFilter)
              .map((element, i) => {
                return (
                  <Fragment key={i}>
                    {element ? (
                      <div id={i} className="item">
                        <div
                          onClick={() => clickToDetails("details", element)}
                          className="virtual-link"
                        >
                          <h3>
                            <b>{element.Title}</b>
                          </h3>
                          {element.TeaserImage && (
                            <div className="img-wrapper">
                              <img
                                src={`${baseURL}${element.TeaserImage.Path}`}
                                alt={element.TeaserImage.AlternativeText}
                              />
                            </div>
                          )}
                          <span>{element.Summary}</span>
                          <a className="read-more">
                            <Icon
                              mr={"10px"}
                              mt={"1px"}
                              name="ChevronRight"
                              variant="Small"
                              color="#EA650D"
                            />
                            <span>{element.LinkForMore}</span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
          </div>
        </>
      ) : null}
    </div>
  );
};
export default observer(ArticleByCategories);
