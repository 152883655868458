import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import InboxStore from "../../Stores/InboxStore";
import AlertComponent from "./AlertComponent";

const DashboardAlerts = () => {
  const inboxStore = useContext(InboxStore);
  const { alertMessageAlerts, messageCategories } = inboxStore;

  const getCategoryName = (inputCategoryId) => {
    if (messageCategories) {
      let category = messageCategories.find((c) => c.NodeId === inputCategoryId);
      return category.Title;
    }
  };

  return (
    <div className="dashboard-alerts">
      {alertMessageAlerts
        ? alertMessageAlerts.map((alertMessageElement, i) => {
            alertMessageElement.category = getCategoryName(alertMessageElement.NodeParentId);
            return <AlertComponent key={i} alertMessageElement={alertMessageElement} />;
          })
        : null}
    </div>
  );
};

export default observer(DashboardAlerts);
