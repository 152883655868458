import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HRStore from "../../../Stores/HRStore";
import LoaderStore from "../../../Stores/LoaderStore";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
import PaymentsSelectedTableHR from "./PaymentsSelectedTableHR";

const PaymentsSelectedHR = ({ contract }) => {
  const loaderStore = useContext(LoaderStore);
  const { paymentsHR, paymentsHasContent } = useContext(HRStore);
  const [list, setList] = useState(undefined);

  useEffect(() => {
    let filterPaymentsSelected = paymentsHR.filter(
      (el) => el.ContractNumber === contract,
    );
    setList(filterPaymentsSelected);
    loaderStore.setMiniLoaderForData3(false);
  }, [paymentsHR]);

  return (
    <div className="contract-hr-tab-container">
      {paymentsHasContent ? (
        <div className="selected-contract-coverages hr-coverage-selected">
          {!!list?.length
            ? list?.map((el) => (
                <PaymentsSelectedTableHR
                  contract={contract}
                  element={el.Invoices}
                />
              ))
            : null}
        </div>
      ) : (
        <SpinnerSmall3></SpinnerSmall3>
      )}
    </div>
  );
};

export default observer(PaymentsSelectedHR);
