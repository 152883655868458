import React from "react";
import { Heading, Paragraph, SpinnerLoader } from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { PortfolioLineChart } from "./Charts/PortfolioLineChart";
import { usePortfolioLineChart } from "./hooks/usePortfolioLineChart";

const PortfolioPerFundChart = ({ fundName, dateRange, fundPrices }) => {
  const { zoomDomain, domainY, finalData, domainX } = usePortfolioLineChart({ dateRange, fundPrices });

  return (
    <div style={{ height: "300px" }}>
      <Paragraph hasMargin={false}>Ιστορικότητα τιμών</Paragraph>
      <Heading mt="14px" hasMargin={false}>
        {fundName}
      </Heading>
      {fundPrices?.length && "x" in zoomDomain ? (
        <PortfolioLineChart zoomDomain={zoomDomain} finalData={finalData} domainY={domainY} domainX={domainX} />
      ) : (
        <div style={{ display: "flex", marginTop: 40 }}>
          <SpinnerLoader />
        </div>
      )}
    </div>
  );
};

export default observer(PortfolioPerFundChart);
