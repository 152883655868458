import { Button, TextInput } from "@nn-design-system/react-component-library";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { htmlElement } from "../CommonComp/Functions";
import { textInputValidation } from "../CommonComp/Validations";
import RegistrationRadioButton from "./RegistrationRadioButton";
import { useTranslation } from "react-i18next";
const RegisterFormStep1 = (props) => {
  const { t } = useTranslation();
  const [myUrl, setMyUrl] = useState("/register");
  const [validSurName, setValidSurName] = useState("");
  const [validName, setValidName] = useState("");
  const [validFatherName, setValidFatherName] = useState("");
  const [buttonVariable, setButtonVariable] = useState("SecondaryMedium");
  const navigate = useNavigate();

  const onChange = (event) => {
    textInputValidation(
      event,
      "firstname",
      props.regValues.firstName,
      props.regValues.setFirstName,
      setValidName,
      50,
      "text",
      true,
    );
    textInputValidation(
      event,
      "lastname",
      props.regValues.surname,
      props.regValues.setSurname,
      setValidSurName,
      50,
      "text",
      true,
    );
    textInputValidation(
      event,
      "fathername",
      props.regValues.middleName,
      props.regValues.setMiddleName,
      setValidFatherName,
      50,
      "text",
      true,
    );
  };

  useEffect(() => {
    if (
      props.regValues.firstName &&
      props.regValues.middleName &&
      props.regValues.surname
    ) {
      setMyUrl(
        props.partnerRegistration ? "/partner/register/2" : "/register/2",
      );
      setButtonVariable("PrimaryMedium");
    } else {
      setMyUrl(props.partnerRegistration ? "/partner/register" : "/register");
      setButtonVariable("SecondaryMedium");
    }
  }, [
    props.regValues.firstName,
    props.regValues.surname,
    props.regValues.middleName,
  ]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const policyNumber =
      urlParams.get("policyNumber") ?? urlParams.get("policynumber");

    if (policyNumber && policyNumber !== null) {
      props.regValues.setContractNumber(policyNumber);
      props.regValues.setIsContractNumberPrefilled(true);
    }
  }, []);

  const checkFiledsAndNavigate = (url) => {
    if (!props.regValues.firstName)
      setValidName(htmlElement(t("global.fill-field")));
    if (!props.regValues.surname)
      setValidSurName(htmlElement(t("global.fill-field")));
    if (!props.regValues.middleName)
      setValidFatherName(htmlElement(t("global.fill-field")));
    navigate(url);
  };

  return (
    <div>
      <h2>
        <span className="light">{t("register.sign-up")}</span>{" "}
        <span className="normal">
          στο myNN {props.partnerRegistration ? "ως" : null}{" "}
        </span>
        {props.partnerRegistration ? (
          <div className="dark">{t("register.in-the-nn")}</div>
        ) : null}
      </h2>
      {!props.partnerRegistration ? (
        <div className="form-element right-padded">
          <div className="switch-entity-type">
            <RegistrationRadioButton
              props={props}
              defaultValue={"Individual"}
            />
          </div>
        </div>
      ) : null}
      <div className="form-element right-padded">
        <TextInput
          labelText={t("register.name")}
          name="name"
          onChange={onChange}
          onBlur={() => setValidName("")}
          validationText={validName}
          id="firstname"
          value={props.regValues.firstName}
          placeholderText={t("register.fill-your-name")}
        />
      </div>
      <div className="form-element right-padded">
        <TextInput
          labelText={t("account.last-name")}
          name="name"
          onChange={onChange}
          onBlur={() => setValidSurName("")}
          validationText={validSurName}
          id="lastname"
          placeholderText={t("register.fill-your-last-name")}
          value={props.regValues.surname}
        />
      </div>
      <div className="form-element right-padded">
        <TextInput
          labelText="Όνομα Πατρός"
          name="name"
          onChange={onChange}
          onBlur={() => setValidFatherName("")}
          validationText={validFatherName}
          id="fathername"
          placeholderText={t("register.father-name")}
          value={props.regValues.middleName}
        />
      </div>
      <Button
        onClick={() => checkFiledsAndNavigate(myUrl)}
        variant={buttonVariable}
      >
        {t("global.next")}
      </Button>
    </div>
  );
};
export default RegisterFormStep1;
