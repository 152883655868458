import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class DocumentsStore {
  documentList = [];
  documentsHasContent = false;
  customerDocumentList = [];
  clientDocumentsHasContent = false;

  constructor() {
    makeObservable(this, {
      documentList: observable,
      documentsHasContent: observable,
      customerDocumentList: observable,
      clientDocumentsHasContent: observable,

      loadCustomerDocuments: action,
    });
  }

  loadContractDocuments = () => {
    this.documentsHasContent = false;
    const documets = agent.documents.contractlist();
    documets
      .then((result) => {
        const newDocumetsList = [...result];
        this.documentList = newDocumetsList.sort((a, b) =>
          b.IssueDate > a.IssueDate ? 1 : -1,
        );
        this.documentsHasContent = true;
        // console.log(result, "from customer docs1");
      })
      .catch((error) => {
        this.documentsHasContent = true;
      });
  };

  loadCustomerDocuments = () => {
    this.clientDocumentsHasContent = false;
    const customerDocuments = agent.documents.customerList();
    customerDocuments
      .then((result) => {
        const newCustomerDocumetsList = [...result];
        this.customerDocumentList = newCustomerDocumetsList.sort((a, b) =>
          b.IssueDate > a.IssueDate ? 1 : -1,
        );
        this.clientDocumentsHasContent = true;
        // console.log(result, "from customer docs2");
      })
      .catch((error) => {
        this.clientDocumentsHasContent = true;
      });
  };
}
export default createContext(new DocumentsStore());
