import { observer } from "mobx-react-lite";
import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToString,
  ConvertDecimalToStringWithCurrency,
} from "../CommonComp/Functions";
import ContractSelectedDocumentTable from "./ContractSelectedDocumentTable";
import ContractSelectedOseka from "./ContractSelectedOseka";
import ContractSelectedRelativeDocuments from "./ContractSelectedRelativeDocuments";

const InvestmentAccountContent = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="selected-contract-account-investment">
        <div className="account-value">
          <div>
            <p>{t("contract.account-value")}</p>
            <span>
              {ConvertDecimalToStringWithCurrency(
                props.contractDetails.AccountValue,
                props.contractDetails.CurrencyCode,
              )}
            </span>
          </div>
          <div>
            <p>{t("contract.takeover-value")}</p>
            <span>
              {ConvertDecimalToStringWithCurrency(
                props.contractDetails.SurrenderValue,
                props.contractDetails.CurrencyCode,
              )}
            </span>
            <span className="takeover-value">
              {t("contract.redemption-penalties-msg")}
            </span>
          </div>

          {props.contract.IsNonExpiredAmount ? null : (
            <div>
              {props.contract.IsGuaranteeExpiredAmount ? (
                <>
                  {props.contract.IsGuaranteeProgrammCapital ? (
                    <p>{t("contract.guaranteed-program-capital")}</p>
                  ) : (
                    <p>{t("contract.guaranteed-maturity-capital")}</p>
                  )}
                  <span>
                    {ConvertDecimalToStringWithCurrency(
                      props.contractDetails.ExpiredAmount,
                      props.contractDetails.CurrencyCode,
                    )}
                  </span>
                </>
              ) : (
                <>
                  <p>{t("contract.closing-capital")}</p>
                  <span>
                    {ConvertDecimalToStringWithCurrency(
                      props.contractDetails.ExpiredAmount,
                      props.contractDetails.CurrencyCode,
                    )}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <ContractSelectedOseka OsekaInvestmentLinks={props.OsekaLinks} />
        <ContractSelectedRelativeDocuments
          filteredDoc={props.filteredDoc}
          adHoc={props.adHoc}
          productCategory={props.contract.ProductCode}
        />
        <ContractSelectedDocumentTable
          Transactions={props.contractDetails.Transactions}
        />
      </div>
    </Fragment>
  );
};

export default observer(InvestmentAccountContent);
