import {
  RadioButton,
  RadioButtonGroup,
} from "@nn-design-system/react-component-library";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const RegistrationRadioButton = ({ props, defaultValue }) => {
  const { t } = useTranslation();
  const [radioType, setRadioType] = useState(defaultValue);
  const navigate = useNavigate();

  const goToCompany = () => {
    props.regValues.setRegType(2);
    navigate(`/register/2`, { state: 2 });
  };

  const goToIndividual = (val) => {
    props.regValues.setRegType(1);
    navigate(`/register`, { state: 2 });
  };
  const radioButtonHandler = (value) => {
    setRadioType(value);
    value === "Individual" ? goToIndividual() : goToCompany();
  };
  return (
    <div>
      <RadioButtonGroup
        labelText=""
        value={radioType}
        onChange={(e) => radioButtonHandler(e.target.value)}
        variant={"Default"}
      >
        <div className={"radiogroup radiogroup-register"}>
          <div>
            <RadioButton
              labelText={t("register.individual")}
              value={"Individual"}
            />
            <p className={"radiobutton"} id="subtitle">
              {t("register.individual-or-group-contact")}
            </p>
          </div>
          <div>
            <RadioButton labelText="Νομικό Πρόσωπο" value={"Company"} />
            <p className={"radiobutton"} id="subtitle">
              {t("commons.individual-contract")}
            </p>
          </div>
        </div>
      </RadioButtonGroup>
    </div>
  );
};

export default RegistrationRadioButton;
