import { action, observable, makeObservable, computed } from "mobx";
import { createContext } from "react";

class LoaderStore {
  loaderActive = false;
  loaderForData = false;
  mainContentLoader = false;
  miniLoaderForData = false;
  miniLoaderForData2 = false;
  miniLoaderForData3 = false;
  customerSupport = false;

  constructor() {
    makeObservable(this, {
      loaderActive: observable,
      loaderForData: observable,
      mainContentLoader: observable,
      miniLoaderForData: observable,
      miniLoaderForData2: observable,
      miniLoaderForData3: observable,
      customerSupport: observable,

      loaderAction: action,
      setLoaderForData: action,
      setMainContentLoader: action,
      setMiniLoaderForData: action,
      setMiniLoaderForData2: action,
      setMiniLoaderForData3: action,

      finishedLoading: computed,
    });
  }

  loaderAction = (val) => {
    this.loaderActive = val;
  };

  setMainContentLoader = (val) => {
    this.mainContentLoader = val;
  };

  setLoaderForData = (val) => {
    this.loaderForData = val;
  };

  setMiniLoaderForData = (val) => {
    this.miniLoaderForData = val;
  };
  setMiniLoaderForData2 = (val) => {
    this.miniLoaderForData2 = val;
  };
  setMiniLoaderForData3 = (val) => {
    this.miniLoaderForData3 = val;
  };
  setCustomerSupportLoader = (val) => {
    this.customerSupport = val;
  };

  get finishedLoading() {
    if (!this.customerSupport) {
      return this.loaderActive || this.loaderForData || this.mainContentLoader ? false : true;
    } else {
      return this.loaderActive || this.mainContentLoader ? false : true;
    }
  }
}
export default createContext(new LoaderStore());
