import React from "react";
import { Icon } from "@nn-design-system/react-component-library";
import img from "../../assets/index";
const PreviewHeader = () => {
  return (
    <>
      <div className="sticky top">
        <div className="nn-container">
          <header>
            <div className="logo-login">
              <a href="#" className="logo">
                <img src={img.logo_NN} alt="nnhellas-logo" />
              </a>{" "}
              <span className="myNN">myNN</span>{" "}
              <div class="login">
                <div class="login-info">
                  <p class="username">Άννα Οικονόμου</p>
                  <p class="logout-remaining-time">Αποσύνδεση σε 09:20</p>
                </div>
                <a href="" class="profile-icon">
                  {/* <img src={img.profile_icon} alt="profile-icon" /> */}
                  <Icon name="Profile" variant="Large" color="#EA650D" />
                </a>
                <a href="" class="logout">
                  {/* <img src={img.logout} alt="logout" /> */}
                  <Icon name="ArrowBox" variant="Large" color="#EA650D" />
                </a>
              </div>
              <span class="force-same-line"> </span>
            </div>
            <div class="navigation">
              <a href="#" class="main">
                Αρχική
              </a>{" "}
              <a href="#" class="my-contracts">
                Τα Συμβόλαιά μου
              </a>{" "}
              <a href="#" class="my-payments">
                Οι Πληρωμές μου
              </a>{" "}
              <a href="#my-documents" class="my-documents">
                Τα Έγγραφά μου
              </a>{" "}
              <a href="#" class="support">
                Υποστήριξη
              </a>{" "}
              <a href="#online-payment" class="online-payment">
                Online πληρωμή
              </a>
              <span class="force-same-line"> </span>
            </div>
          </header>
        </div>
      </div>
    </>
  );
};

export default PreviewHeader;
