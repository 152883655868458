import React from "react";
import { Navigate, Route, Routes } from "react-router-dom/dist";
import InfoPages from "../Components/infoPages/InfoPages";
import SignInB2C from "../Components/Login/SignInB2C";
import Register from "../Components/Register/Register";
import UnRegisterDowload from "../Components/Register/UnRegisterDowload";
import ExpiredPage from "../Components/SpecialPages/ExpiredPage";
import UnRegisterCustomerSurvey from "../Components/Register/UnRegisterCustomerSurvey";

const UnauthorizedRoutes = (props) => {
  const unauthorizedCustomerSurvey =
    process.env.REACT_APP_UNAUTHORIZED_SURVEY_ENABLED === "1";
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            props.isAuthorized ? (
              <Navigate to="/home" />
            ) : (
              <Navigate to="/azureb2cendpoint" />
            )
          }
        />
        <Route
          path="/home"
          element={
            props.isAuthorized ? (
              <Navigate to="/home" />
            ) : (
              <Navigate to="/azureb2cendpoint" />
            )
          }
        />
        <Route
          path="register/*"
          element={<Register partnerRegistration={false} />}
        />
        <Route
          path="partner/register/*"
          element={<Register partnerRegistration={true} />}
        />
        <Route path="documents/contract/*" element={<UnRegisterDowload />} />
        {unauthorizedCustomerSurvey && (
          <Route
            path="customer/survey/:surveyId"
            element={<UnRegisterCustomerSurvey />}
          />
        )}
        <Route
          path="/azureb2cendpoint"
          element={
            props.authService && props.authorities ? (
              <SignInB2C
                authService={props.authService}
                authorities={props.authorities}
              />
            ) : null
          }
        />
        <Route path="/SpecialPages/expiredPage" element={<ExpiredPage />} />
        <Route path="/Info/*" element={<InfoPages />} />
        {/* <Route exact path="/azureb2cendpoint">
          {isAuthorized ? <Navigate to="/home" /> : <Navigate to="/azureb2cendpoint" />}
        </Route>
       */}
      </Routes>
    </>
  );
};

export default UnauthorizedRoutes;
