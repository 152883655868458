import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Link } from "@nn-design-system/react-component-library";
import img from "../../assets/index";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";

const OnlinePaymentFailPayment = () => {
  const { t } = useTranslation();
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const { paymentCode, transactionMessage, isChangeOnlinePaymentMehod } =
    onlinePaymentStore;
  const [isDifferentTab] = useState(isChangeOnlinePaymentMehod);
  const changeCard = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (changeCard !== isDifferentTab) {
      onlinePaymentStore.setIsChangeOnlinePaymentMehod(changeCard);
      onlinePaymentStore.setTransactionStatus(null);
      onlinePaymentStore.setContract(null);
      navigate(`/online-payment`);
    }

    return () => {
      sessionStorage.removeItem("IsChangeCard");
      onlinePaymentStore.setTransactionStatus(null);
    };
  }, []);

  const clearStatus = () => {
    onlinePaymentStore.setTransactionStatus(null);
    navigate("/online-payment");
  };
  return (
    <>
      {paymentCode && transactionMessage ? (
        <div>
          <h2 className="success">
            <img src={img.failure} alt="failure" />
            {t("online.payment.unsuccessful")}
          </h2>
          <br />
          <div className="form-element payment">
            <label>
              <b>{transactionMessage}</b>
            </label>
          </div>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.transaction-number")}
              </span>
              <span> {paymentCode} </span>
            </div>
          </div>
          <br />
          <div className="form-element payment">
            <span>
              {t("online.payment.for-information-text")}{" "}
              <Link
                href="https://www.nnhellas.gr/faq"
                testId="link-test-id"
                text={t("online.payment.press-here")}
                variant="PrimaryStandAlone"
              />
            </span>
          </div>
          <Button mt={"20px"} variant="PrimaryMedium" onClick={clearStatus}>
            {t("global.return")}
          </Button>
        </div>
      ) : (
        <div>
          <h2 className="success">
            <img src={img.failure} alt="failure" />
            {t("online.payment.unsuccessful")}
          </h2>
          <br />
          <div className="form-element payment">
            <label>
              <b>{transactionMessage}</b>
            </label>
          </div>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.transaction-number")}
              </span>
              <span> {paymentCode} </span>
            </div>
          </div>
          <br />
          <div className="form-element payment">
            <span>
              {t("online.payment.for-information-text")}{" "}
              <Link
                href="https://www.nnhellas.gr/faq"
                testId="link-test-id"
                text={t("online.payment.press-here")}
                variant="PrimaryStandAlone"
              />
            </span>
          </div>
          <Button mt={"20px"} variant="PrimaryMedium" onClick={clearStatus}>
            {t("global.return")}
          </Button>
        </div>
      )}
    </>
  );
};

export default observer(OnlinePaymentFailPayment);
