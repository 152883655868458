import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@nn-design-system/react-component-library";
import { stringToAmount } from "../../CommonComp/Functions";

export const GroupPensionInsuredMembersTableHRNN = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "InvestmentType",
      headerName: t("contract.investment-type"),
      flex: 3,
      headerAlign: "left",
      align: "left",
      sortable: false,
      cellClassName: "groupPensionDetailRowCell",
      renderCell: (params) => {
        return <span style={{ whiteSpace: "wrap" }}>{params.value}</span>;
      },
    },
    {
      field: "EmployerContribution",
      headerName: t("contract.employer-account-department"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionDetailRowCell",
      renderCell: (params) => {
        const isLastRow = params.value.toString().indexOf("lastRow") > -1;
        const value = params.value.toString().replace("lastRow", "");
        return (
          <span style={{ fontWeight: isLastRow ? "bold" : "" }}>
            {stringToAmount(value)}
          </span>
        );
      },
    },
    {
      field: "VoluntaryContribution",
      headerName: t("contract.voluntary-account-department"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionDetailRowCell",
      renderCell: (params) => {
        const isLastRow = params.value.toString().indexOf("lastRow") > -1;
        const value = params.value.toString().replace("lastRow", "");
        return (
          <span style={{ fontWeight: isLastRow ? "bold" : "" }}>
            {stringToAmount(value)}
          </span>
        );
      },
    },
  ];

  const rows = data.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
    />
  );
};
