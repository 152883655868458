import React from "react";

const ScoreRadioButton = ({
  label,
  value,
  formItemId,
  isSelected,
  onSelect,
}) => {
  function handleChange(event) {
    onSelect(event.target.value);
  }

  return (
    <div className="score">
      <button
        className="score-button"
        type="button"
        role="radio"
        aria-checked={isSelected}
        data-state={isSelected ? "checked" : "unchecked"}
        value={value}
        id="r2"
        tabIndex="0"
        onClick={handleChange}
      >
        <label htmlFor="r2" className="score-label">
          {label}
        </label>
      </button>
      <input
        type="radio"
        aria-hidden="true"
        tabIndex="-1"
        value={value}
        className="score-input"
        onChange={() => {}}
        checked={isSelected}
        name={`${formItemId}-${value}`}
      />
    </div>
  );
};

export { ScoreRadioButton };
