import React from "react";
import img from "../../assets/index";

const PreviewFooter = () => {
  return (
    <div class="footer">
      <div class="nn-container">
        <div class="top">
          <div class="left">
            <div class="title">
              <span>Στο myNN θα βρείτε</span>
            </div>
            <div class="links">
              <a href="#">Τα Συμβόλαιά μου</a>
              <a href="#">Τα Έγγραφά μου</a>
              <a href="#">Οι Πληρωμές μου</a>
              <a href="#" class="button">
                Online πληρωμή
              </a>
            </div>
          </div>
          <div class="center">
            <div class="title">
              <span>Επικοινωνία</span>
            </div>
            <div class="links">
              <a href="#">Μηνύματα</a>
              <a href="#">Υποστήριξη</a>
              <a href="#">Ακολουθήστε μας</a>
              <div class="social">
                <a href="#" title="NN Hellas Facebook page">
                  <img src={img.fb_icon} alt="Facebook" />
                </a>
                <a href="#" title="NN Hellas LinkedIn page">
                  <img src={img.ln_icon} alt="LinkedIn" />
                </a>
                <a href="#" title="NN Hellas Youtube channel">
                  <img src={img.youtube_icon} alt="Youtube" />
                </a>
              </div>
            </div>
          </div>
          <div class="right">
            <img src={img.yrs_icon} alt="40 years NN Hellas | You matter" />
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="nn-container">
          <a href="#">Πολιτική cookies</a>
          <span class="divide"></span>
          <a href="#">Προσωπικά δεδομένα</a>
          <span class="divide"></span>
          <a href="#">Οροι χρήσης</a>
        </div>
      </div>
      <div class="bottom">
        <div class="nn-container">
          <span>
            ΝΝ Ελληνική Μονοπρόσωπη Ανώνυμη Ασφαλιστική Εταιρία Ζωής, Λ. Συγγρού 198, 17671 Καλλιθέα, +30 210 950 6000
            <br /> ΑΡ.Γ.Ε.ΜΗ.: 121801401000 Α.Φ.Μ.: 094509480, Δ.Ο.Υ. ΦΑΕ ΠΕΙΡΑΙΑ
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreviewFooter;
