import React from "react";
import { VictoryChart, VictoryLine, VictoryAxis, createContainer } from "victory";
import { sharedAxisStyles, LIGHT_GREY } from "../../HR/Utils/Constants";
import { ConvertDecimalToStringWithCurrencyMaxFractionDigits } from "../../CommonComp/Functions";

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi");

export const PortfolioLineChart = ({ finalData, zoomDomain, domainY, domainX }) => {
  return (
    <VictoryChart
      scale={{ x: "time" }}
      height={300}
      width={700}
      containerComponent={
        <VictoryZoomVoronoiContainer
          zoomDimension="x"
          zoomDomain={zoomDomain}
          labels={({ datum }) =>
            `${datum.x.toLocaleDateString("el-GR")}\n ${ConvertDecimalToStringWithCurrencyMaxFractionDigits(datum.y)} `
          }
        />
      }
    >
      <VictoryLine data={finalData} style={{ data: { stroke: "#EA650D" } }} />
      <VictoryAxis
        domain={[domainX, domainY]}
        style={{
          ...sharedAxisStyles,
          grid: {
            fill: LIGHT_GREY,
            stroke: LIGHT_GREY,
            pointerEvents: "",
            strokeWidth: 0.5,
          },
        }}
        dependentAxis
        tickFormat={(x) => `€${x}`}
      />
      <VictoryAxis
        crossAxis
        style={{
          ...sharedAxisStyles,
        }}
      />
    </VictoryChart>
  );
};
