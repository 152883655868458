import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToString,
  ConvertDecimalToStringWithCurrency,
} from "../CommonComp/Functions";
import ContractSelectedOseka from "./ContractSelectedOseka";
import ContractSelectedDocumentTable from "./ContractSelectedDocumentTable";

const PensionAccountContentForVisibleFields = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="selected-contract-account-pension">
        <div className="account-value">
          {props.contractDetails.VisibleFields.includes("AccountValue") &&
          props.contractDetails.AccountValue ? (
            <div>
              <p>{t("contract.value-freedom")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.AccountValue,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
          {props.contractDetails.VisibleFields.includes("SurrenderValue") ? (
            <div>
              <p>{t("contract.takeover-value")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.SurrenderValue,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
              <span className="takeover-value">
                {t("contract.surrender-penalties-msg")}
              </span>
            </div>
          ) : null}

          {props.contractDetails.VisibleFields.includes("ExpiredAmount") ? (
            props.contract.IsNonExpiredAmount ? null : (
              <div>
                {props.contract.IsGuaranteeExpiredAmount ? (
                  <>
                    <p>{t("contract.guaranteed-maturity-capital")}</p>
                    <span>
                      {ConvertDecimalToStringWithCurrency(
                        props.contractDetails.ExpiredAmount,
                        props.contractDetails.CurrencyCode,
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <p>{t("contract.closing-capital")}</p>
                    <span>
                      {ConvertDecimalToStringWithCurrency(
                        props.contractDetails.ExpiredAmount,
                        props.contractDetails.CurrencyCode,
                      )}
                    </span>
                  </>
                )}
              </div>
            )
          ) : null}
        </div>
        <div className="account-mortgage">
          {props.contractDetails.VisibleFields.includes("LoanRight") ? (
            <div>
              <p>{t("contract.loan-right")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.LoanRight,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
          {props.contractDetails.VisibleFields.includes("LoanAmount") &&
          props.contractDetails.LoanAmount ? (
            <div>
              <p>{t("contract.loan-amount")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.LoanAmount,
                  props.contractDetails.CurrencyCode,
                )}{" "}
              </span>
            </div>
          ) : null}
          {props.contractDetails.VisibleFields.includes("LoanToRepay") &&
          props.contractDetails.LoanToRepay ? (
            <div>
              <p>{t("contract.loan-repaid")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.LoanToRepay,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
          {props.contractDetails.VisibleFields.includes("Dividend") ? (
            <div>
              <p>{t("contract.dividend")}</p>
              <span>
                {ConvertDecimalToStringWithCurrency(
                  props.contractDetails.Dividend,
                  props.contractDetails.CurrencyCode,
                )}
              </span>
            </div>
          ) : null}
        </div>

        {props.contractDetails.VisibleFields.includes(
          "OsekaInvestmentLinks",
        ) ? (
          <ContractSelectedOseka OsekaInvestmentLinks={props.OsekaLinks} />
        ) : null}

        {props.contractDetails.VisibleFields.includes("Transactions") ? (
          <ContractSelectedDocumentTable
            Transactions={props.contractDetails.Transactions}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default observer(PensionAccountContentForVisibleFields);
