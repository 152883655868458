import React, { Fragment, useContext, useEffect } from "react";
import { Route, useLocation, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import IdentificationFooter from "../Components/Footer/IdentificationFooter";
import UserStore from "../Stores/UserStore";
import { useState } from "react";
import Preview from "../Components/Preview/Preview";
import PreviewFooter from "../Components/Preview/PreviewFooter";
import PreviewHeader from "../Components/Preview/PreviewHeader";
import UnauthorizedRoutes from "../Routes/UnauthorizedRoutes";

const IdentificationContent = (props) => {
  const userStore = useContext(UserStore);

  const { isAuthorized } = userStore;
  const [show, setShow] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  let path = useLocation();

  useEffect(() => {
    if (
      path.pathname.includes("/unregister/download") ||
      path.pathname.includes("/register") ||
      path.pathname.includes("/SpecialPages") ||
      path.pathname.includes("/Info")
    ) {
      setShow(true);
    } else {
      setShow(false);
    }

    if (path.pathname.includes("/preview/")) {
      setShowPreview(true);
    } else {
      setShowPreview(false);
    }
  }, [path.pathname]);

  return (
    <Fragment>
      <div id="inner-root" className="registration guest">
        <UnauthorizedRoutes
          isAuthorized={isAuthorized}
          authorities={props.authorities}
          authService={props.authService}
        />
      </div>
      <div id="inner-root">
        {showPreview ? (
          <>
            <PreviewHeader />
          </>
        ) : null}
        <Routes>
          <Route path="/preview/*" element={<Preview />} />
        </Routes>
      </div>
      {showPreview ? (
        <footer>
          <PreviewFooter />
        </footer>
      ) : null}

      {show ? (
        <footer>
          <IdentificationFooter />
        </footer>
      ) : null}
    </Fragment>
  );
};
export default observer(IdentificationContent);
