import React from "react";
import { Table } from "@nn-design-system/react-component-library";
import {
  ConvertDecimalToString,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import { useTranslation } from "react-i18next";
import HoverPopover from "../CommonComp/HoverPopover";
import ElementDownload from "../Document/ElementDownload";

export const PaymentsTableNN = ({ data, documentDetails }) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "a",
      headerName: t("online.payment.date-debtor"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? setDateFormatForDateTime(params.value) : "-"}
          </span>
        );
      },
    },
    {
      field: "b",
      headerName: t("payments.table-date-payment"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params.value ? setDateFormatForDateTime(params.value) : "-"}
          </span>
        );
      },
    },
    {
      field: "c",
      headerName: t("payments.payment-code"),
      headerAlign: "left",
      align: "left",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="code-payment-container">
            {!params.value.paymentCodeRF ? (
              <>
                <span>
                  <b>A.</b> {params.value.PaymentCodeA}
                </span>
                {params.value.PaymentCodeB ? (
                  <span>
                    <b>B.</b> {params.value.PaymentCodeB}
                  </span>
                ) : (
                  "-"
                )}
              </>
            ) : (
              <>{params.value.PaymentCodeA ?? "-"}</>
            )}
          </div>
        );
      },
    },
    {
      field: "d",
      headerName: t("online.payment.payment-amount"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span>{ConvertDecimalToString(params.value)}</span>
      ),
    },
    {
      field: "e",
      headerName: t("payments.dosage-amount"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <span>
            {ConvertDecimalToString(params.value.InstallmentGrossPremium)}
          </span>
          {documentDetails ? (
            <span className="installment-download-icon">
              <HoverPopover
                popoverElement={
                  <ElementDownload
                    DocumentLink={params.value.documentLink}
                    DocumentType={params.value.documentType}
                    IconVariant="Small"
                  />
                }
              >
                <div className="installment-download-popover-text">
                  {t("payments.notice-payment")}
                </div>
              </HoverPopover>
            </span>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "f",
      headerName: t("global.condition"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
    },
  ];

  const rows = data.map((e, i) => {
    return {
      id: i,
      a: e.InstallmentFrom,
      b: e.PaymentDate,
      c: {
        PaymentCodeA: e.PaymentCodeA,
        PaymentCodeB: e.PaymentCodeB,
        paymentCodeRF: e.paymentCodeRF,
      },
      d: e.PaidAmount,
      e: {
        InstallmentGrossPremium: e.InstallmentGrossPremium,
        documentLink: documentDetails?.paymentDocLink,
        documentType: documentDetails?.paymentDocType,
      },
      f: e.StatusDescription,
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hideFooter
      density="comfortable"
    />
  );
};
