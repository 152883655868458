import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const anchorTagToLink = (htmlString) => {
  const html = parse(htmlString, {
    transform(reactNode) {
      if (reactNode.type === "a" && reactNode.props.href.startsWith("/")) {
        return (
          <Link to={reactNode.props.href} target={reactNode.props.target}>
            {reactNode.props.children}
          </Link>
        );
      } else {
        return reactNode;
      }
    },
  });
  return html;
};

const HTMLParser = {
  anchorTagToLink,
};

export default HTMLParser;
