import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ChatStore from "../../Stores/ChatStore";
import img from "../../assets/index";
import {
  Button,
  Circle,
  Icon,
  IconButton,
  Link,
  Select,
  Textarea,
  TextInput,
} from "@nn-design-system/react-component-library";
import "../../Css/CommonCss/Chat.css";
import CookieStore from "../../Stores/CookieStore";
const Chat = (props) => {
  const { t } = useTranslation();
  const chatStore = useContext(ChatStore);
  const cookieStore = useContext(CookieStore);
  const { callMeBackResponse } = chatStore;
  const [classNameInactive, setclassNameInactive] = useState("active");
  const [classNameactive, setclassNameactive] = useState("");
  const [classNameactiveA, setclassNameactiveA] = useState("active");
  const [classNameactiveB, setclassNameactiveB] = useState("");
  const [classNameactiveC, setclassNameactiveC] = useState("");
  const [classNameactiveD, setclassNameactiveD] = useState("");
  const [classNameactiveE, setclassNameactiveE] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [myText, setMyText] = useState();
  const [myContactNumber, setMyContactNumber] = useState("+30 6");
  const [myTextCount, setMyTextCount] = useState(0);
  const [myEmail, setMyEmail] = useState(props.accountData.Email);
  const [waitResponse, setWaitResponse] = useState(false);
  const [showIndividualTab, setShowIndividualTab] = useState(false);
  const [showGroupTab, setShowGroupTab] = useState(false);
  const [classNameTabIndividual, setClassNameTabIndividual] =
    useState("tab-button");
  const [classNameTabGroup, setClassNameTabGroup] = useState("tab-button");
  const [dropTopic, setDropTopic] = useState(false);
  const [topicList, setTopicList] = useState([
    {
      text: "- Επιλέξτε -",
      value: false,
    },
  ]);
  const [contactSettingsList, setContactSettingsList] = useState([]);
  const [emailError, setEmailError] = useState("");

  const valueInput = (event) => {
    if (
      event.target.name === "callmeback-comments" ||
      event.target.name === "emailme-comments"
    ) {
      setMyText(event.target.value);
      setMyTextCount(event.target.value.length);
    }
    if (event.target.name === "callmeback-mobile-phone") {
      const regExp = /^[0-9_ +]*$/g;
      if (
        event.target.value.length >= 5 &&
        event.target.value.length <= 14 &&
        regExp.test(event.target.value.slice(5))
      ) {
        setMyContactNumber(event.target.value);
      }
    }
    if (event.target.name === "emailme-email-address") {
      setMyEmail(event.target.value);
      setEmailError("");
    }
  };

  const sendEmail = () => {
    if (showGroupTab) {
      if (myEmail && myText && dropTopic) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailRegex.test(myEmail)) {
          let emailTo = contactSettingsList.find(
            (setting) => setting.Title === dropTopic,
          ).Email;
          return (window.location.href = `mailto:${emailTo}?subject=${dropTopic}&body=${myText}`);
        } else {
          setEmailError(t("commons.invalid-email"));
        }
      }
    }
  };

  const submitRequest = () => {
    if (showGroupTab) {
      if (dropTopic && dropTopic !== false && myText && myContactNumber) {
        setWaitResponse(true);
        const requestValues = {
          MyText: myText,
          ContactNumber: myContactNumber.replaceAll(/\s/g, ""),
          Topic: dropTopic,
        };
        chatStore.callMeBack(requestValues);
      }
    } else if (showIndividualTab) {
      if (myText && myContactNumber) {
        setWaitResponse(true);
        const requestValues = {
          MyText: myText,
          ContactNumber: myContactNumber.replaceAll(/\s/g, ""),
        };
        chatStore.callMeBack(requestValues);
      }
    }
  };

  const resetDefault = () => {
    setMyText("");
    setMyTextCount(0);
    setDropTopic(false);
    setMyContactNumber("+30 6");
    setMyEmail(props.accountData.Email);
    setShowIframe(false);
  };

  useEffect(() => {
    if (callMeBackResponse) {
      if (callMeBackResponse.Status === 0 || callMeBackResponse.Status === 1) {
        changeclassNameF();
        setWaitResponse(false);
      }
    }
  }, [callMeBackResponse]);

  useEffect(() => {
    if (cookieStore.dropDownContactValues) {
      setContactSettingsList(cookieStore.dropDownContactValues);
    }
  }, [cookieStore.dropDownContactValues]);

  useEffect(() => {
    getDropDownTopicList();
  }, [contactSettingsList]);

  const newRequest = () => {
    changeclassNameE();
    setMyText("");
    setMyTextCount(0);
    setDropTopic(false);
    setMyContactNumber("+30 6");
    setMyEmail(props.accountData.Email);
  };

  const changeclassNameA = () => {
    setclassNameInactive("");
    setclassNameactive("active");
  };

  const changeclassNameB = () => {
    setclassNameInactive("active");
    setclassNameactive("");
    setclassNameactiveB("");
    setclassNameactiveA("active");
    setclassNameactiveC("");
    setclassNameactiveD("");
    setclassNameactiveE("");
    setShowGroupTab(false);
    setShowIndividualTab(false);
    setClassNameTabIndividual("tab-button");
    setClassNameTabGroup("tab-button");
    resetDefault();
  };

  const changeclassNameC = () => {
    setclassNameactiveB("active");
    setclassNameactiveA("");
    setShowIframe(true);
  };

  const changeclassNameD = () => {
    if (!waitResponse) {
      setclassNameactiveB("");
      setclassNameactiveA("active");
      setclassNameactiveC("");
      setclassNameactiveD("");
      setclassNameactiveE("");
      setShowIframe(false);
      resetDefault();
    }
  };

  const changeclassNameE = () => {
    setclassNameactiveC("active");
    setclassNameactiveA("");
    setclassNameactiveD("");
    setShowIframe(false);
  };
  const changeclassNameF = () => {
    setclassNameactiveD("active");
    setclassNameactiveC("");
    setShowIframe(false);
  };

  const changeclassNameG = () => {
    setclassNameactiveE("active");
    setclassNameactiveD("");
    setclassNameactiveA("");
    setclassNameactiveC("");
    setShowIframe(false);
  };

  const onIndividualTabClick = () => {
    setShowGroupTab(false);
    setClassNameTabGroup("tab-button");
    setShowIndividualTab(!showIndividualTab);

    if (classNameTabIndividual === "tab-button") {
      setClassNameTabIndividual("tab-button active");
    } else {
      setClassNameTabIndividual("tab-button");
    }
  };

  const onGroupTabClick = () => {
    setShowIndividualTab(false);
    setClassNameTabIndividual("tab-button");
    setShowGroupTab(!showGroupTab);

    if (classNameTabGroup === "tab-button") {
      setClassNameTabGroup("tab-button active");
    } else {
      setClassNameTabGroup("tab-button");
    }
  };

  const getDropDownTopicList = () => {
    if (
      contactSettingsList &&
      contactSettingsList.length > 0 &&
      topicList.length < contactSettingsList.length
    ) {
      contactSettingsList.map((setting) => {
        return setTopicList((topicList) => [
          ...topicList,
          {
            text: setting.Title,
            value: setting.Title,
          },
        ]);
      });
    }
  };

  const dropDownHandlerTopic = (e) => {
    setDropTopic(e.target.value);
  };

  return (
    <div id="chat-container">
      <div className={`chat-content ${classNameactive}`}>
        <div className={`page first-page ${classNameactiveA}`}>
          <div className="greeting">
            <span className="title">{t("commons.hello")}!</span>
            <span className="body">{t("commons.how-may-help")};</span>
          </div>

          <div className="tab-container">
            <span className="classspan">{t("commons.want-to-contact")}:</span>
            <div>
              <a
                className={classNameTabIndividual}
                onClick={() => onIndividualTabClick()}
                target="_blank"
              >
                {t("commons.individual-contract")}
              </a>
            </div>
            {showIndividualTab ? (
              <div className="actions">
                <ul>
                  <li>
                    <a href="tel:+30-210-950-6000">
                      <div>
                        <Icon
                          name="PhoneReceiver"
                          variant="Large"
                          color="#ea650d"
                        />
                      </div>
                      <span className="actions classspan">
                        +30 210 950 6000
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="begin-chat-button" onClick={changeclassNameC}>
                      <div>
                        <img src={img.chat_icon} alt="call_icon" />
                      </div>
                      <span className="actions classspan">
                        {t("commons.want-chat-online")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      id="callback-button-individual"
                      onClick={changeclassNameE}
                    >
                      <div>
                        <Icon
                          name="PhoneReceiverArrow"
                          variant="Large"
                          color="#ea650d"
                        />
                      </div>

                      <span className="actions classspan">
                        {t("commons.want-to-call-me")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto: info@nnhellas.gr">
                      <div>
                        <Icon name="Envelope" variant="Large" color="#ea650d" />
                      </div>

                      <span className="actions classspan">
                        {t("commons.want-send-email")}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
            <div>
              <a
                className={classNameTabGroup}
                onClick={() => onGroupTabClick()}
                target="_blank"
              >
                {t("commons.group.contract")}
              </a>
            </div>
            {showGroupTab ? (
              <div className="actions">
                <ul>
                  <li>
                    <a href="tel:+30-210-950-6000">
                      <div>
                        <Icon
                          name="PhoneReceiver"
                          variant="Large"
                          color="#ea650d"
                        />
                      </div>
                      <span className="actions classspan">
                        +30 210 950 6000
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="callback-button-group" onClick={changeclassNameE}>
                      <div>
                        <Icon
                          name="PhoneReceiverArrow"
                          variant="Large"
                          color="#ea650d"
                        />
                      </div>

                      <span className="actions classspan">
                        {t("commons.want-to-call-me")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a id="emailme-button-group" onClick={changeclassNameG}>
                      <div>
                        <Icon name="Envelope" variant="Large" color="#ea650d" />
                      </div>

                      <span className="actions classspan">
                        {t("commons.want-send-email")}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className={`page second-page ${classNameactiveB}`}>
          <div className="chat-nav">
            <div>
              <div onClick={changeclassNameD} className="left-caret-mock">
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <img src={img.chat_icon} alt="chat_icon" />
              <span>Chat Online</span>
            </div>
          </div>
          <div className="chat-iframe-container">
            {showIframe && (
              <iframe
                height="458"
                width="368"
                src="https://nnhellas.custhelp.com/app/chat/chat_launch"
                title="NN Chat Online"
              />
            )}
          </div>
        </div>

        <div className={`page third-page ${classNameactiveC}`}>
          <div className="chat-nav">
            <div>
              <div onClick={changeclassNameD} className="left-caret-mock">
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="header-container">
              <Icon
                name="PhoneReceiverArrow"
                variant="Medium"
                color="#ea650d"
              />
              <span id="callmeback">{t("commons.want-to-call-me")}</span>
            </div>
          </div>
          <div
            className="callmeback-container"
            style={{ overflowY: "auto", maxHeight: "450px" }}
          >
            <div>
              <span>{t("commons.fill-form-msg")}</span>
            </div>
            <div>
              <span>
                {props.accountData ? (
                  <b>
                    {props.accountData.FirstName && props.accountData.Surname
                      ? props.accountData.FirstName +
                        " " +
                        props.accountData.Surname
                      : "-"}
                  </b>
                ) : null}
              </span>
            </div>
            <div className={`prefixed`}>
              <TextInput
                labelText={t("commons.cell-phone-number")}
                name="callmeback-mobile-phone"
                onChange={valueInput}
                value={myContactNumber}
              />
            </div>
            {showGroupTab ? (
              <div>
                <Select
                  placeholderText={t("global.choose")}
                  labelText={t("commons.theme")}
                  name="select"
                  onChange={dropDownHandlerTopic}
                  options={topicList}
                  testId="select"
                  value={dropTopic}
                />
              </div>
            ) : (
              <></>
            )}

            <div>
              <span id="commentsNum">
                <span className="current">{myTextCount}</span>/150
              </span>
              <Textarea
                name="callmeback-comments"
                labelText={t("commons.comments")}
                numberOfRows={3}
                onChange={valueInput}
                value={myText}
                placeholderText={`${t("commons.please-type")}...`}
              />
            </div>
            <div>
              <Button
                testId="button"
                variant="PrimaryMedium"
                onClick={submitRequest}
              >
                {t("commons.submission")}
              </Button>
            </div>
            <div>
              <span className="help light-gray">
                {t("commons.contact-us-phone")} +30 210 950 6000.
              </span>
            </div>
          </div>
        </div>

        <div className={`page fourth-page ${classNameactiveD}`}>
          <div className="chat-nav">
            <div>
              <div className="left-caret-mock" onClick={changeclassNameD}>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <Icon
                name="PhoneReceiverArrow"
                variant="Medium"
                color="#ea650d"
              />
              <span>Call me back</span>
            </div>
          </div>

          <div className="callmeback-container">
            {callMeBackResponse ? (
              <>
                <div>
                  <h2 className="success">
                    <div className="check-icon">
                      <Circle variant="SmallAttentional">
                        <Icon variant="Large" name="Checkmark" color="white" />
                      </Circle>
                    </div>
                    {callMeBackResponse.Title}
                  </h2>
                </div>

                <div>
                  <span>{callMeBackResponse.Message}</span>
                </div>

                <div>
                  <span className="light">{t("commons.request-details")}</span>
                  <br></br>
                  {props.accountData ? (
                    <span>
                      {props.accountData.FirstName && props.accountData.Surname
                        ? props.accountData.FirstName +
                          " " +
                          props.accountData.Surname
                        : "-"}
                    </span>
                  ) : null}
                  <br></br>
                  <span>{myContactNumber}</span>
                  <br></br>
                  {showGroupTab ? (
                    <>
                      <span className="gray comments">
                        {t("commons.theme")}: {dropTopic}
                      </span>
                      <br></br>
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="gray comments">
                    {t("commons.comments")}: {myText}
                  </span>
                </div>
              </>
            ) : null}

            <div className="chevron-link-orange">
              <Link
                testId="link-test-id"
                text="Δημιουργία νέου αιτήματος Call me back"
                variant="Primary"
                onClick={() => newRequest()}
                iconProps={{ name: "ChevronRight" }}
              ></Link>
            </div>
            <div>
              <span className="help light-gray">
                {t("commons.information-contact-us-phone")} +30 210 950 6000.
              </span>
            </div>
          </div>
        </div>

        <div className={`page second-page ${classNameactiveE}`}>
          <div className="chat-nav">
            <div>
              <div onClick={changeclassNameD} className="left-caret-mock">
                <div></div>
                <div></div>
              </div>
            </div>
            <div className="header-container">
              <Icon name="Envelope" variant="Medium" color="#ea650d" />
              <span id="emailme">{t("commons.want-send-email")}</span>
            </div>
          </div>
          <div
            className="callmeback-container"
            style={{ overflowY: "auto", maxHeight: "450px" }}
          >
            <div>
              <span>{t * "commons.fill-out-form-msg"}</span>
            </div>
            <div>
              <span>
                {props.accountData ? (
                  <b>
                    {props.accountData.FirstName && props.accountData.Surname
                      ? props.accountData.FirstName +
                        " " +
                        props.accountData.Surname
                      : "-"}
                  </b>
                ) : null}
              </span>
            </div>
            <div className={`prefixed`}>
              <TextInput
                placeholderText={`${t("commons.please-type")}...`}
                labelText="Email"
                name="emailme-email-address"
                onChange={valueInput}
                value={myEmail}
              />
            </div>
            <span className="error-input">{emailError}</span>
            {showGroupTab ? (
              <div>
                <Select
                  placeholderText={t("global.choose")}
                  labelText={t("commons.theme")}
                  name="select"
                  onChange={dropDownHandlerTopic}
                  options={topicList}
                  testId="select"
                  value={dropTopic}
                />
              </div>
            ) : (
              <></>
            )}

            <div>
              <span id="commentsNum">
                <span className="current">{myTextCount}</span>/150
              </span>
              <Textarea
                name="emailme-comments"
                labelText={t("commons.comments")}
                numberOfRows={3}
                onChange={valueInput}
                value={myText}
                placeholderText={`${t("commons.please-type")}...`}
              />
            </div>
            <div>
              <Button
                testId="button"
                variant="PrimaryMedium"
                onClick={sendEmail}
              >
                {t("commons.submission")}
              </Button>
            </div>
            <div>
              <span className="help light-gray">
                {t("commons.contact-us-phone")} +30 210 950 6000.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`chat-toggler chat-toggler-inactive ${classNameInactive}`}
      >
        <Button variant="PrimaryMedium" onClick={changeclassNameA}>
          {t("commons.contact")}
        </Button>
      </div>
      <div className={`chat-toggler chat-toggler-active ${classNameactive}`}>
        <div>
          <IconButton
            color="white"
            onClick={changeclassNameB}
            iconName="Cross"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Chat);
