import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ContractStore from "../../../Stores/ContractStore";
import {
  RadioButton,
  RadioButtonGroup,
  SearchInput,
} from "@nn-design-system/react-component-library";
import "../../../Css/contracts-table.css";
import "../../../Css/search-contracts.css";
import { ContractTableHRNN } from "./ContractTableHRNN";
import HRStore from "../../../Stores/HRStore";

const ContractsTableHR = () => {
  const { t } = useTranslation();
  const { contractHeader } = useContext(ContractStore);
  const { hrContractsList } = useContext(HRStore);
  const [radioType, setRadioType] = useState("IF");
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState([]);
  const [groupPensionContracts, setGroupPensionContracts] = useState([]);

  const checkSearchValue = useCallback(
    (el) =>
      ["CompanyName", "PolicyNumber"].some((prop) =>
        el[prop].toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue],
  );

  const checkRadioValue = useCallback(
    (el) =>
      radioType === "IF"
        ? el.ContractStatus === radioType || el.ContractStatus === "A"
        : true,
    [radioType],
  );

  let parseHrContractsList = useCallback((hrList) => {
    let groupInsuranceProgram = {};
    let groupPensionList = [];
    hrList.forEach((obj) => {
      if (obj.IsGroupPensionPolicy) {
        groupPensionList.push(obj);
      } else {
        const insuranceProgram = obj.InsuranceProgram;
        if (!groupInsuranceProgram[insuranceProgram])
          groupInsuranceProgram[insuranceProgram] = [];
        groupInsuranceProgram[insuranceProgram].push(obj);
      }
    });
    setList([...Object.values(groupInsuranceProgram)]);
    setGroupPensionContracts([...groupPensionList]);
  }, []);

  useEffect(() => {
    parseHrContractsList(hrContractsList);
  }, [hrContractsList]);

  useEffect(() => {
    if (searchValue) {
      let filteredContractList = hrContractsList.filter(
        (el) => checkSearchValue(el) && checkRadioValue(el),
      );
      parseHrContractsList(filteredContractList);
    } else {
      let filteredContractList = hrContractsList.filter((el) =>
        checkRadioValue(el),
      );
      parseHrContractsList(filteredContractList);
    }
  }, [searchValue, radioType]);

  const searchHandler = (e) => {
    if (e === undefined) {
      setSearchValue("");
    } else if (e.target.value === "" || e.target.value === " ") {
      e.target.value = e.target.value.replace(/\s/g, "");
      setSearchValue("");
    } else {
      setSearchValue(e.target.value);
    }
  };

  return (
    <>
      {hrContractsList && contractHeader ? (
        <>
          <Fragment>
            <div className="nn-container contracts">
              <div htmlFor="search-contracts" className="search-contracts-hr">
                <SearchInput
                  labelText=""
                  onChange={searchHandler}
                  placeholderText={t("contract.contract-search")}
                  value={searchValue}
                  onEmptySearchInput={searchHandler}
                />
              </div>
              <div className="filter-contracts">
                <RadioButtonGroup
                  labelText=""
                  value={radioType}
                  onChange={(ev) => setRadioType(ev.target.value)}
                  variant={"Default"}
                >
                  <div className={"radiogroup"}>
                    <RadioButton
                      labelText={t("contract.active-contracts")}
                      value={"IF"}
                    />
                    <RadioButton
                      ml={"25px"}
                      labelText={t("contract.all-contracts")}
                      value={"all"}
                    />
                  </div>
                </RadioButtonGroup>
              </div>
            </div>
          </Fragment>
          <div className="nn-container table-container contracts">
            {list?.map((element, i) => {
              return (
                <Fragment key={i}>
                  <h2 className="table-label">
                    {element[0]?.InsuranceProgram}
                  </h2>
                  <ContractTableHRNN data={element} />
                </Fragment>
              );
            })}
            {groupPensionContracts?.length > 0 && (
              <Fragment>
                <h2 className="table-label">{t("hr.pension-plans")}</h2>
                <ContractTableHRNN data={groupPensionContracts} />
              </Fragment>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default observer(ContractsTableHR);
