import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class PaymentStore {
  paymentList = [];
  paymentHasContent = false;

  constructor() {
    makeObservable(this, {
      paymentList: observable,
      paymentHasContent: observable,

      loadPayments: action,
      updatePayment: action,
    });
  }

  loadPayments = () => {
    this.paymentHasContent = false;
    const payments = agent.payments.list();
    payments
      .then((result) => {
        const paymentObj = { ...result };

        if (paymentObj.Contracts) {
          this.paymentList = [...paymentObj.Contracts];
          this.paymentList.forEach(({ Payments }) => {
            Payments.forEach((e) => {
              if (e.PaymentCodeA === "-" || e.PaymentCodeA === null) {
                e.PaymentCodeA = e.PaymentCodeB;
                e.PaymentCodeB = null;
                e.paymentCodeRF = true;
              }
            });
          });
        }
        this.paymentHasContent = true;
      })
      .catch((error) => {
        this.paymentHasContent = true;
      });
  };

  updatePayment = (ContractNumber, InvoiceNumber, PaidAmount, PaymentDate, StatusCode, StatusDescription) => {
    let paymentList = this.paymentList;
    paymentList.map((contractElem, i) => {
      if (contractElem.Number === ContractNumber) {
        contractElem.Payments.map((paymentElem) => {
          if (paymentElem.InvoiceNumber === InvoiceNumber) {
            if (paymentElem.OutstandingAmount !== 0) {
              paymentElem.StatusCode = StatusCode;
              paymentElem.PaymentDate = PaymentDate;
              paymentElem.PaidAmount = paymentElem.PaidAmount + PaidAmount;
              paymentElem.StatusDescription = StatusDescription;
            }
          }
        });
      }
    });
    this.paymentList = paymentList;
  };
}
export default createContext(new PaymentStore());
