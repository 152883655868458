import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import UrlStore from "../../Stores/UrlStore";
import SettingsStore from "../../Stores/SettingsStore";
import {
  IconButton,
  SpinnerLoader,
  PrefabModal,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { contentTypeToExtension } from "../HR/Utils/Constants";

/**
 * A button to download documents.
 *
 * @component
 * @param {Object} props
 * @param {string} props.DocumentLink - The url the document should be downloaded from
 * @param {string} props.DocumentType - The file name
 * @param {string} props.MimeType - The document's MimeType
 * @param {function} [props.onClick] - Additional click handler.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <ElementDownload DocumentLink={value.DocumentLink} DocumentType={value.DocumentType} />
 */

const ElementDownload = (props) => {
  const { t } = useTranslation();

  const { onClick } = props;
  const { baseURL } = useContext(UrlStore);
  const settingStore = useContext(SettingsStore);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const downloadDocument = (myUrl, fileName, mimeType, method, postData) => {
    onClick && onClick();
    setIsLoading(true);
    function token() {
      const token = sessionStorage.getItem("msal.idtoken");
      return token;
    }
    function impersonated() {
      const client = sessionStorage.getItem("client");
      return client;
    }

    Axios({
      url: myUrl, //your url
      method: method ? method : "GET",
      responseType: "blob", // important
      headers: {
        authorization: `Bearer ${token()}`,
        ImpersonatedUser: impersonated(),
        ...(method === "POST" && { "Content-Type": "application/json" }),
      },
      ...(postData && {
        data: postData,
      }),
    })
      .then((response) => {
        const fileExtension = mimeType
          ? `.${mimeType}`
          : contentTypeToExtension[
              response.headers["content-type"].toString()
            ] || ".pdf";
        if (response.status === 204) {
          setIsLoading(false);
          setShowModal(true);
          return setErrorMessage(t("claim.submission.no-results"));
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}${fileExtension}`);
        document.body.appendChild(link);
        link.click();

        setIsLoading(false);
      })
      .catch((error) => {
        let reader = new FileReader();
        reader.onload = function () {
          let errorMessage = JSON.parse(reader.result);
          setErrorMessage(errorMessage.Message);
        };
        reader.readAsText(error.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (errorMessage) {
      settingStore.setShowErrorMessage(true, errorMessage);
      ///setErrorMessage();
    }
  }, [errorMessage, settingStore]);

  return (
    <div className="dowload-icon-container">
      {isLoading ? (
        <SpinnerLoader variant="Medium" />
      ) : errorMessage ? (
        <PrefabModal
          isOpen={showModal}
          hasCloseButton
          headingText=""
          onClose={() => setShowModal(false)}
          testId="modal"
        >
          <Paragraph>{errorMessage}</Paragraph>
        </PrefabModal>
      ) : (
        <IconButton
          iconName="ArrowDownLine"
          testId="document-download-button"
          variant="PlainMedium"
          onClick={() =>
            downloadDocument(
              baseURL + props.DocumentLink,
              props.fileName,
              props.MimeType,
              props.method,
              props.postData,
            )
          }
        />
      )}
      {props.children}
    </div>
  );
};

export default ElementDownload;
