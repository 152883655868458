import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Carousel } from "@nn-design-system/react-component-library";
import ContractStore from "../../Stores/ContractStore";
import CarouselItem from "./CarouselItem";

const Promos = () => {
  const contractStore = useContext(ContractStore);
  const { promosList } = contractStore;

  const [promoSlide, setPromoSlide] = useState(null);

  useEffect(() => {
    setPromoSlide(promosList);
  }, [promosList]);

  return (
    <>
      {promoSlide ? (
        <>
          {promoSlide.length ? (
            <Carousel
              hasIndicators
              hasFadeEffect={false}
              hasNavigationButtons
              numberOfItemsPerView={1}
              shouldLoop
            >
              {promoSlide.map((promoElement, i) => {
                return <CarouselItem key={i} promo={promoElement} />;
              })}
            </Carousel>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default observer(Promos);
