import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  stringToAmount,
  ConvertDecimalToStringWithOutCurrency,
} from "../../CommonComp/Functions";

const GroupPensionAccountBalanceTable = ({ data }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const maxTypeStringLength = 110;

  const formatTypeValue = useCallback(
    (value) => {
      if (value.length >= maxTypeStringLength) {
        return value.substring(0, maxTypeStringLength - 3) + "...";
      }
      return value;
    },
    [maxTypeStringLength],
  );

  const columns = [
    {
      field: "InvestmentType",
      headerName: t("contract.investment-type"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        return (
          <span
            title={
              params.value.length >= maxTypeStringLength ? params.value : ""
            }
            style={{ whiteSpace: "wrap" }}
          >
            {formatTypeValue(params.value)}
          </span>
        );
      },
    },
    {
      field: "TotalShares",
      headerName: t("contract.total-shares"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return ConvertDecimalToStringWithOutCurrency(props.value, "el-GR", 4);
      },
    },
    {
      field: "SharePrice",
      headerName: t("contract.shares-price"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        if (!props.value) {
          return "-";
        }
        return stringToAmount(props.value, 4);
      },
    },
    {
      field: "TotalAmount",
      headerName: t("contract.total-account-value"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1.05,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "EmployerContribution",
      headerName: t("hr.employers-department"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "VoluntaryContribution",
      headerName: t("hr.volunteer-department"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "OwnerContribution",
      headerName: t("hr.counter-party-account"),
      headerClassName: "textAlignLeft",
      headerAlign: "left",
      align: "left",
      flex: 1.3,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
  ];
  const rows = data.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      hasPagination
      hasFooterPagination
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      density="comfortable"
      itemsPerPageProps={{
        itemsPerPage: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
        ],
        name: "itemsPerPage",
        onChange: (test) => {
          setItemsPerPage(test);
        },
        value: itemsPerPage,
      }}
      page={pageNumber}
      pageNavigationProps={{
        name: "pageNavigation",
        onChange: (value) => {
          setPageNumber(value);
        },
        value: pageNumber,
      }}
      pageSize={itemsPerPage}
    ></Table>
  );
};
export default observer(GroupPensionAccountBalanceTable);
