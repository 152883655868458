import React from "react";
import { ToggleButtonGroup } from "@nn-design-system/react-component-library";
import { useTranslations } from "../../Context/i18n";

const LanguageSwitch = (props) => {
  const { language, setLanguage } = useTranslations();
  return (
    <ToggleButtonGroup
      name="name"
      onChange={(_, value) => setLanguage(value)}
      toggleButtons={[
        {
          text: "EN",
          value: "en",
        },
        {
          text: "GR",
          value: "gr",
        },
      ]}
      value={language}
      variant="Horizontal"
      id="change-language"
      {...props}
    />
  );
};

export { LanguageSwitch };
