import React, { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import UserStore from "../../Stores/UserStore";
import MainContent from "../../Views/MainContent";
import IdentificationContent from "../../Views/IdentificationContent";
import ScrollToTop from "../CommonComp/ScrollToTop";
import Logout from "../Login/Logout";

const AppContent = (props) => {
  const userStore = useContext(UserStore);
  const { isAuthorized } = userStore;
  const [giveAccess, setGiveAccess] = useState(false);

  window.localStorage.clear();

  useEffect(() => {
    if (sessionStorage.hasOwnProperty("msal.idtoken")) {
      userStore.setUserAuthorization(true);
    } else {
      userStore.setUserAuthorization(false);
    }
  }, []);

  useEffect(() => {
    setGiveAccess(isAuthorized);
  }, [isAuthorized]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path="/errorPages/logout"
          element={<Logout authService={props.authService} />}
        />
      </Routes>
      {giveAccess ? (
        props.authService && props.authorities ? (
          <>
            <MainContent
              authService={props.authService}
              authorities={props.authorities}
              giveAccess={giveAccess}
            />
          </>
        ) : null
      ) : props.authService && props.authorities ? (
        <IdentificationContent
          authService={props.authService}
          authorities={props.authorities}
        />
      ) : null}
    </Router>
  );
};

export default observer(AppContent);
