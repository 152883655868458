import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  PrefabModal,
  Icon,
  Heading,
  Label,
  Paragraph,
} from "@nn-design-system/react-component-library";
import {
  setDateFormatForDateTime,
  decimalToPercentage,
} from "../CommonComp/Functions";
const PersonDataCard = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (props.element.ClientId) {
      setShow(true);
    }
  }, [props]);

  const findAddressesOrPhones = (array, typeOfPlace, addressOrPhone) => {
    let filterArray = array.find((a) => a.AddressType === typeOfPlace);

    if (filterArray) {
      if (addressOrPhone === "findAddress") {
        if (filterArray.StreetAddress) {
          return (
            <span>
              {filterArray.StreetAddress} {filterArray.StreetNumber},{" "}
              {filterArray.City}
            </span>
          );
        } else {
          return "-";
        }
      }
      if (addressOrPhone === "findPhones") {
        if (filterArray.PhoneNumber) {
          return <span>{filterArray.PhoneNumber}</span>;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  return (
    <Fragment>
      <div className="chevron-link">
        <a data-toggle="modal" onClick={() => setModalShow(true)}>
          {show ? (
            <span>
              {props.element.Surname} {props.element.FirstName}
            </span>
          ) : (
            <span>{props.element.BeneficiaryDescription}</span>
          )}
          <div>
            <Icon name="ChevronRight" color="#EE7F00" variant="Small" />
          </div>
        </a>
      </div>
      <PrefabModal
        isOpen={modalShow}
        hasCloseButton
        width="620px"
        height="auto"
        children={
          <div className="insured-content">
            <div className="modal-header-nn">
              {show ? (
                <Heading
                  variant="Medium"
                  hasMargin={false}
                  mb="15px"
                  className="modal-title"
                >
                  {props.element.Surname} {props.element.FirstName}
                </Heading>
              ) : (
                <Heading hasMargin={false} mb="15px" lassName="modal-title">
                  {props.element.BeneficiaryDescription}
                </Heading>
              )}

              {props.insType === "beneficiaries" ? (
                <div>
                  <Heading
                    variant={"Small"}
                    mt="-10px"
                    className="modal-description"
                  >
                    {props.element.BeneficiaryLevel} {"("}
                    {decimalToPercentage(
                      props.element.PercentageContract.toString(),
                    )}
                    {")"}
                  </Heading>
                </div>
              ) : null}
            </div>

            <div className="modal-body">
              {props.insType === "beneficiaries" ? (
                <div className="beneficiaries-information">
                  <Label text={t("contract.insured-relationship")} />
                  <Paragraph hasMargin={false}>
                    {props.element.ContractInsuredRelationship
                      ? props.element.ContractInsuredRelationship
                      : "-"}
                  </Paragraph>
                </div>
              ) : null}

              <div className="insured-information-1">
                <div>
                  <Label text={t("account.father-name")} />
                  <Paragraph hasMargin={false}>
                    {props.element.MiddleName ? props.element.MiddleName : "-"}{" "}
                  </Paragraph>
                </div>

                <div>
                  <Label text={t("account.birth-date")} />
                  <Paragraph hasMargin={false}>
                    {props.element.DateOfBirth
                      ? setDateFormatForDateTime(props.element.DateOfBirth)
                      : "-"}
                  </Paragraph>
                </div>
                <div>
                  <Label text={t("account.identity-type")} />
                  <Paragraph hasMargin={false}>
                    {props.element.IdType ? props.element.IdType : "-"}
                  </Paragraph>
                </div>
                <div>
                  <Label text={t("account.residence-address")} />
                  <Paragraph hasMargin={false}>
                    {findAddressesOrPhones(
                      props.element.Addresses,
                      "Home",
                      "findAddress",
                    )}
                  </Paragraph>
                </div>
                <div>
                  <Label text={t("account.department-labor")} />
                  <Paragraph hasMargin={false}>
                    {findAddressesOrPhones(
                      props.element.Addresses,
                      "Work",
                      "findAddress",
                    )}
                  </Paragraph>
                </div>
                {props.insType !== "beneficiaries" ? (
                  <>
                    {" "}
                    <div>
                      <Label text={t("account.home-telephone-number")} />
                      <Paragraph hasMargin={false}>
                        {findAddressesOrPhones(
                          props.element.Addresses,
                          "Home",
                          "findPhones",
                        )}
                      </Paragraph>
                    </div>
                    <div>
                      <Label text={t("account.work-phone")} />
                      <Paragraph hasMargin={false}>
                        {findAddressesOrPhones(
                          props.element.Addresses,
                          "Home",
                          "findPhones",
                        )}
                      </Paragraph>
                    </div>
                  </>
                ) : null}

                <div>
                  <Label
                    text={
                      props.insType === "beneficiaries"
                        ? `${t("account.mobile-phone")} 1`
                        : `${t("account.mobile-phone")}`
                    }
                  />
                  <Paragraph hasMargin={false}>
                    {props.element.PhoneCellular1
                      ? props.element.PhoneCellular1
                      : "-"}
                  </Paragraph>
                </div>
              </div>
              <div className="insured-information-2">
                <div>
                  <Label text={t("account.gender")} />
                  <Paragraph hasMargin={false}>
                    {props.element.Gender ? props.element.Gender : "-"}
                  </Paragraph>
                </div>
                <div>
                  <Label text={t("account.vat-number")} />
                  <Paragraph hasMargin={false}>
                    {props.element.VatNumber ? props.element.VatNumber : "-"}
                  </Paragraph>
                </div>
                <div>
                  <Label text={t("account.id-number")} />
                  <Paragraph hasMargin={false}>
                    {props.element.IdNumber ? props.element.IdNumber : "-"}
                  </Paragraph>
                </div>
                <div>
                  {props.insType === "beneficiaries" ? (
                    <>
                      <div>
                        <Label text={t("account.home-telephone-number")} />
                        <Paragraph hasMargin={false}>
                          {findAddressesOrPhones(
                            props.element.Addresses,
                            "Home",
                            "findPhones",
                          )}
                        </Paragraph>
                      </div>
                      <div>
                        <Label text={t("account.work-phone")} />
                        <Paragraph hasMargin={false}>
                          {findAddressesOrPhones(
                            props.element.Addresses,
                            "Work",
                            "findPhones",
                          )}
                        </Paragraph>
                      </div>
                      <Label text={`${t("account.mobile-phone")} 2`} />
                      <Paragraph hasMargin={false}>
                        {props.element.PhoneCellular2
                          ? props.element.PhoneCellular2
                          : "-"}
                      </Paragraph>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        }
        onClose={() => setModalShow(false)}
        headingText={" "}
      />
    </Fragment>
  );
};

export default PersonDataCard;
