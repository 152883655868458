import { Icon } from "@nn-design-system/react-component-library";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const AlertComponent = (props) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(true);
  const navigate = useNavigate();

  const changeClass = () => {
    setShowAlert(false);
  };

  const clickToDetails = (detail) => {
    navigate(`/myInbox/details`, { state: { ...detail } });
  };

  return (
    <>
      {showAlert ? (
        <div className="dashboard-alert ">
          <div className="text">
            <span>{props.alertMessageElement.Title}</span>
            <a onClick={() => clickToDetails(props.alertMessageElement)}>
              {t("articleCarousel-see-more")}
            </a>
          </div>
          <div className="remove" onClick={changeClass}>
            <span></span>
            <span></span>
            <div className="cursor-pointer">
              <Icon
                mr={"10px"}
                mt={"8px"}
                name="Cross"
                variant="Medium"
                color="#EA650D"
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AlertComponent;
