import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Paragraph,
  Icon,
  Checkbox,
  Button,
  AttentionBlock,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import KycStore from "../../Stores/KycStore";
import { observer } from "mobx-react-lite";
import { downloadPdfFromBinaryProp } from "../CommonComp/Functions";
const KycStep1 = () => {
  const { t } = useTranslation();
  const {
    setCurrentPage,
    sendKycTermAcceptance,
    kycTermAcceptanceResponse,
    kycAccessResponse,
    resetTermAcceptance,
    kycAcceptanceIsLoading,
  } = useContext(KycStore);
  const [checkBoxState, setCheckBoxState] = useState();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [errorNotification, setErrorNotification] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      sessionStorage.setItem("kycState", kycAccessResponse.State);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      resetTermAcceptance(sessionStorage.getItem("kycState"));
    };
  }, []);

  useEffect(() => {
    setisLoading(false);
    if (kycTermAcceptanceResponse) {
      if (kycTermAcceptanceResponse?.Success) {
        localStorage.setItem(
          "kycStateSuccess",
          kycTermAcceptanceResponse?.Success,
        );
        setCurrentPage(2);
        navigate("/oauth/kyc/step2");
      } else {
        setCurrentPage(2);
        navigate("/oauth/kyc/failure", {
          state: false,
        });
      }
    }
  }, [kycTermAcceptanceResponse, kycAcceptanceIsLoading]);

  return (
    <>
      <article className="info-kyc kyc-customer-status center-items">
        <Paragraph hasMargin={false}>{t("kyc.data-archive")}</Paragraph>
        <div className="kyc-status">
          <a
            className="active"
            onClick={() =>
              downloadPdfFromBinaryProp(kycAccessResponse.PdfBinary, "KYC-info")
            }
          >
            <Icon name="ArrowDownLine" variant={"Large"} color="#EE7F00" />
          </a>
        </div>
      </article>
      <Heading>{t("kyc.terms-of-use")}</Heading>
      <article className="checkbox-container">
        <Checkbox
          onChange={(ev) => {
            setCheckBoxState(ev.target.checked ? "Checked" : undefined);
            if (errorNotification) setErrorNotification(false);
          }}
          dataState={checkBoxState}
          labelText={t("kyc.request-update-info-msg")}
        />
      </article>
      {errorNotification && !checkBoxState && (
        <AttentionBlock mt="12px" testId="test-id" variant="Error">
          <Paragraph>{t("kyc.acceptance-terms-of-use")}</Paragraph>
        </AttentionBlock>
      )}
      <Button
        ml="48px"
        mt="32px"
        variant={"SecondaryMedium"}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {t("global.return")}
      </Button>
      <Button
        ml="48px"
        mt="32px"
        variant={"PrimaryMedium"}
        isLoading={isLoading}
        onClick={() => {
          if (checkBoxState) {
            sendKycTermAcceptance(true);
            setisLoading(true);
          } else {
            setErrorNotification(true);
          }
        }}
      >
        {t("global.continue")}
      </Button>
      <PrefabModal
        isOpen={showModal}
        hasCloseButton
        headingText={t("kyc.app-update-details")}
        primaryActionProps={{
          onClick: () => setShowModal(false),
          text: t("global.no"),
        }}
        secondaryActionProps={{
          onClick: () => navigate("/home"),
          text: t("global.yes"),
        }}
        children={
          <Paragraph hasMargin={false}>{t("kyc.sure-stop-precess")}</Paragraph>
        }
        onClose={() => {
          setShowModal(false);
        }}
        width="auto"
        height="auto"
      />
    </>
  );
};

export default observer(KycStep1);
