import React, { Fragment, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import { observer } from "mobx-react-lite";
import UrlStore from "../../Stores/UrlStore";
import dompurify from "dompurify";
import "../../Css/Articles/single-article-main.css";

const ArticleDetails = (props) => {
  const { baseURL } = useContext(UrlStore);
  const [article, setArticle] = useState(null);
  let location = useLocation();
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    if (!props.data) {
      setArticle(location.state);
    }
  }, [location, article]);

  useEffect(() => {
    if (props.data) {
      setArticle(props.data);
    }
  }, []);

  return (
    <Fragment>
      {article ? (
        <div className="nn-container">
          <article id="single-article">
            <BreadCrumb Title={article.Title} />
            <h1 className="articles-label">{article.Title}</h1>

            {article.ArticleType === "Text" ? (
              article.MainImage ? (
                <img src={baseURL + article.MainImage.Path} alt={article.MainImage.AlternativeText} />
              ) : null
            ) : null}

            {article.ArticleType === "ContainsImages" ? (
              article.Images.length ? (
                <img src={baseURL + article.Images[0].Path} alt={article.Images[0].AlternativeText} />
              ) : null
            ) : null}

            {article.ArticleType === "ContainsVideos" ? (
              <iframe
                width="956"
                height="538"
                src={`https://www.youtube.com/embed/${article.VideoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
              ></iframe>
            ) : null}
            <div className="body">
              <div dangerouslySetInnerHTML={{ __html: sanitizer(article.Text, { ADD_ATTR: ["target"] }) }} />
            </div>

            {article.PdfFiles ? (
              <>
                {article.PdfFiles.map((pdf, k) => {
                  return (
                    <div key={k}>
                      <a href={baseURL + pdf.Path} target="_blank" rel="noopener noreferrer">
                        PDF File {k}
                      </a>
                    </div>
                  );
                })}
              </>
            ) : null}
          </article>
        </div>
      ) : null}
    </Fragment>
  );
};

export default observer(ArticleDetails);
