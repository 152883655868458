import React, { useState, useEffect } from "react";
import { Select } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
const Pagination = ({
  messagePerPage,
  totalMessages,
  paginate,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [firstMessageIndex, setFirstMessageIndex] = useState(1);
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalMessages / messagePerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (currentPage === 1) {
      setFirstMessageIndex(1);
    } else {
      setFirstMessageIndex(messagePerPage * (currentPage - 1) + 1);
    }
  }, [currentPage]);

  useEffect(() => {}, [pageNumbers]);

  return (
    <div className="pagination-container">
      <div className="pages-text">
        <span>{t("commons.page")}</span>
      </div>
      <div className="pagination-nav">
        {pageNumbers ? (
          <Select
            labelText=""
            options={pageNumbers.map((page) => ({
              value: page,
              text: page,
            }))}
            value={currentPage}
            onChange={(e) => paginate(e.target.value)}
          />
        ) : null}
      </div>
      <div className="pages-text">
        <span>
          {t("global.from")} {pageNumbers.length}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
