import { action, observable, makeObservable, toJS } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class NavBarStore {
  navBarItems = [];
  headerThemes = [];
  headerThemeByUrl = {};
  showSideBar = true;
  navBarItemsMobile = [];
  headerItemMobile = null;
  navBarItemsHR = [];

  constructor() {
    makeObservable(this, {
      navBarItems: observable,
      headerThemes: observable,
      headerThemeByUrl: observable,
      showSideBar: observable,
      navBarItemsMobile: observable,
      headerItemMobile: observable,
      navBarItemsHR: observable,

      loadNavbarItems: action,
      loadHeaders: action,
      loadHeaderByUrl: action,
      setShowSideBar: action,
    });
  }

  loadNavbarItems = () => {
    const menuItems = agent.navBar.list();
    menuItems.then((result) => {
      if (!result) {
        return;
      }
      this.navBarItems = [...result.MenuItems];
      let navbarMobileItems = [...result.MenuItemsMobile];
      let mobileArray = [];
      navbarMobileItems.map((navMobileElement, i) => {
        if (navMobileElement.Location === "header") {
          this.headerItemMobile = { ...navMobileElement };
        } else {
          mobileArray.push({ ...navMobileElement });
        }
      });
      this.navBarItemsMobile = [...mobileArray];
      this.navBarItemsHR = [...result.HrMenuItems];
    });
  };

  loadHeaders = () => {
    const header = agent.headerTheme.listTheme();
    header.then((result) => {
      if (this.headerThemes.length < 2) {
        this.headerThemes.push(result);
      }
    });
  };

  loadHeaderByUrl = (val) => {
    const header = agent.headerTheme.getbyUrl(val);
    header.then((result) => {
      this.headerThemeByUrl = { ...result };
    });
  };

  setShowSideBar = (val) => {
    this.showSideBar = val;
  };
}
export default createContext(new NavBarStore());
