import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import SettingsStore from "../../Stores/SettingsStore";
import dompurify from "dompurify";

const IdentificationFooter = () => {
  const { footer } = useContext(SettingsStore);
  const sanitizer = dompurify.sanitize;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizer(footer, { ADD_ATTR: ["target"] }),
      }}
    />
  );
};

export default observer(IdentificationFooter);
