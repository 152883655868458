export const accountRole = {
  hr: "Διαχειριστής Ομαδικού",
  individual: "Ασφαλισμένος",
};

export const allStatusOptionSelect = [
  {
    text: "Όλες",
    value: "all",
  },
];
export const allCompanyNameOptionSelect = [
  {
    text: "Όλες",
    value: "all",
  },
];

export const minDateErrorMsg = (minDate) => {
  return `Πρέπει να έχετε συμπληρωμένες υποχρεωτικά τις ημερομηνίες και να μην είναι πριν τις 
  ${new Intl.DateTimeFormat("el-GR").format(
    new Date(minDate),
  )} (έναρξη ασφαλιστικού έτους).`;
};

export const dateFormatError =
  "Εισάγετε τις ημερομηνίες με την σωστή μορφή (πχ. 10/12/23).";

export const contentTypeToExtension = {
  "application/pdf": ".pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
};
export const currencySymbols = {
  USD: "$",
  EUR: "€",
  JPY: "¥",
  GBP: "£",
  CNY: "¥",
  TRY: "₺",
};

export const bankLinks = [
  {
    bankLink: "https://www.winbank.gr/sites/idiwtes/el/Pages/default.aspx",
    bankName: "Τράπεζα Πειραιώς",
  },
  {
    bankLink: "https://secure.alpha.gr/Login/myalphaweb/el",
    bankName: "Alpha Bank",
  },
  { bankLink: "https://ibank.nbg.gr/", bankName: "Εθνική Τράπεζα" },
  { bankLink: "https://ebanking.eurobank.gr/#/login", bankName: "Eurobank" },
];

export const yesterday = () => {
  let today = new Date();
  let yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday.toLocaleDateString("el-GR");
};

export const LIGHT_GREY = "hsl(355, 20%, 90%)";

export const sharedAxisStyles = {
  axis: {
    // stroke: "transparent",
  },
  tickLabels: {
    // fill: "LIGHT_GREY",
    fontSize: 12,
  },
  axisLabel: {
    fill: LIGHT_GREY,
    padding: 36,
    fontSize: 15,
    fontStyle: "italic",
  },
};

export const colorsList = [
  "#75A0CE",
  "#2E5783",
  "#6DAB65",
  "#524E91",
  "#FFC37E",
  "#947965",
  "#008080",
  "#191970",
  "#ff7f0e",
  "#87CEEB",
  "#32CD32",
  "#FFD700",
  "#FF6F61",
  "#E6E6FA",
  "#98FB98",
  "#FFA07A",
  "#800080",
  "#40E0D0",
  "#DAA520",
  "#008080",
];
export const productCodesOnlinePaymentForShowLinks = [
  "MC1",
  "MG3",
  "UR2",
  "HG1",
  "HB1",
  "FS2",
  "TT9",
];

export const MCPPIProducts = [
  "Smart Plan",
  "Genius",
  "Genius Plus Campaign",
  "Smart Premium",
  "Smart Move",
];

export const activeGroupContractStatus = ["A", "IF"];
