import React, { useEffect, useState } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import PortFolioPerFundDetailPanel from "./PortFolioPerFundDetailPanel";
import { ConvertDecimalToStringWithCurrencyMaxFractionDigits } from "../CommonComp/Functions";
import { PortfolioPerFundDetailPanelRows } from "./PortfolioPerFundDetailPanelRows";

export const PortfolioPerFundTable = ({ element, originalData, totalSum }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (element) {
      setData([...element, {}]);
    }
  }, [element]);

  const columns = [
    {
      field: "FundName",
      headerName: t("portfolio.fund"),
      flex: 2.2,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (row) => {
        if (row.id < data.length - 1 && row.row.FundName)
          return (
            <span style={{ whiteSpace: "normal" }}>{row.row.FundName}</span>
          );
        return t("global.total");
      },
    },
    {
      field: "a",
      headerName: t("portfolio.contract-number"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1,
      sortable: false,
    },
    {
      field: "UnitSpecialPrice",
      headerName: t("portfolio.net-price"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (rowData) => {
        if (rowData.id < data.length - 1 && rowData.row.FundName)
          return ConvertDecimalToStringWithCurrencyMaxFractionDigits(
            Number(rowData.row.UnitSpecialPrice),
            rowData.row.Currency,
          );
      },
    },
    {
      field: "NumberOfUnits",
      headerName: t("portfolio.share-number"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (rowData) => {
        return (
          rowData.id < data.length - 1 &&
          rowData.row.NumberOfUnits?.toLocaleString("el-GR", {
            maximumFractionDigits: 6,
          })
        );
      },
    },
    {
      field: "StatusDescription",
      headerName: t("portfolio.investment-account-value"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1.6,
      sortable: false,
      renderCell: (rowData) => {
        if (rowData.id < data.length - 1 && rowData.row.FundName)
          return ConvertDecimalToStringWithCurrencyMaxFractionDigits(
            rowData.row.TotalAmountInvestment,
            rowData.row.Currency,
          );
        return `${ConvertDecimalToStringWithCurrencyMaxFractionDigits(totalSum(element, rowData.row.Currency))}`;
      },
    },
    {
      field: "z",
      headerName: `${t("portfolio.investment-allocation")} (%)`,
      headerAlign: "left",
      align: "left",
      flex: 1.3,
      sortable: false,
      headerClassName: "textAlignLeft",
      valueFormatter: (props) => {
        if (props.id < data.length - 1 && props.value)
          return `${Number(props.value)?.toLocaleString("el-GR")} %`;
      },
    },
  ];
  const rows = data?.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });

  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      testId={"fund-portfolio-table"}
      getRowHeight={() => "auto"}
      getDetailPanelContent={(rowData) => {
        let filterDataByFundName = originalData?.filter(
          (el) => el.FundCode === rowData.row.FundCode,
        );
        if (rowData.id < data.length - 1)
          return (
            <div style={{ overflow: "hidden", paddingBottom: 70 }}>
              <PortfolioPerFundDetailPanelRows
                portfolio={filterDataByFundName}
              />
              <div style={{ marginTop: 24 }}>
                <PortFolioPerFundDetailPanel
                  fundName={rowData.row.FundName}
                  data={{ ...rowData.row }}
                />
              </div>
            </div>
          );
      }}
      getDetailPanelHeight={() => {
        return "auto";
      }}
      hideFooter
    ></Table>
  );
};
