import { Icon, Label, Paragraph } from "@nn-design-system/react-component-library";
import React, { Fragment } from "react";

export const CommonList = ({ element, data, hasIcon, onClick }) => {
  return (
    <div className={`${"list-container"} ${hasIcon && "list-container-icon"}`} onClick={() => onClick(element)}>
      <div>
        {hasIcon && (
          <div>
            <Icon name="ChevronRight" variant="Medium" color="#EA650D" />
          </div>
        )}
      </div>
      <div>
        {data.map((el, i) => {
          return (
            <Fragment key={i}>
              <Label hasMargin={false} text={el.name} />
              {el.element ? el.element(element) : <Paragraph hasMargin={false}>{element[el.value]}</Paragraph>}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
