import React from "react";
import { useTranslation } from "react-i18next";
import config from "@nn-design-system/config/web";

const TranslationsContext = React.createContext();

const locales = {
  gr: "el-GR",
  en: "en-GB",
};

const TranslationsProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setAppLanguage] = React.useState("gr");
  config.setLocale(locales[language]);

  function setLanguage(lang) {
    i18n.changeLanguage(lang);
    setAppLanguage(lang);
  }

  return (
    <TranslationsContext.Provider value={{ language, setLanguage }}>
      {children}
    </TranslationsContext.Provider>
  );
};

function useTranslations() {
  const context = React.useContext(TranslationsContext);

  if (context === undefined) {
    throw new Error(
      "useTranslations must be used within a TranslationsProvider",
    );
  }

  return context;
}

export { TranslationsProvider, useTranslations };
