import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import {
  DateRangePickerInput,
  Button,
  SearchInput,
  AttentionBlock,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import HRStore from "../../../Stores/HRStore";
import { ClaimsTable } from "./ClaimsTable";
import { usePaymentsHRFilter } from "../Payments/hooks/usePaymentsHRFilter";
import LoaderStore from "../../../Stores/LoaderStore";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
import { useWindowDimensions } from "../../../Hooks/useWindowDimensions";
const ClaimsHR = ({ contract }) => {
  const { t } = useTranslation();
  const {
    loadClaimsHRSelected,
    claimsHR,
    showClaimsStore,
    setShowHRClaimsStore,
    hrContractsList,
    setClaimsSelectedFilterValue,
    claimsSelectedFilterValue,
  } = useContext(HRStore);
  const { setMiniLoaderForData3 } = useContext(LoaderStore);
  const [list, setList] = useState([]);
  const [datePickerDates, setDatePickerDates] = useState([null, null]);
  const { width } = useWindowDimensions();

  const isSmallView = useMemo(() => {
    return width <= 767.98;
  }, [width]);

  let minDate = useMemo(
    () =>
      hrContractsList.find((c) => c.PolicyNumber === contract)
        ?.AnniversaryStartDate,
    [hrContractsList],
  );
  const {
    datePickerRange,
    onInputChange,
    searchInput,
    clearOnSearch,
    validValue,
  } = usePaymentsHRFilter(claimsSelectedFilterValue, minDate);
  let formatDate = useCallback((date) => date.toISOString().split("T")[0], []);

  useEffect(() => {
    let filteredList;
    if (searchInput) {
      filteredList = claimsHR.filter((claim) =>
        ["PayrollCode", "MainInsuredName"].some((prop) =>
          claim[prop].toLowerCase().includes(searchInput.toLowerCase()),
        ),
      );
    }
    setList(filteredList || claimsHR);
  }, [searchInput]);

  useEffect(() => {
    let today = new Date();
    let todayFormat = formatDate(today);
    let thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);
    let thirtyDaysAgoFormat = formatDate(thirtyDaysAgo);

    setDatePickerDates([thirtyDaysAgo, today]);

    if (!showClaimsStore) {
      setMiniLoaderForData3(true);
      loadClaimsHRSelected(contract, [thirtyDaysAgoFormat, todayFormat]);
    }
  }, [contract]);

  useEffect(() => {
    setList(claimsHR);
    setMiniLoaderForData3(false);
  }, [claimsHR]);

  const handleSubmit = () => {
    if (!validValue.isValidValue) return;
    setMiniLoaderForData3(true);
    setShowHRClaimsStore();
    let datesFormatted = datePickerRange
      ? datePickerRange.map((date) => formatDate(date))
      : datePickerDates.map((date) => formatDate(date));

    setClaimsSelectedFilterValue({ datePickerRange });
    return loadClaimsHRSelected(contract, datesFormatted);
  };

  return (
    <section>
      <div className="claims-form-container">
        <DateRangePickerInput
          mr={!isSmallView ? "80px" : ""}
          minDate={minDate}
          canSelectFutureDates={false}
          id="dateRangePicker"
          onChange={(ev) => onInputChange(ev, "datePickerRange")}
          startDateInputProps={{
            labelText: t("hr.claim-payment-period-form"),
            placeholderText: "dd/mm/yy",
          }}
          endDateInputProps={{
            labelText: t("hr.claim-payment-period-to"),
            placeholderText: "dd/mm/yy",
          }}
          testId="date-picker"
          value={datePickerRange || datePickerDates}
        />

        <Button
          mt="26px"
          testId="button"
          variant={validValue.isValidValue ? "PrimaryLarge" : "Secondary"}
          onClick={handleSubmit}
        >
          {t("hr.search")}
        </Button>
      </div>
      {!validValue.isValidValue && (
        <AttentionBlock mt={"10px"} headingText={validValue.errorMsg} />
      )}
      <div className="claims-searchInput-container">
        <SearchInput
          labelText=""
          onChange={onInputChange}
          placeholderText={`${t("hr.number")} / ${t("hr.employee-name")}`}
          mt="50px"
          name="searchInput"
          value={searchInput}
          inputProps={!isSmallView ? { width: "400px" } : {}}
          onEmptySearchInput={() => clearOnSearch("")}
        />
      </div>

      {showClaimsStore ? (
        <ClaimsTable element={list} contract={contract} />
      ) : (
        <SpinnerSmall3 />
      )}
    </section>
  );
};

export default observer(ClaimsHR);
