import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class OnlinePaymentStore {
  contract = null;
  payment = null;
  transactionResult = null;
  transactionStatus = null;
  paymentCode = null;
  transactionMessage = null;
  responsePayment = null;
  isChangeOnlinePaymentMehod = undefined;

  constructor() {
    makeObservable(this, {
      contract: observable,
      payment: observable,
      transactionResult: observable,
      transactionStatus: observable,
      paymentCode: observable,
      transactionMessage: observable,
      responsePayment: observable,
      isChangeOnlinePaymentMehod: observable,

      setContract: action,
      setPayment: action,
      sendTicketingWebService: action,
      clearTransaction: action,
      setTransactionStatus: action,
      updateTransactionStatus: action,
      setIsChangeOnlinePaymentMehod: action,
    });
  }

  setContract = (val) => {
    this.contract = val;
  };
  setPayment = (val) => {
    this.payment = val;
  };
  setIsChangeOnlinePaymentMehod = (val) => {
    this.isChangeOnlinePaymentMehod = val;
  };

  sendTicketingWebService = (bodyOfPayment) => {
    this.paymentCode = bodyOfPayment.PaymentCodeA;
    const ticketingWebServiceResponse =
      agent.onlinePayments.ticketingWebService(bodyOfPayment);
    ticketingWebServiceResponse
      .then((result) => {
        this.transactionResult = { ...result };
      })
      .catch((error) => {
        console.log(error);
      });
  };
  sendTicketingWebServiceForCreditCard = (bodyOfPayment) => {
    this.paymentCode = bodyOfPayment.PaymentCodeA;
    const ticketingWebServiceResponse =
      agent.onlinePayments.ticketingWebServiceCreditCard(bodyOfPayment);
    ticketingWebServiceResponse
      .then((result) => {
        this.transactionResult = { ...result };
      })
      .catch();
  };

  updateTransactionStatus = (val) => {
    const updatedStatusResponse =
      agent.onlinePayments.updateStatusTransaction(val);
    updatedStatusResponse.then((result) => {
      if (result) {
        this.paymentCode = result.SupportReferenceID;
        this.transactionStatus = result.PaymentTransactionStatus;
        this.transactionMessage = result.Message;
        this.responsePayment = { ...result };
      }
    });
  };

  clearTransaction = () => {
    this.transactionResult = null;
  };

  setTransactionStatus = (value) => {
    this.transactionStatus = value;
  };
}
export default createContext(new OnlinePaymentStore());
