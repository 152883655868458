import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InboxStore from "../../Stores/InboxStore";
import { observer } from "mobx-react-lite";
import Pagination from "../CommonComp/Pagination";
import InboxMessageElement from "./InboxMessageElement";
import "../../Css/inbox-table.css";
import "../../Css/inbox-filter.css";
import LoaderStore from "../../Stores/LoaderStore";
import AccountStore from "../../Stores/AccountStore";
import CookieStore from "../../Stores/CookieStore";
import {
  Button,
  RadioButton,
  RadioButtonGroup,
  SearchInput,
  Select,
} from "@nn-design-system/react-component-library";
import { normalizeGreek } from "../CommonComp/Functions";

const InboxTable = () => {
  const { t } = useTranslation();
  const inboxStore = useContext(InboxStore);
  const {
    messageList,
    messageCategories,
    nonArchivedMessages,
    inboxHasContent,
    isReadPost,
  } = inboxStore;
  const loaderStore = useContext(LoaderStore);
  const { accountHasContent } = useContext(AccountStore);
  const { cookieHasContent } = useContext(CookieStore);
  const [filteredList, setFilteredList] = useState();
  const [messagesArray, setMessagesArray] = useState();
  const [messageArrayWithPag, setMessageArrayWithPag] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(15);
  const [lastIndexPage, setLastIndexPage] = useState();

  const [filterState, setFilterState] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [radioType, setRadioType] = useState(false);

  const [dropdownOption, setDropdownOption] = useState("all");

  const [selectedMessages, setSelectedMessages] = useState([]);

  const getCategoryName = (inputCategoryId) => {
    if (messageCategories) {
      let category = messageCategories.find(
        (c) => c.NodeId === inputCategoryId,
      );
      return category.Title;
    }
  };

  useEffect(() => {
    let radioFilteredMessages = messageList.filter(
      (c) => c.IsArchived === filterState,
    );

    if (dropdownOption !== "all") {
      radioFilteredMessages = radioFilteredMessages.filter(
        (c) => getCategoryName(c.NodeParentId) === dropdownOption,
      );
    }

    if (searchValue) {
      radioFilteredMessages = radioFilteredMessages.filter((c) =>
        normalizeGreek(c.Title)
          .toLowerCase()
          .includes(normalizeGreek(searchValue).toLowerCase()),
      );
    }

    setFilteredList(radioFilteredMessages);
    setSelectedMessages([]);
    setCurrentPage(1);
  }, [
    filterState,
    messageList,
    searchValue,
    dropdownOption,
    nonArchivedMessages,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setMessagesArray(filteredList);
  }, [filteredList, messageCategories]);

  useEffect(() => {
    inboxStore.NonBulkMessages();
  }, [inboxStore]);

  //pagination

  useEffect(() => {
    if (messagesArray) {
      let indexOfLastMessages = currentPage * messagesPerPage;
      let indexOfFirstMessages = indexOfLastMessages - messagesPerPage;
      setMessageArrayWithPag(
        messagesArray.slice(indexOfFirstMessages, indexOfLastMessages),
      );
      setLastIndexPage(messagesArray.length / messagesPerPage);
    }
  }, [messagesArray, currentPage]);

  const paginate = (pageNumber) => {
    if (pageNumber < lastIndexPage + 1 && pageNumber > 0)
      setCurrentPage(pageNumber);
  };
  //pagination
  //filter
  const radioHandler = (value) => {
    setRadioType(JSON.parse(value));
    setFilterState(JSON.parse(value));
  };

  //filter
  const searchHandler = (e) => {
    if (e.target.value === "" || e.target.value === " ") {
      e.target.value = e.target.value.replace(/\s/g, "");
      setSearchValue("");
    } else {
      setSearchValue(e.target.value);
    }
  };

  useEffect(() => {
    if (inboxHasContent && accountHasContent && cookieHasContent) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
  }, [inboxHasContent, accountHasContent, cookieHasContent]);

  const handleCatgeoryChange = (e) => {
    setDropdownOption(e.target.value);
  };

  const handleSelectMessage = (data) => {
    let newSelectedMessages = [];
    if (data.value) {
      newSelectedMessages = [...selectedMessages, data.message];
    } else {
      newSelectedMessages = selectedMessages.filter(
        (m) =>
          !(
            m.DocumentId === data.message.DocumentId &&
            m.ClassName === data.message.ClassName
          ),
      );
    }
    setSelectedMessages(newSelectedMessages);
  };

  const archiveMessages = () => {
    if (selectedMessages.length > 0) {
      selectedMessages.forEach((m) =>
        isReadPost(m.DocumentId, "archive", m.ClassName),
      );
    }
    setSelectedMessages([]);
  };

  return (
    <>
      <div className="nn-container">
        <div className="inbox-top-wrapper">
          <div className="filter-wrapper">
            <div className="filter-messages">
              <RadioButtonGroup
                labelText=""
                mt={"-15px"}
                value={radioType}
                onChange={(e) => radioHandler(e.target.value)}
                variant={"Default"}
              >
                <div className={"radiogroup"}>
                  <RadioButton
                    variant="Small"
                    labelText={t("messages.active")}
                    value={false}
                  />
                  <RadioButton
                    variant="Small"
                    ml={"35px"}
                    labelText={t("messages.archived")}
                    value={true}
                  />
                </div>
              </RadioButtonGroup>
            </div>
          </div>

          <div className="search-category-container">
            <div className="search-container">
              <SearchInput
                labelText=""
                onChange={searchHandler}
                placeholderText={t("messages.search-msg")}
                value={searchValue}
                onEmptySearchInput={() => setSearchValue("")}
              />
            </div>

            {messageCategories ? (
              <div className="category-container">
                <Select
                  options={[
                    { value: "all", text: t("messages.all-categories") },
                    ...messageCategories.map((mc) => ({
                      value: mc.Title,
                      text: mc.Title,
                    })),
                  ]}
                  labelText=""
                  value={dropdownOption}
                  onChange={handleCatgeoryChange}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <table id="inbox-table">
          {messageArrayWithPag
            ? messageArrayWithPag.map((elem, ma) => {
                let activeClass = elem.IsRead ? "" : "highlighted";
                return (
                  <InboxMessageElement
                    key={ma}
                    elem={elem}
                    activeClass={activeClass}
                    messageArrayWithPag={messageArrayWithPag}
                    handleSelectMessage={handleSelectMessage}
                  />
                );
              })
            : null}
        </table>
        <div className="inbox-table-footer">
          <div className="archive-button">
            {!filterState ? (
              <Button
                variant={
                  selectedMessages.length > 0
                    ? "PrimaryMedium"
                    : "SecondaryMedium"
                }
                onClick={archiveMessages}
              >
                {t("messages.archiving")}
              </Button>
            ) : (
              <></>
            )}
          </div>
          {messagesArray ? (
            <Pagination
              messagePerPage={messagesPerPage}
              totalMessages={messagesArray.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default observer(InboxTable);
