import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DatepickerInput,
  TextInput,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import RegistrationRadioButton from "./RegistrationRadioButton";
import { htmlElement } from "../CommonComp/Functions";
import "../../Css/CommonCss/Filters.css";
import { textInputValidation } from "../CommonComp/Validations";
const RegisterFormStep2 = (props) => {
  const { t } = useTranslation();
  const [myUrl, setMyUrl] = useState("");
  const [validAFM, setValidAFM] = useState("");
  const [validContract, setValidContract] = useState("");
  const [validateDate, setValidateDate] = useState("");
  const [validBrandName, setValidBrandName] = useState("");
  const [buttonVariable, setButtonVariable] = useState("SecondaryMedium");
  const [dateInput, setDateInput] = useState(props.regValues.dateOfBirth);
  const contractNumberInputRef = useRef(null);
  const navigate = useNavigate();
  const yearMax = 2040;
  const yearMin = 1920;
  const onChange = (event) => {
    textInputValidation(
      event,
      "afm",
      props.regValues.vatNumber,
      props.regValues.setVatNumber,
      setValidAFM,
      9,
      "numeric",
      true,
    );
    textInputValidation(
      event,
      "contractno",
      props.regValues.contractNumber,
      props.regValues.setContractNumber,
      setValidContract,
      25,
      "alphanumeric",
      true,
    );
    textInputValidation(
      event,
      "company",
      props.regValues.brandName,
      props.regValues.setBrandName,
      setValidBrandName,
      50,
      "text",
      false,
    );
  };

  const setDateFormatForDateTime = (val) => {
    return (
      val.getDate() +
      "/" +
      parseInt(val.getMonth() + 1) +
      "/" +
      val.getFullYear()
    );
  };

  const checkFiledsAndNavigate = (url) => {
    if (!props.regValues.vatNumber)
      setValidAFM(htmlElement(t("global.fill-field")));
    if (props.regValues.vatNumber.length !== 9)
      setValidAFM(htmlElement(t("global.fill-field")));
    if (!props.regValues.contractNumber && !props.registrationApplicationNumber)
      setValidContract(htmlElement(t("global.fill-field")));
    if (!props.regValues.brandName)
      setValidBrandName(htmlElement(t("global.fill-field")));
    if (
      isDateNaN(props.regValues.dateOfBirth) ||
      props.regValues.dateOfBirth === undefined
    ) {
      setValidateDate(htmlElement(t("global.fill-field")));
    }

    navigate(url);
  };

  const isDateNaN = (value) => {
    if (value) {
      let isNan = false;
      value
        .toString()
        .split("/")
        .map((v) => {
          if (isNaN(v)) {
            isNan = true;
          } else {
            isNan = false;
          }
        });
      return isNan;
    }
  };

  const insertDate = (dateInput) => {
    if (dateInput == "Invalid Date" || !dateInput) {
      setValidateDate(htmlElement(t("register.enter-valid-date")));
      return;
    } else {
      const newDate = setDateFormatForDateTime(new Date(dateInput));
      var validYear = newDate.split("/");
      if (
        parseInt(validYear[2]) < yearMin ||
        parseInt(validYear[2]) > yearMax
      ) {
        setValidateDate(htmlElement(t("register.enter-valid-date")));
        return;
      }
      setValidateDate("");
      props.regValues.setDateOfBirth(newDate);
      setDateInput(newDate);
    }
  };

  const formatDate = (date) => {
    if (date) {
      var datearray = date.split("/");
      return datearray[1] + "/" + datearray[0] + "/" + datearray[2];
    } else {
      return date;
    }
  };

  useEffect(() => {
    if (
      props.regValues.vatNumber.length === 9 &&
      ((props.regValues.dateOfBirth &&
        !isDateNaN(props.regValues.dateOfBirth)) ||
        props.regValues.brandName) &&
      (props.regValues.contractNumber || props.registrationApplicationNumber)
    ) {
      setButtonVariable("PrimaryMedium");
      setMyUrl(
        props.partnerRegistration ? "/partner/register/3" : "/register/3",
      );
    } else {
      setButtonVariable("SecondaryMedium");
      setMyUrl(
        props.partnerRegistration ? "/partner/register/2" : "/register/2",
      );
    }
  }, [
    props.regValues.vatNumber,
    props.regValues.dateOfBirth,
    props.regValues.contractNumber,
    props.regValues.brandName,
    props.registrationApplicationNumber,
  ]);

  useEffect(() => {
    if (
      props.regValues.isContractNumberPrefilled &&
      props.regValues.isContractNumberPrefilled === true
    ) {
      contractNumberInputRef.current.disabled = true;
      contractNumberInputRef.current.readonly = true;
    }
  }, []);

  return (
    <div>
      <h2>
        <span className="light">{t("register.sign-up")}</span>{" "}
        <span className="normal">{t("register.in-the-nn")}</span>
      </h2>
      {props.regValues.regType === 2 ? (
        <div className="form-element right-padded">
          <div className="switch-entity-type">
            <RegistrationRadioButton props={props} defaultValue={"Company"} />
          </div>
        </div>
      ) : null}
      <div className="form-element right-padded">
        <TextInput
          labelText={t("hr.vat-number")}
          name="name"
          onChange={onChange}
          onBlur={() => setValidAFM("")}
          validationText={validAFM}
          id="afm"
          placeholderText={
            props.regValues.regType === 1
              ? t("register.fill-out-vat-number")
              : t("register.fill-out-company-vat-number")
          }
          value={props.regValues.vatNumber}
        />
      </div>
      {props.regValues.regType === 1 ? (
        <>
          <div className="form-element date right-padded">
            <DatepickerInput
              id="datepicker"
              maxDate={`12/31/${yearMax}`}
              minDate={`01/01/${yearMin}`}
              inputFormat={"dd/MM/yyyy"}
              value={new Date(formatDate(dateInput))}
              validationText={validateDate}
              onChange={(e) => insertDate(e)}
              onBlur={() => setValidateDate("")}
              labelText={t("hr.birth-date")}
              placeholderText={t("register.select-date")}
            />
          </div>
        </>
      ) : (
        <div className="form-element right-padded">
          <TextInput
            labelText={t("register.company-name")}
            name="name"
            onChange={onChange}
            onBlur={() => setValidBrandName("")}
            validationText={validBrandName}
            id="company"
            placeholderText={t("register.fill-company-name")}
            value={props.regValues.brandName}
          />
        </div>
      )}

      <div className="form-element right-padded">
        <TextInput
          labelText={
            props.registrationApplicationNumber
              ? t("register.application-number")
              : t("document.insurance-policy-number")
          }
          name="name"
          onChange={onChange}
          onBlur={() => setValidContract("")}
          validationText={validContract}
          id="contractno"
          placeholderText={
            props.regValues.regType === 1
              ? t("register.your-policy-number")
              : t("register.insurance-policy-number")
          }
          value={
            props.registrationApplicationNumber
              ? props.registrationApplicationNumber
              : props.regValues.contractNumber
          }
          ref={contractNumberInputRef}
        />
        <div className={"radiogroup topbutton"}>
          {props.regValues.regType === 1 ? (
            <Button
              onClick={() =>
                navigate(
                  props.partnerRegistration ? "/partner/register" : "/register",
                )
              }
              variant={"SecondaryMedium"}
            >
              {t("global.previous")}
            </Button>
          ) : null}
          <Button
            onClick={() => checkFiledsAndNavigate(myUrl)}
            variant={buttonVariable}
          >
            {t("global.next")}
          </Button>
        </div>
      </div>

      <div className="form-element button"></div>
    </div>
  );
};

export default RegisterFormStep2;
