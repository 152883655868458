import React, { Fragment, useState } from "react";
import { Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  setDateFormatForDateTime,
  ConvertDecimalToString,
} from "../CommonComp/Functions";

const ContractSelectedDocumentTable = (props) => {
  const { t } = useTranslation();
  const [rowsData, setrowsData] = useState(props.Transactions || []);
  const columns = [
    {
      field: "a",
      headerName: t("contract.movement"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => <span>{params.value ? params.value : "-"}</span>,
    },
    {
      field: "b",
      headerName: t("contract.date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span>
          {params.value ? setDateFormatForDateTime(params.value) : "-"}
        </span>
      ),
    },
    {
      field: "c",
      headerName: t("global.how-much"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span>{params.value ? ConvertDecimalToString(params.value) : "-"}</span>
      ),
    },
  ];

  const rows = rowsData.map((e, i) => {
    return {
      id: i,
      a: e.TransactionType,
      b: e.PaymentDate,
      c: e.PaymentAmount,
    };
  });
  return (
    <Fragment>
      <section className="contract-selected-document-table">
        <Table
          localeText={{ noRowsLabel: t("global.no-results-found") }}
          rows={rows}
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
        />
      </section>
    </Fragment>
  );
};

export default ContractSelectedDocumentTable;
