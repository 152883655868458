import React, { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import * as Msal from "msal"; // MSAL v1.0
import AppContent from "./Components/Main/AppContent";
import ReactGA from "react-ga4";
import SettingsStore from "./Stores/SettingsStore";
import B2cStore from "./Stores/B2cStore";
import CookieStore from "./Stores/CookieStore";
import Cookies from "universal-cookie";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css"; // NEEDS TO BE REMOVED PABLO
import { FontFaces as NNFontFaces } from "@nn-design-system/react-component-library";
import getFontPaths from "@nn-design-system/fonts/dist/webpack";
import config from "@nn-design-system/config/web";

import { TranslationsProvider } from "./Context/i18n";

function App() {
  const settingsStore = useContext(SettingsStore);
  const { activeGA, cookiesToBeRemoved } = useContext(CookieStore);
  const b2cStore = useContext(B2cStore);
  const { publicSettings, authorities, clientId } = settingsStore;
  const { msalConfig } = b2cStore;
  const [authService, setAuthService] = useState("");

  config.getFeatureFlags().useAccessibleLightTheme.disable();

  //we will must check if we need to add the get value here

  useEffect(() => {
    //we might have to check if values  publicSettings, authorities are empty only then to call for data
    settingsStore.loadPublicSettings();
  }, []);

  useEffect(() => {
    if (authorities && publicSettings && clientId) {
      msalConfig.auth.clientId = clientId;
      msalConfig.auth.authority = authorities.signIn.authority;
      //initialize authService
      setAuthService(new Msal.UserAgentApplication(msalConfig));
    }
  }, [authorities, publicSettings, clientId]);

  useEffect(() => {
    if (authService) {
      settingsStore.initiateAuthService(authService);
    }
  }, [authService]);

  //Google Analytics
  useEffect(() => {
    const cookies = new Cookies();
    if (Object.keys(publicSettings).length) {
      if (activeGA) {
        ReactGA.initialize([
          {
            trackingId: publicSettings.GoogleAnalyticsKey,
            gaOptions: { cookieFlags: "SameSite=strict; Secure" },
          },
        ]);
      } else {
        window[`ga-disable-${publicSettings.GoogleAnalyticsKey}`] = true;
        if (cookiesToBeRemoved) {
          cookiesToBeRemoved.map((cookieElement) => {
            cookies.remove(cookieElement, { path: "/" });
          });
        }
      }
    }
  }, [publicSettings, activeGA, cookiesToBeRemoved]);

  return (
    <TranslationsProvider>
      {authService && authorities && publicSettings.GoogleAnalyticsKey ? (
        <>
          <NNFontFaces paths={getFontPaths()} />
          <AppContent
            authService={authService}
            authorities={authorities}
            GoogleAnalyticsKey={publicSettings.GoogleAnalyticsKey}
          />
        </>
      ) : null}
    </TranslationsProvider>
  );
}
export default observer(App);
