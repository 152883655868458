import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import dompurify from "dompurify";
import "../../Css/Footer/footer.css";
import SettingsStore from "../../Stores/SettingsStore";
import HRStore from "../../Stores/HRStore";
import HTMLParser from "../../Hooks/HTMLParser";

const Footer = () => {
  const { privateFooter, privateHRFooter } = useContext(SettingsStore);
  const { isHrRole } = useContext(HRStore);

  const sanitizer = dompurify.sanitize;
  const sanitizedFooter = sanitizer(
    isHrRole ? privateHRFooter : privateFooter,
    { ADD_ATTR: ["target"] }
  );

  return <div>{HTMLParser.anchorTagToLink(sanitizedFooter)}</div>;
};

export default observer(Footer);
