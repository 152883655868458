import React, { Fragment, useContext, useCallback } from "react";
import { observer } from "mobx-react-lite";
import ContractStore from "../Stores/ContractStore";
import { NavLink } from "react-router-dom";
import CookieStore from "../Stores/CookieStore";
import HRStore from "../Stores/HRStore";
import { useNavBar } from "./useNavBar";

const NavBar = () => {
  const { navBarItems, navBarItemsHR } = useNavBar();
  const cookieStore = useContext(CookieStore);
  const contractStore = useContext(ContractStore);
  const { isHrRole } = useContext(HRStore);
  const { cookieHasContent, isCustomerOfNNHellas } = cookieStore;
  const { contractList, hasAtLeastOneGroupPolicy } = contractStore;

  const checkNN2Users = process.env.REACT_APP_CHECK_NN2_USERS === "true";

  const isValidNavigationalOption = useCallback(
    (element) => {
      if (element.NodeAlias === "Refunds" && !hasAtLeastOneGroupPolicy) {
        return false;
      }
      if (
        !checkNN2Users ||
        (isCustomerOfNNHellas && contractList.length > 0) ||
        element.NodeAlias == "Home"
      ) {
        return true;
      }
      return false;
    },
    [
      checkNN2Users,
      isCustomerOfNNHellas,
      contractList,
      hasAtLeastOneGroupPolicy,
    ],
  );

  return (
    <nav className={`${isHrRole ? "navigation-HR" : ""} navigation`}>
      {!isHrRole
        ? (!checkNN2Users || cookieHasContent) &&
          navBarItems.map((element) => {
            if (!isValidNavigationalOption(element)) {
              return null;
            }
            return (
              <Fragment key={element.NodeAlias}>
                <NavLink
                  to={element.Url}
                  className={(navData) =>
                    navData.isActive ? "activeLink" : element.DocumentMenuClass
                  }
                >
                  {element.Title}
                </NavLink>{" "}
              </Fragment>
            );
          })
        : navBarItemsHR.map((element) => {
            return (
              <Fragment key={element.NodeAlias}>
                <NavLink
                  to={element.Url}
                  className={(navData) =>
                    navData.isActive ? "activeLink" : element.DocumentMenuClass
                  }
                >
                  {element.Title}
                </NavLink>{" "}
              </Fragment>
            );
          })}
      <span className="force-same-line"> </span>
    </nav>
  );
};
export default observer(NavBar);
