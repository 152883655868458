import React, { useState } from "react";
import { Icon, Paragraph } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import img from "../../assets/index";

const CopyText = ({ selector, text }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const onClickCopy = useCallback((selector) => {
    let copyText = document.querySelector(`#${selector}`);
    navigator.clipboard.writeText(copyText.innerText.split(" ")[1]);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);

  return (
    <Paragraph id={selector} hasMargin={false} mt="10px">
      <div style={{ display: "flex" }}>
        {text}
        {copied ? (
          <Icon ml={"10px"} name="Checkmark" variant="Small" color="Orange" />
        ) : (
          <img
            onClick={() => onClickCopy(selector)}
            style={{ marginLeft: 5 }}
            src={img.document_icon}
            alt="call_icon"
            title={t("commons.tap-to-copy")}
          />
        )}
      </div>
    </Paragraph>
  );
};

export default CopyText;
