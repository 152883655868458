import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import AccountStore from "../../Stores/AccountStore";
import "../../Css/account-contact-info.css";

const AccountContactInfo = () => {
  const { t } = useTranslation();
  const accountStore = useContext(AccountStore);
  const { accountData } = accountStore;

  return (
    <>
      <div className="contact-info-container">
        <label>{t("account.comunication-way")}</label>
        {accountData.SuppressEmails ||
        accountData.SuppressEmails === null ? null : (
          <span>{t("global.via")} email</span>
        )}
        {accountData.SuppressSMS || accountData.SuppressSMS === null ? null : (
          <span>{t("global.via")} sms</span>
        )}
        {accountData.SuppressCalls ||
        accountData.SuppressCalls === null ? null : (
          <span>{t("account.by-phone")}</span>
        )}
      </div>
      <div className="contact-time-container">
        <label>{t("account.suggested-contact-time")}</label>
        <span>{accountData.BestCallTime ? accountData.BestCallTime : "-"}</span>
      </div>
    </>
  );
};
export default observer(AccountContactInfo);
