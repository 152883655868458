import React, { useContext, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Heading,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import UnregisterStore from "../../Stores/UnregisterStore";
import Survey from "../Survey/Survey";
import AccountRegLoginHeader from "../Header/AccountRegLoginHeader";
import img from "../../assets/index";

const UnRegisterCustomerSurvey = () => {
  const { t } = useTranslation();
  const { surveyId } = useParams();
  const {
    unauthorizedSurveyContents,
    getUnauthorizedSurvey,
    surveyIsAnswered,
    surveyIsExpired,
  } = useContext(UnregisterStore);

  useEffect(() => {
    if (!surveyId) return;
    getUnauthorizedSurvey(surveyId);
  }, [surveyId]);

  const surveyIsAnsweredText = useCallback(
    () => (
      <>
        <Paragraph mt="0">{t("survey.answers-already-sent")}</Paragraph>{" "}
        <Paragraph mt="0">{t("survey.team-nn")}</Paragraph>
      </>
    ),
    [surveyIsAnswered],
  );
  const surveyIsExpiredText = useCallback(
    () => (
      <>
        <Heading variant="AttentionalXL" mt="0" hasMargin={false}>
          {t("survey.no-longer-available")}
        </Heading>
        <br />
        <Paragraph mt="0">{t("global.thank-you")},</Paragraph>
        <Paragraph mt="0">{t("survey.team-nn")}</Paragraph>
      </>
    ),
    [surveyIsExpired],
  );

  return (
    (unauthorizedSurveyContents || surveyIsAnswered || surveyIsExpired) && (
      <>
        <div className="nn-container">
          <header>
            <AccountRegLoginHeader />
          </header>
        </div>
        <hr className="line" />
        <img
          className="hero-banner"
          src={img.unreg_mainphoto}
          alt="hero-banner-unregistered-download"
        />

        <div className="survey-form-wrapper">
          {surveyIsAnswered || surveyIsExpired ? (
            surveyIsAnswered ? (
              surveyIsAnsweredText()
            ) : (
              surveyIsExpiredText()
            )
          ) : (
            <Survey surveyContents={unauthorizedSurveyContents} />
          )}
        </div>
      </>
    )
  );
};

export default observer(UnRegisterCustomerSurvey);
