import React, { Fragment } from "react";
import {
  InlineMessage,
  Paragraph,
} from "@nn-design-system/react-component-library";
import { Score, ScoreReason } from "./Score";
import { useFormContext } from "./context";

const FormItem = ({ item }) => {
  switch (item.Type) {
    case "radio":
      return <Score item={item} />;
    case "textArea": {
      return <ScoreReason item={item} />;
    }
    default:
      return null;
  }
};

const Block = ({ question }) => {
  const { errors } = useFormContext();
  const { HelpText, Question, FormItems, QuestionId, index } = question;

  return (
    <div className="survey-question-block">
      {Question && (
        <p className="survey-question-text">
          <span>{index}.</span>
          {Question}
        </p>
      )}

      {HelpText && (
        <Paragraph variant="Small" mt="0" mb="16px">
          {HelpText}
        </Paragraph>
      )}

      {FormItems.map((item) => (
        <div key={item.FormItemId} className={`form-item-${item.Type}`}>
          <FormItem item={{ ...item, questionId: QuestionId }} />

          {errors[QuestionId] && item.Type === "radio" && (
            <InlineMessage
              text={errors[QuestionId]}
              variant="Error"
              mt="24px"
            />
          )}
        </div>
      ))}
    </div>
  );
};

const Section = ({ blocks }) => {
  return (
    <>
      {blocks.map((block, index) => (
        <Fragment key={block.QuestionId}>
          <Block question={{ ...block, index: index + 1 }} />
        </Fragment>
      ))}
    </>
  );
};

export { Section };
