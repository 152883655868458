import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  Button,
  Label,
  DatepickerInput,
} from "@nn-design-system/react-component-library";
import ClaimsStore from "../../../Stores/ClaimsStore";
import { ExpenseType } from "../../../Consts/Claims";
import FilesUpload from "./FilesUpload";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import moment from "moment";

const HospitalAllowance = (props) => {
  const { t } = useTranslation();
  const invalidValueText = t("claim.submission.invalid-value");
  const [entryDate, setEntryDate] = useState("");
  const [entryDateError, setEntryDateError] = useState("");
  const [exitDate, setExitDate] = useState("");
  const [exitDateError, setExitDateError] = useState("");

  const [filesTypes, setFilesTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [showFileValidationError, setShowFileValidationError] = useState(false);

  const claimsStore = useContext(ClaimsStore);

  const { basicSubmissionInfoToSubmit, expenseTypes, documentTypes } =
    claimsStore;

  const currentDateValue = useMemo(() => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }, []);
  const eventDateValue = useMemo(() => {
    let eventDate = new Date(basicSubmissionInfoToSubmit.eventDate);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate;
  }, [basicSubmissionInfoToSubmit.eventDate]);

  const validateEntryDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - eventDateValue.getTime() < 0
        ) {
          setEntryDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setEntryDateError("");
          return true;
        }
        setEntryDateError(invalidValueText);
        return false;
      }
      setEntryDateError(invalidValueText);
      return false;
    },
    [currentDateValue, eventDateValue, setEntryDateError],
  );

  const handleEventEntryDateChange = useCallback(
    (value) => {
      if (validateEntryDate(value)) {
        setEntryDate(value);
      } else {
        setEntryDate("");
      }
    },
    [setEntryDate, validateEntryDate],
  );

  const validateExitDate = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date" &&
        entryDate !== "" &&
        entryDateError === ""
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);
        const entryDateValue = new Date(entryDate);
        entryDateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - currentDateValue.getTime() > 0 ||
          dateValue.getTime() - entryDateValue.getTime() < 0
        ) {
          setExitDateError(invalidValueText);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setExitDateError("");
          return true;
        }
        setExitDateError(invalidValueText);
        return false;
      }
      setExitDateError(invalidValueText);
      return false;
    },
    [entryDate, entryDateError, currentDateValue, setExitDateError],
  );

  const handleEventExitDateChange = useCallback(
    (value) => {
      if (validateExitDate(value)) {
        setExitDate(value);
      } else {
        setExitDate("");
      }
    },
    [setExitDate, validateExitDate],
  );

  const validateFiles = useCallback(
    (filesToCheck) => {
      let isValid = true;
      filesTypes.forEach((fileType) => {
        if (
          fileType.isMandatory &&
          (filesToCheck.length === 0 ||
            !filesToCheck?.find((file) => file.fileType === fileType.value))
        ) {
          isValid = false;
        }
      });
      setShowFileValidationError(!isValid);
      return isValid;
    },
    [filesTypes, setShowFileValidationError],
  );

  const validateHospitalAllowanceInfo = useCallback(() => {
    const isEntryDate = validateEntryDate(entryDate);
    const isExitDate = validateExitDate(exitDate);
    const isFilesValid = validateFiles(files);

    return isEntryDate && isExitDate && isFilesValid;
  }, [
    entryDate,
    exitDate,
    files,
    validateEntryDate,
    validateExitDate,
    validateFiles,
  ]);

  const createNewHospitalAllowanceClaim = useCallback(() => {
    claimsStore.addClaimToSubmitingList({
      ExpenseType: ExpenseType.HospitalAllowance,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      EntryDate: entryDate,
      ExitDate: exitDate,
      Files: files,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(exitDate),
      CollaboratingNetworkList: false,
      AmountList: 0,
      OtherEntityContributedList: false,
    });
  }, [
    entryDate,
    exitDate,
    files,
    claimsStore,
    basicSubmissionInfoToSubmit.mainInsuredMember,
  ]);

  const saveEditedHospitalAllowanceClaim = useCallback(() => {
    claimsStore.saveEditedClaim({
      Id: props.selectedClaimToEdit.Id,
      ExpenseType: ExpenseType.HospitalAllowance,
      MainInsuredMemberList: basicSubmissionInfoToSubmit.mainInsuredMember,
      EntryDate: entryDate,
      ExitDate: exitDate,
      Files: files,
      ReceiptDateList: new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(exitDate),
      CollaboratingNetworkList: false,
      AmountList: 0,
      OtherEntityContributedList: false,
    });
  }, [
    entryDate,
    exitDate,
    files,
    claimsStore,
    basicSubmissionInfoToSubmit.mainInsuredMember,
    props.selectedClaimToEdit,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (validateHospitalAllowanceInfo()) {
      if (props.selectedClaimToEdit !== null) {
        saveEditedHospitalAllowanceClaim();
      } else {
        createNewHospitalAllowanceClaim();
      }
      props.nextStep();
    }
  }, [
    validateHospitalAllowanceInfo,
    createNewHospitalAllowanceClaim,
    saveEditedHospitalAllowanceClaim,
    props,
  ]);

  const handleCancelBtn = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      claimsStore.clearSelectedClaimToEdit();
      props.nextStep();
    } else {
      props.previousStep();
    }
  }, [props, claimsStore]);

  const populateStateWithSelectedClaimToEdit = useCallback(() => {
    if (props.selectedClaimToEdit !== null) {
      setEntryDate(props.selectedClaimToEdit.EntryDate);
      setExitDate(props.selectedClaimToEdit.ExitDate);
      setFiles(props.selectedClaimToEdit.Files);
    }
  }, [props.selectedClaimToEdit, setEntryDate, setExitDate, setFiles]);

  const addFile = useCallback(
    (fileId, fileType, fileName) => {
      const newFiles = [...files, { fileId, fileName, fileType }];
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  const removeFile = useCallback(
    (id) => {
      const newFiles = files.filter((file) => file.fileId !== id);
      setFiles(newFiles);
      validateFiles(newFiles);
    },
    [files, setFiles, validateFiles],
  );

  useEffect(() => {
    populateStateWithSelectedClaimToEdit();
  }, [populateStateWithSelectedClaimToEdit]);

  useEffect(() => {
    if (exitDate !== "") {
      validateExitDate(exitDate);
    }
  }, [entryDate, exitDate, validateExitDate]);

  useEffect(() => {
    const options = [];

    const expenseType = expenseTypes.find(
      (et) => et.Name === ExpenseType.HospitalAllowance,
    );

    if (expenseType) {
      expenseType.DocumentTypes?.forEach((dt) => {
        const currentDocumentType = documentTypes?.find(
          (sdt) => sdt.Name === dt.Name,
        );
        if (currentDocumentType) {
          options.push({
            text: currentDocumentType.Title,
            value: dt.Name,
            isMandatory: dt.IsRequired,
          });
        }
      });
    }

    setFilesTypes(options);
  }, [documentTypes, expenseTypes, setFilesTypes]);

  return (
    <div>
      <div className={styles.formRowContainer} style={{ marginTop: "30px" }}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={t("claim.allowance.entry-date")}
            name="EntryDate"
            placeholderText="HH/ΜΜ/EEEE"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={eventDateValue}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventEntryDateChange(value);
            }}
            onAccept={(value) => {
              handleEventEntryDateChange(value);
            }}
            value={entryDate}
            validationText={entryDateError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={t("claim.allowance.departure-date")}
            name="ExitDate"
            placeholderText="HH/ΜΜ/EEEE"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            maxDate={currentDateValue}
            minDate={entryDate}
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={(event) => {
              let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
              handleEventExitDateChange(value);
            }}
            onAccept={(value) => {
              handleEventExitDateChange(value);
            }}
            value={exitDate}
            validationText={exitDateError}
          />
        </div>
      </div>
      {filesTypes.length > 0 ? (
        <FilesUpload
          filesTypes={filesTypes}
          files={files}
          addFile={addFile}
          removeFile={removeFile}
          showFileValidationError={showFileValidationError}
        />
      ) : (
        <></>
      )}
      <div className={`${styles.buttonsRow}`} style={{ marginTop: "20px" }}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleCancelBtn();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div className={styles.nextStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("claim.entry")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(HospitalAllowance);
