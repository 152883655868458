import React from "react";
import {
  Card,
  Heading,
  Label,
  Paragraph,
  SpinnerLoader,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import "../../Css/Portfolio/portfolio.css";
import { PortfolioPerFundTable } from "./PortfolioPerFundTable";
import { PortfolioPieChart } from "./Charts/PortfolioPieChart";
import { PortfolioChartLegend } from "./Charts/PortfolioChartLegend";
import { usePortfolio } from "./hooks/usePortfolio";

export const PortfolioPerFund = ({
  userPorfolio,
  originalData,
  tabSelected,
}) => {
  const { t } = useTranslation();
  const { totalSum, pieChartData, pieLegendData, data } = usePortfolio({
    userPorfolio,
    tabSelected,
  });

  return (
    <Card>
      <Heading ml="50px">{t("portfolio.by-fund-table-heading")}</Heading>
      <section style={{ display: "flex", alignItems: "center" }}>
        <PortfolioPieChart data={pieChartData} />
        <PortfolioChartLegend data={pieLegendData} />
      </section>
      <Paragraph ml="50px">{t("portfolio.table-into")}</Paragraph>
      {data?.length ? (
        <div style={{ padding: 30 }}>
          <PortfolioPerFundTable
            element={data}
            originalData={originalData}
            totalSum={totalSum}
          />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SpinnerLoader variant="Large" />
        </div>
      )}
      <div style={{ paddingLeft: 30 }}>
        <span style={{ fontWeight: "bold" }}>
          {t("portfolio.by-fund-disclaimer")}
        </span>
      </div>
    </Card>
  );
};
