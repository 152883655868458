import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import KycStore from "../../Stores/KycStore";
import queryString from "query-string";

const KycTransactionHandler = () => {
  const { setCurrentPage, setUrlKycData, kycAccessResponse } = useContext(KycStore);
  const path = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (path.pathname.includes("/oauth/kyc")) {
      const queryData = queryString.parse(path.search);
      if (queryData.state && (queryData.code || queryData.error)) {
        setUrlKycData(queryData);
      }
    }
  }, [path]);

  useEffect(() => {
    if (kycAccessResponse) {
      if (kycAccessResponse?.Success && kycAccessResponse.State) {
        navigate("step1");
        setCurrentPage("1");
      } else if (!kycAccessResponse?.Success && kycAccessResponse?.ErrorCode === "access_denied") {
        navigate("failure", { state: true });
      } else {
        navigate("failure", { state: false });
      }
    }
  }, [kycAccessResponse]);
  return null;
};

export default observer(KycTransactionHandler);
