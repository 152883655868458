import { useState, useEffect, useCallback } from "react";
import { colorsList } from "../../HR/Utils/Constants";

export const usePortfolio = ({
  FundAnalysisList,
  userPorfolio,
  tabSelected,
}) => {
  const [mergePortfolioDataFunds, setMergePortfolioDataFunds] = useState(null);
  const [data, setData] = useState(undefined);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieLegendData, setPieLegendData] = useState([]);

  useEffect(() => {
    if (FundAnalysisList) {
      const mergePortfolioDataFunds = {};
      for (const obj of FundAnalysisList) {
        const {
          FundPercentage,
          NumberOfUnits,
          ContractNumber,
          UnitSpecialPrice,
          UnitPrice,
          FundCode,
          ...rest
        } = obj;
        if (
          mergePortfolioDataFunds[
            tabSelected === "FundName" ? FundCode : ContractNumber
          ]
        ) {
          mergePortfolioDataFunds[
            tabSelected === "FundName" ? FundCode : ContractNumber
          ].NumberOfUnits += NumberOfUnits;
          mergePortfolioDataFunds[
            tabSelected === "FundName" ? FundCode : ContractNumber
          ].TotalAmountInvestment += UnitPrice;
        } else {
          mergePortfolioDataFunds[
            tabSelected === "FundName" ? FundCode : ContractNumber
          ] = {
            ...(tabSelected === "FundName" ? { FundCode } : { ContractNumber }),
            NumberOfUnits: NumberOfUnits,
            TotalAmountInvestment: UnitPrice,
            UnitSpecialPrice,
            ...rest,
          };
        }
      }
      const mergedData = Object.values(mergePortfolioDataFunds);
      setMergePortfolioDataFunds(mergedData);
    }
  }, [FundAnalysisList, tabSelected]);

  let totalSum = useCallback((data) => {
    let total = 0;
    for (let obj of data) {
      total += obj.TotalAmountInvestment;
    }
    return Number(total);
  }, []);

  useEffect(() => {
    if (userPorfolio) {
      userPorfolio.sort(
        (a, b) => b.TotalAmountInvestment - a.TotalAmountInvestment,
      );
      const sortedData = userPorfolio?.map((el, i) => ({
        ...el,
        Color: colorsList[i],
      }));

      setData(sortedData);
    }
  }, [userPorfolio]);

  useEffect(() => {
    let pieData = data?.map((el) => {
      return {
        x: el.FundName,
        y: (el.TotalAmountInvestment / totalSum(userPorfolio)) * 100,
        fundValue: el.TotalAmountInvestment,
        fill: el.Color,
      };
    });

    let legendData = data
      ?.map((el) => ({
        legendName:
          tabSelected === "FundName"
            ? el.FundName
            : `${el.ProductDescription} ${el.ContractNumber}`,
        legendPercentage: (
          (el.TotalAmountInvestment / totalSum(userPorfolio)) *
          100
        ).toFixed(2),
        color: el.Color,
      }))
      .filter((e) => e.legendPercentage !== "0.00");

    setPieChartData(pieData);
    setPieLegendData(legendData);
  }, [data, tabSelected]);

  return {
    mergePortfolioDataFunds,
    pieChartData,
    pieLegendData,
    data,
    totalSum,
  };
};
