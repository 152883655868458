import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";

import UnregisterStore from "../../Stores/UnregisterStore";
import img from "../../assets/index";
import "../../Css/Register/unregistered-dowload.css";
import AccountRegLoginHeader from "../Header/AccountRegLoginHeader";
import Survey from "../Survey/Survey";
import { observer } from "mobx-react-lite";
import UrlStore from "../../Stores/UrlStore";

const UnRegisterDowload = () => {
  const { t } = useTranslation();
  const unregisterStore = useContext(UnregisterStore);
  const {
    isDocsExists,
    docsToDownload,
    authStatus,
    getResponce,
    productDescription,
    surveyContents,
  } = unregisterStore;
  const urlStore = useContext(UrlStore);
  const [downloadClass, setDownloadClass] = useState("");
  const [counter, setCounter] = useState(-1);
  const [linkLength, setLinkLength] = useState(0);
  const { baseURL } = urlStore;

  useEffect(() => {
    urlStore.loadURL(process.env.REACT_APP_ENVIRONMENT);
  }, []);

  const location = useLocation();

  let newCount = -1;

  const getId = (url) => {
    if (url) {
      let contractKey = url.split("/");
      if (contractKey.length === 4) {
        return contractKey[3];
      }
    }
  };

  useEffect(() => {
    const urlId = getId(location.pathname);
    if (urlId) {
      unregisterStore.getDocsAndSurvey(urlId);
    }
  }, [location.pathname]);

  const DownloadDocs = () => {
    setDownloadClass("active");
    var links = [];
    // bring base url
    docsToDownload.map((docElement, i) => {
      let doc = {
        link: `${baseURL}/documents/unregistered/download?fileName=${docElement.FName}&encFNum=${docElement.EncFNum}`,
        docId: docElement.FName,
      };
      links.push(doc);
    });
    setLinkLength(links.length);

    for (let i = 0; i < links.length; i++) {
      download(links[i].link, links[i].docId);
    }

    const urlId = getId(location.pathname);
    unregisterStore.confirmDocumentsDownload(urlId);
  };

  const download = (url, filename) => {
    fetch(url, { mode: "no-cors" })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((uril) => {
        var link = document.createElement("a");
        link.href = uril;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .then(() => {
        newCount = newCount + 1;
        setCounter(newCount);
      });
  };

  useEffect(() => {
    if (counter === linkLength - 1) {
      setDownloadClass("");
      setCounter(-1);
    }
  }, [counter, linkLength]);

  const createAccountUrlMap = {
    local: process.env.REACT_APP_SITE_URL_LOCAL,
    dev: process.env.REACT_APP_SITE_URL_DEV,
    test: process.env.REACT_APP_SITE_URL_TEST,
    uat: process.env.REACT_APP_SITE_URL_UAT,
    live: process.env.REACT_APP_SITE_URL_LIVE,
  };
  const createAccountUrl =
    createAccountUrlMap[process.env.REACT_APP_ENVIRONMENT];

  return (
    <>
      <div className="nn-container">
        <header>
          <AccountRegLoginHeader />
        </header>
      </div>

      <hr className="line" />
      <img
        className="hero-banner"
        src={img.unreg_mainphoto}
        alt="hero-banner-unregistered-download"
      />

      <div className="nn-container">
        {getResponce ? (
          authStatus === 0 ? (
            <>
              <h1 className="my-label">
                <span className="light">{t("register.receipt")}</span>{" "}
                <span className="normal">
                  {t("contracts.insurance-number")}
                </span>{" "}
                <span className="dark">{isDocsExists}</span>
              </h1>
              <p>
                {t("register.click-link-below")}{" "}
                <b>{t("global.insurance-policy")}</b> {t("register.your")}
              </p>
              <p className="unregistered-download">
                <a className={`${downloadClass}`} onClick={DownloadDocs}>
                  <img src={img.download2} />
                  <b>
                    {t("global.insurance-policy")} {productDescription}
                  </b>
                </a>
              </p>
            </>
          ) : (
            <div className="nn-container">
              <p>{t("register.page-is-no-available")}</p>
            </div>
          )
        ) : null}
      </div>
      {surveyContents && (
        <>
          <Card variant="Tinted" maxWidth="876px" mr="auto" ml="auto" mt="32px">
            <div className="survey-form-wrapper">
              <Survey surveyContents={surveyContents} />
            </div>
          </Card>
          <Card
            variant="Attentional"
            maxWidth="970px"
            mr="auto"
            ml="auto"
            mt="32px"
            mb="32px"
          >
            <a href={createAccountUrl} className="survey-create-account-link">
              {t("register.create-account-access")}
            </a>
          </Card>
        </>
      )}
    </>
  );
};

export default observer(UnRegisterDowload);
