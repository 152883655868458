import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button, Tabs } from "@nn-design-system/react-component-library";
import AccountPayments from "./AccountPayments";
import AccountDetails from "./AccountDetails";
import AccountContactInfo from "./AccountContactInfo";
import AccountStore from "../../Stores/AccountStore";
import SettingsStore from "../../Stores/SettingsStore";

const AccountTab = () => {
  const { t } = useTranslation();
  const { accountData } = useContext(AccountStore);
  const { publicSettings, clientId } = useContext(SettingsStore);

  const handleChangePassword = () => {
    const { AzureADTenantName, AzureADUserFlowChangePassword } = publicSettings;
    const currentUrl = window.location.origin;

    const destination = new URL(
      `https://${AzureADTenantName}.b2clogin.com/${AzureADTenantName}.onmicrosoft.com/oauth2/v2.0/authorize`,
    );

    destination.searchParams.append("p", AzureADUserFlowChangePassword);
    destination.searchParams.append("client_id", clientId);
    destination.searchParams.append("nonce", "defaultNonce");
    destination.searchParams.append("redirect_uri", `${currentUrl}/home`);
    destination.searchParams.append("scope", "openid");
    destination.searchParams.append("response_type", "id_token");
    destination.searchParams.append("response_mode", "query");

    window.location.href = destination.href;
  };

  const getTabs = () => {
    let tabs = [
      {
        contentElement: (
          <div className={`tab-pane container active`}>
            <AccountDetails />{" "}
          </div>
        ),
        tabText: t("account.my-information"),
      },
      {
        contentElement: (
          <div className={`tab-pane container active`}>
            {" "}
            <AccountPayments />{" "}
          </div>
        ),
        tabText: t("account.payment-accounts"),
      },
      {
        contentElement: (
          <Button variant="PrimaryMedium" onClick={handleChangePassword}>
            {t("account.change-psw")}
          </Button>
        ),
        tabText: t(t("account.settings")),
      },
    ];

    if (
      accountData.ShowCommunicationPreferences ||
      accountData.ShowCommunicationPreferences !== null
    ) {
      if (accountData.ShowCommunicationPreferences) {
        tabs.push({
          contentElement: (
            <div className={`tab-pane container active`}>
              <AccountContactInfo />
            </div>
          ),
          tabText: t("account.ways-comunication"),
        });
      }
    }

    return tabs;
  };

  return (
    <div id="account-tabs" className="nn-container">
      <div className="panel panel-acount">
        <Tabs centered tabs={getTabs()} />
      </div>
    </div>
  );
};

export default observer(AccountTab);
