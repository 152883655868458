import React, { useState, useCallback } from "react";
import { Icon, Table } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToStringWithCurrency,
  ConvertDecimalToStringWithOutCurrency,
  stringToAmount,
} from "../CommonComp/Functions";
import GroupPensionPriceHistoryModal from "./GroupPensionPriceHistoryModal";
import styles from "../../Css/GroupPension/GroupPension.module.scss";

const GroupPensionAnalysisNNTable = (props) => {
  const { t } = useTranslation();
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const [modalData, setModalData] = useState();
  const maxTypeStringLength = 110;

  const clickBarChartIcon = (value) => {
    setModalData(value);
    setShowPriceHistoryModal(true);
  };

  const formatTypeValue = useCallback(
    (value) => {
      if (value.length >= maxTypeStringLength) {
        return value.substring(0, maxTypeStringLength - 3) + "...";
      }
      return value;
    },
    [maxTypeStringLength],
  );

  const columns = [
    {
      field: "a",
      flex: 2.6,
      headerAlign: "left",
      headerName: t("contract.investment-type"),
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
      cellClassName: "whiteSpaceNormal groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        return (
          <span
            title={
              params?.value?.length >= maxTypeStringLength ? params.value : ""
            }
            style={{ fontWeight: params.row.e === -1 ? "bold" : "" }}
          >
            {params.row.e === -1
              ? t("global.total")
              : formatTypeValue(params.value)}
          </span>
        );
      },
    },
    {
      field: "e",
      headerName: t("contract.total-shares"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        if (params.value === -1) {
          return <div></div>;
        }

        return (
          <span>
            {ConvertDecimalToStringWithOutCurrency(params.value, "el-GR", 4)}
          </span>
        );
      },
    },
    {
      field: "f",
      flex: 1,
      headerName: t("contract.shares-price"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        if (params.value === null || params.row.h) {
          return <span> - </span>;
        }

        if (params.value === -1) {
          return <div></div>;
        }

        return <span>{stringToAmount(params.value, 4)}</span>;
      },
    },
    {
      field: "d",
      headerName: t("contract.total-account-value"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        return (
          <span style={{ fontWeight: params.row.e === -1 ? "bold" : "" }}>
            {ConvertDecimalToStringWithCurrency(params.value)}
          </span>
        );
      },
    },
    {
      field: "b",
      headerName: t("contract.employer-account-department"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        return (
          <span style={{ fontWeight: params.row.e === -1 ? "bold" : "" }}>
            {ConvertDecimalToStringWithCurrency(params.value)}
          </span>
        );
      },
    },
    {
      field: "c",
      headerName: t("contract.voluntary-account-department"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        return (
          <span style={{ fontWeight: params.row.e === -1 ? "bold" : "" }}>
            {ConvertDecimalToStringWithCurrency(params.value, null, null)}
          </span>
        );
      },
    },
    {
      field: "g",
      headerName: t("contract.price-history"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "center",
      flex: 1,
      sortable: false,
      cellClassName: "groupPensionIndividualAnalysisRowCell",
      renderCell: (params) => {
        if (!params.value || !params.value.length) {
          return <div></div>;
        }

        return (
          <button
            className={styles.noOutline}
            style={{
              backgroundColor: "unset",
              margin: "0",
              border: "0",
              padding: "0",
              cursor: "pointer",
            }}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              clickBarChartIcon(params.value);
            }}
          >
            <Icon name="BarChart" color="#EE7F00" />
          </button>
        );
      },
    },
  ];

  const rows = props.tableData?.map((elem, i) => {
    return {
      id: i,
      a: elem.InvestmentType,
      b: elem.EmployerContribution,
      c: elem.VoluntaryContribution,
      d: elem.TotalAmount,
      e: elem.TotalShares,
      f: elem.SharePrice,
      g: elem.SharePriceHistory,
      h: elem.IsGroup,
    };
  });

  const getRowClassName = (params) => {
    return params.row.h ? "MuiDataGrid-row--grouprow" : "";
  };

  return (
    <>
      {rows && (
        <>
          <GroupPensionPriceHistoryModal
            isOpen={showPriceHistoryModal}
            data={modalData}
            onClose={() => {
              setShowPriceHistoryModal(false);
            }}
          />
        </>
      )}
      <div className="groupPensionIndividual-analysistable">
        <Table
          mt="48px"
          rows={rows || []}
          columns={columns}
          density="comfortable"
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          getRowClassName={getRowClassName}
          localeText={{
            noRowsLabel: props.errMsg
              ? props.errMsg
              : t("global.no-results-found"),
          }}
        />
      </div>
    </>
  );
};

export default GroupPensionAnalysisNNTable;
