import React, { useState } from "react";
import {
  Table,
  Icon,
  Paragraph,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import {
  setDateFormatForDateTime,
  stringToAmount,
} from "../../CommonComp/Functions";
import DownloadClaimHRDocument from "./DownloadClaimHRDocument";
import { useTranslation } from "react-i18next";
export const ClaimsDetailPanelTable = ({ element, contract }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const columns = [
    {
      field: "BenefitDescription",
      headerName: t("claim.table.header.output-type"),
      flex: 1.5,
      headerAlign: "left",
      align: "left",
      sortable: false,
      headerClassName: "textAlignLeft",
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "PortalSubmitted",
      headerName: t("claim.table.header.registered-email"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.value && (
          <Icon name="Checkmark" variant="Small" color="#EA650D" />
        ),
    },
    {
      field: "RequestedAmount",
      headerName: t("claim.table.header.amount-requested"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "CompensationAmount",
      headerName: t("claim.table.header.compensation-amount"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "PaymentDate",
      headerName: t("hr.payment-date"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      headerClassName: "textAlignLeft",
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
    },
    {
      field: "BeneficiaryName",
      headerName: t("hr.beneficiary-bearer"),
      headerAlign: "center",
      align: "left",
      flex: 1.3,
      headerClassName: "textAlignLeft",
      sortable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "a",
      headerName: t("hr.detailed-compensation-sheet"),
      headerAlign: "center",
      headerClassName: "textAlignLeft",
      align: "center",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <DownloadClaimHRDocument
              params={params}
              contract={contract}
              setShowModal={setShowModal}
            />
          </>
        );
      },
    },
  ];

  const rows = element.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <div>
      <Table
        localeText={{ noRowsLabel: t("global.no-results-found") }}
        rows={rows}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
      ></Table>
      <PrefabModal
        isOpen={showModal}
        hasCloseButton
        children={
          <div className={""}>
            <Paragraph hasMargin={false}>
              {t("claims.detailed-compensation-issued-from")}
            </Paragraph>
          </div>
        }
        onClose={() => {
          setShowModal(false);
        }}
        width="350px"
        height="auto"
        headingText={t("claims.download-document")}
      />
    </div>
  );
};
