import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import img from "../../assets/index";
import UserStore from "../../Stores/UserStore";
const RegisterSuccess = () => {
  const { t } = useTranslation();
  const userStore = useContext(UserStore);
  const { resendEmailLink, resendEmailLinkResponseMessage } = userStore;

  const [message, setMessage] = useState();
  const [show, setShow] = useState(true);

  const recendEmail = (url) => {
    let newUrl = url.split("=")[1];
    userStore.resendRegistrationSuccessEmail(newUrl);
  };

  useEffect(() => {
    if (resendEmailLink) {
      setMessage(resendEmailLink.Title);
    }
  }, [resendEmailLink]);

  useEffect(() => {
    if (resendEmailLinkResponseMessage) {
      setMessage(resendEmailLinkResponseMessage);
      setShow(false);
    }
  }, [resendEmailLinkResponseMessage]);

  return (
    <>
      <div>
        <h2 className="success">
          <img src={img.authorisation} alt="authorisation" />
          {t("register.complete-successfully")}
        </h2>
        <div className="form-element">
          <span>{t("register.activate-account-msg")}</span>
        </div>
        <div className="form-element">
          {resendEmailLink ? (
            show ? (
              <div className="chevron-link-icon">
                <a>
                  <Icon name="ChevronRight" variant="Small" color="red" />
                  <span onClick={() => recendEmail(resendEmailLink.Url)}>
                    {message}
                  </span>
                </a>
              </div>
            ) : (
              <div className="chevron-link-icon-check">
                <Icon name="Checkmark" variant="Small" color="red" />
                <span>{message}</span>
              </div>
            )
          ) : null}
        </div>
        <div className="form-element">
          <span className="help">
            {t("commons.contact-us-phone")} +30 210 950 6000.
          </span>
        </div>
      </div>
    </>
  );
};

export default observer(RegisterSuccess);
