import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import agent from "../Api/agent";

class AccountStore {
  accountData = [];
  accountHasContent = false;
  hasMultipleRole = false;

  constructor() {
    makeObservable(this, {
      accountData: observable,
      accountHasContent: observable,

      loadLegalAccount: action,
      loadAccount: action,
      loadAccountDetails: action,
      updateTermPage: action,
      logLogin: action,
      logout: action,
    });
  }

  loadAccount = () => {
    this.accountHasContent = false;

    const account = agent.account.person();
    account
      .then((result) => {
        this.accountData = { ...result };
        this.accountHasContent = true;
      })
      .catch((error) => {
        this.accountHasContent = true;
      });
  };

  loadLegalAccount = () => {
    this.accountHasContent = false;
    const account = agent.account.legal();
    account
      .then((result) => {
        this.accountData = { ...result };
        this.accountHasContent = true;
      })
      .catch((error) => {
        this.accountHasContent = true;
      });
  };

  loadAccountDetails = () => {
    console.log("loadAccount");
    this.accountHasContent = false;
    const account = agent.account.entityDetails();
    account
      .then((result) => {
        this.accountData = { ...result };
        this.hasMultipleRole = !!this.accountData.HRAdministratorRelationships.length;
        this.accountHasContent = true;
      })
      .catch((error) => {
        console.log(error);
        this.accountHasContent = true;
      });
  };

  deleteAccountData = () => {
    this.accountData = [];
  };

  logLogin = () => {
    let loginLogResponse = agent.account.logLogin();
    loginLogResponse.then((result) => {
      console.log(result);
      if (result.Status === 0) {
        sessionStorage.setItem("LoginLog", true);
      }
      if (result.Status === 1) {
        sessionStorage.setItem("LoginLog", true);
      }
    });
  };

  updateTermPage = () => {
    let body = {
      PreferredCommunicationMethod: 3,
    };

    const updateAccount = agent.account.updateTermPages(body);
    updateAccount
      .then((result) => {
        //do nothing
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateMarketingConsent = (body) => {
    const updateAccount = agent.account.updateConsent(body);
    updateAccount
      .then((result) => {
        //do nothing
      })
      .catch((error) => {
        console.log(error);
      });
  };

  logout = () => {
    let logoutResponse = agent.account.logout();
    logoutResponse
      .then((result) => {})
      .catch((ex) => {
        console.log("Unable to logout user with error", ex);
      });
  };
}

export default createContext(new AccountStore());
