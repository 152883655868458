import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Select } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import HRStore from "../../../Stores/HRStore";
import LoaderStore from "../../../Stores/LoaderStore";
import { DocumentsSelecetedTable } from "./DocumentsSelecetedTable";
import SpinnerSmall3 from "../../CommonComp/SpinnerSmall3";
import { allCompanyNameOptionSelect } from "../Utils/Constants";
const DocumentsHR = ({ contract }) => {
  const { t } = useTranslation();
  const { loadHRDocuments, hrDocumentsList, showDocuementsStore } =
    useContext(HRStore);
  const { setMiniLoaderForData3 } = useContext(LoaderStore);
  const [list, setList] = useState([]);
  const [documentsOptionsSelect, setDocumentsOptionsSelect] = useState([]);
  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    let filteredDocuments = hrDocumentsList.filter(({ Description }) =>
      selectValue === "all" ? true : Description === selectValue,
    );
    setList(filteredDocuments);
  }, [selectValue]);

  useEffect(() => {
    if (!showDocuementsStore) {
      setMiniLoaderForData3(true);
      loadHRDocuments(contract.NelisId, contract.PolicyNumber);
    }
  }, [contract]);

  useEffect(() => {
    let selectOptions = hrDocumentsList.map(({ Description }) => ({
      text: Description,
      value: Description,
    }));
    setDocumentsOptionsSelect([
      ...allCompanyNameOptionSelect,
      ...selectOptions,
    ]);
    setList(hrDocumentsList);
    setMiniLoaderForData3(true);
  }, [hrDocumentsList]);

  return (
    <>
      {showDocuementsStore ? (
        <div className="contract-hr-tab-container">
          <div className="selected-contract-documents">
            <div className="select-nn-hr">
              <Select
                mt="20px"
                mb="20px"
                placeholderText={`- ${t("global.choose")} -`}
                name="select"
                labelText={t("file-upload.document-type")}
                onChange={(ev) => setSelectValue(ev.target.value)}
                options={documentsOptionsSelect}
                testId="select"
                value={selectValue}
              />
            </div>
            <div>
              <DocumentsSelecetedTable data={list} />
            </div>
          </div>
        </div>
      ) : (
        <SpinnerSmall3 />
      )}
    </>
  );
};

export default observer(DocumentsHR);
