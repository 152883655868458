import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Heading,
  InlineMessage,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { ExpenseType } from "../../../Consts/Claims";
import HospitalAllowance from "./HospitalAllowance";
import SurgicalAllowance from "./SurgicalAllowance";
import MaternityAllowance from "./MaternityAllowance";
import ClaimsStore from "../../../Stores/ClaimsStore";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import ExpenseTypeItem from "./ExpenseTypeItem";
import MaternityExpenseTypeItem from "./MaternityExpenseTypeItem";

const Allowance = (props) => {
  const { t } = useTranslation();
  const initialHeadingText = t("claim.allowance.header");
  const afterSelectionHeadingText = t("claim.allowance.register-output");
  const [headingText, setHeadingText] = useState(initialHeadingText);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showCoverCodeError, setShowCoverCodeError] = useState(false);
  const [showExpenseComponent, setShowExpenseComponent] = useState(false);
  const claimStore = useContext(ClaimsStore);
  const {
    expenseTypes,
    selectedClaimToEdit,
    usedCoverCode,
    claimsListToSubmit,
  } = claimStore;

  const selectExpense = useCallback(() => {
    if (selectedExpense !== null) {
      setHeadingText(afterSelectionHeadingText);
      setShowExpenseComponent(true);
    }
  }, [selectedExpense, setHeadingText, setShowExpenseComponent]);

  const enableNextPageBtn = useMemo(() => {
    return selectedExpense !== null && !showCoverCodeError;
  }, [selectedExpense, showCoverCodeError]);

  const clearLocalState = useCallback(() => {
    setSelectedExpense(null);
    setShowCoverCodeError(false);
    setShowExpenseComponent(false);
    setHeadingText(initialHeadingText);
  }, [setSelectedExpense, setShowExpenseComponent, setHeadingText]);

  const renderSelectedExpense = useCallback(() => {
    if (showExpenseComponent) {
      switch (selectedExpense) {
        case ExpenseType.HospitalAllowance:
          return (
            <HospitalAllowance
              previousStep={clearLocalState}
              nextStep={props.nextStep}
              selectedClaimToEdit={selectedClaimToEdit}
            />
          );
        case ExpenseType.SurgicalAllowance:
          return (
            <SurgicalAllowance
              previousStep={clearLocalState}
              nextStep={props.nextStep}
              selectedClaimToEdit={selectedClaimToEdit}
            />
          );
        case ExpenseType.MiscarriageMaternityAllowance:
        case ExpenseType.NormalMaternityAllowance:
        case ExpenseType.CaesareanMaternityAllowance:
          return (
            <MaternityAllowance
              previousStep={clearLocalState}
              nextStep={props.nextStep}
              selectedClaimToEdit={selectedClaimToEdit}
            />
          );

        default:
          return null;
      }
    }
    return null;
  }, [
    showExpenseComponent,
    selectedExpense,
    clearLocalState,
    props.nextStep,
    selectedClaimToEdit,
  ]);

  const isExpenseElementDisabled = useCallback(
    (expeseElemInQuestion) => {
      return showExpenseComponent && expeseElemInQuestion !== selectedExpense;
    },
    [selectedExpense, showExpenseComponent],
  );

  useEffect(() => {
    if (selectedClaimToEdit !== null) {
      setSelectedExpense(selectedClaimToEdit.ExpenseType);
      setShowExpenseComponent(true);
    }
  }, [selectedClaimToEdit, setSelectedExpense, setShowExpenseComponent]);

  useEffect(() => {
    const selectedCoverCode = expenseTypes?.find(
      (et) => et.Name === selectedExpense,
    )?.CoverCode;

    if (
      selectedExpense &&
      usedCoverCode &&
      selectedCoverCode !== usedCoverCode
    ) {
      setShowCoverCodeError(true);
    } else {
      setShowCoverCodeError(false);
    }
  }, [expenseTypes, selectedExpense, usedCoverCode, setShowCoverCodeError]);

  const renderExpenseType = useCallback(
    (currentExpenseTypeCode) => {
      const currentExpenseType = expenseTypes.find(
        (expenseType) => expenseType.Name === currentExpenseTypeCode,
      );

      if (!currentExpenseType) {
        return <></>;
      }

      return (
        <ExpenseTypeItem
          currentExpenseTypeCode={currentExpenseTypeCode}
          currentExpenseType={currentExpenseType}
          isExpenseElementDisabled={isExpenseElementDisabled}
          selectedExpense={selectedExpense}
          setSelectedExpense={setSelectedExpense}
          showExpenseComponent={showExpenseComponent}
        />
      );
    },
    [
      expenseTypes,
      isExpenseElementDisabled,
      selectedExpense,
      setSelectedExpense,
      showExpenseComponent,
    ],
  );

  const renderMaternityExpenseType = useCallback(() => {
    const currentExpenseType = expenseTypes.find(
      (expenseType) =>
        expenseType.Name === ExpenseType.NormalMaternityAllowance ||
        expenseType.Name === ExpenseType.CaesareanMaternityAllowance ||
        expenseType.Name === ExpenseType.MiscarriageMaternityAllowance,
    );

    if (!currentExpenseType) {
      return <></>;
    }

    return (
      <MaternityExpenseTypeItem
        possibleExpenseTypeCodes={[
          ExpenseType.NormalMaternityAllowance,
          ExpenseType.CaesareanMaternityAllowance,
          ExpenseType.MiscarriageMaternityAllowance,
        ]}
        displayName={t("claim.allowance.maternity-allowance")}
        isExpenseElementDisabled={isExpenseElementDisabled}
        selectedExpense={selectedExpense}
        setSelectedExpense={setSelectedExpense}
        showExpenseComponent={showExpenseComponent}
      />
    );
  }, [
    expenseTypes,
    isExpenseElementDisabled,
    selectedExpense,
    setSelectedExpense,
    showExpenseComponent,
  ]);

  return (
    <div>
      <Heading mb="20px" isFirstSection>
        {headingText}
      </Heading>
      <div className={styles.expensesImgsContainer}>
        {renderExpenseType(ExpenseType.HospitalAllowance)}
        {renderExpenseType(ExpenseType.SurgicalAllowance)}
        {renderMaternityExpenseType()}
      </div>
      {renderSelectedExpense()}
      {showCoverCodeError ? (
        <div className={`${styles.note} ${styles.responsiveFontSize}`}>
          <InlineMessage text={t("claim.allowance.error-text")} />
        </div>
      ) : (
        <></>
      )}
      <div
        className={`${styles.buttonsRow} ${
          showExpenseComponent ? "doNotDisplay" : ""
        }`}
        style={{ marginTop: "20px" }}
      >
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              props.previousStep();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div>
          {claimsListToSubmit.length > 0 ? (
            <div className={`${styles.claimsListBtn}`}>
              <Button
                type="Button"
                onClick={() => {
                  props.nextStep();
                }}
              >
                {t("claim.submission.expense-clearance")}
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div
            className={`${styles.nextStepBtn} ${
              enableNextPageBtn ? "" : styles.disabledBtn
            }`}
          >
            <Button
              type="Button"
              onClick={() => {
                if (enableNextPageBtn) selectExpense();
              }}
            >
              {t("global.continue")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Allowance);
