import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InboxTable from "./InboxTable";
import InboxMessageDetails from "./InboxMessageDetails";

const InboxToggleComp = () => {
  const [showInboxTable, setShowInboxTable] = useState(null);
  const path = useLocation();

  useEffect(() => {
    if (path.pathname.includes("details")) {
      setShowInboxTable(false);
    } else {
      setShowInboxTable(true);
    }
  }, [path.pathname]);

  return <Fragment>{showInboxTable ? <InboxTable /> : <InboxMessageDetails />}</Fragment>;
};
export default InboxToggleComp;
