import React from "react";
import { Label, Paragraph } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import ElementDownload from "../Document/ElementDownload";

const GroupPensionCertificatesList = (props) => {
  const { t } = useTranslation();
  return props.tableData?.map((elem, i) => {
    return (
      <div key={i}>
        <div className="list-container" style={{ margin: "0" }}>
          <div>
            <Label
              text={`${t("contract.certificate-type")} / ${t("contract.certificate")}`}
              hasMargin={false}
            />
            <Paragraph hasMargin={false}>{elem.CertificateName}</Paragraph>
          </div>
          <div>
            <Label text={t("contract.year")} hasMargin={false} />
            <Paragraph hasMargin={false}>{elem.Year}</Paragraph>
          </div>
          <div>
            <Label text={t("document.download")} />
            <ElementDownload
              fileName={`${elem.CertificateName}-${elem.Year}`}
              DocumentLink={elem.DownloadUrl}
              MimeType={elem.FileType}
              method="POST"
              postData={elem.CertificateData}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default GroupPensionCertificatesList;
