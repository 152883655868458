import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import DocumentsStore from "../../Stores/DocumentsStore";
import ContractStore from "../../Stores/ContractStore";
import InvestmentAccountContentForVisibleFields from "./InvestmentAccountContentForVisibleFields";
import InvestmentAccountContent from "./InvestmentAccountContent";
import SpinnerSmall from "../CommonComp/SpinnerSmall";

const ContractSelectedAccountInvestment = (props) => {
  const { documentList } = useContext(DocumentsStore);
  const contractStore = useContext(ContractStore);
  const { showBillStore } = contractStore;
  const [filteredDoc, setFilteredDoc] = useState([]);

  useEffect(() => {
    if (props.contract.Number && props.getData) {
      contractStore.loadBill(props.contract.Number, props.contract.ProductCode, props.contract.ProductCategoryId);
    }
  }, [props.contract.Number, props.getData]);

  useEffect(() => {
    filterDocumentList(props.contractDetails.DocumentTypes);
  }, [props.contractDetails.DocumentTypes]);

  const filterDocumentList = (arrayVal) => {
    const newArray = documentList.filter((d) => d.ContractNumber === props.contract.Number);
    let finalArrayOfDocument = [];
    if (arrayVal) {
      for (let i = 0; i < arrayVal.length; i++) {
        let newFilteredArray = newArray.filter((d) => d.DocumentTypeId === arrayVal[i]);
        if (newFilteredArray.length > 0) {
          newFilteredArray.map((element) => {
            finalArrayOfDocument.push(element);
          });
        }
      }
    }
    setFilteredDoc(finalArrayOfDocument);
  };

  return (
    <>
      {showBillStore ? (
        <>
          {props.contractDetails.VisibleFields ? (
            <InvestmentAccountContentForVisibleFields
              contractDetails={props.contractDetails}
              contract={props.contract}
              OsekaLinks={props.OsekaLinks}
              filteredDoc={filteredDoc}
              adHoc={props.adHocSt}
            />
          ) : (
            <>
              <InvestmentAccountContent
                contractDetails={props.contractDetails}
                contract={props.contract}
                OsekaLinks={props.OsekaLinks}
                filteredDoc={filteredDoc}
                adHoc={props.adHocSt}
              />
            </>
          )}
        </>
      ) : (
        <SpinnerSmall />
      )}
    </>
  );
};
export default observer(ContractSelectedAccountInvestment);
