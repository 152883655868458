import i18n from "../../i18n";
import { htmlElement } from "./Functions";
//event is the html event
//event id comes from html tag id
//value is the original value we see from use state
//valueSet is the setter of the value we see from use state
//validSet is the setter of the validation value we see from use state
//textLength is the value of our validation example (type until you have 50 characters)
//regexType is for text or numeric inputs
//activateRegEx if we want to validate content or only length
export const textInputValidation = (
  event,
  eventId,
  value,
  valueSet,
  validSet,
  textLength,
  regexType,
  activateRegEx,
) => {
  const regExpText = /^[a-zA-Zα-ωΑ-ΩάέήίόύώϊϋΆΈΉΊΌΎΏΪΫς ]*$/g;
  const regExpNumeric = /^\d*$/g;
  const regExpAlphanumeric =
    /^[a-zA-Z0-9α-ωΑ-Ω!@#$%^&*()_+\-=[\]{};':"\\|,./?]*$/g;

  let regExp;
  let typeOfInput;

  switch (regexType) {
    case "text":
      regExp = regExpText;
      typeOfInput = i18n.t("commons.letters");
      break;
    case "numeric":
      regExp = regExpNumeric;
      typeOfInput = i18n.t("commons.numbers");
      break;
    case "alphanumeric":
      regExp = regExpAlphanumeric;
      typeOfInput = i18n.t("commons.letters-numbers-specials");
      break;
    default:
      return;
  }

  if (event.target.id === eventId) {
    if (!regExp.test(event.target.value) && activateRegEx) {
      valueSet(value);
      validSet(htmlElement(`${i18n.t("commons.enter-only")} ${typeOfInput}`));
      return;
    }
    if (event.target.value.trim().length <= textLength) {
      validSet("");
      valueSet(event.target.value);
    } else {
      validSet(
        htmlElement(
          `${i18n.t("commons.enter-up-to")} ${textLength} ${i18n.t("commons.characters")}`,
        ),
      );
      valueSet(value);
    }
  }
};

const Validations = () => {};
export default Validations;
