import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Link } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";

import ContractStore from "../../Stores/ContractStore";
import "../../Css/home-contracts-table.css";
import { setDateFormatForDateTime } from "../CommonComp/Functions";
import DetailsNavigation from "../CommonComp/DetailsNavigation";
import AccountStore from "../../Stores/AccountStore";
import { RESTRICTED_ACCESS_CONTRACTS } from "../Contracts/constants";

const ContractList = () => {
  const { contractOverviewList, contractPRList } = useContext(ContractStore);
  const { accountData, accountHasContent } = useContext(AccountStore);

  const [list, setList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setList(
      [...contractOverviewList].sort(
        (a, b) => new Date(b.IssueDate) - new Date(a.IssueDate),
      ),
    );
  }, [contractOverviewList]);

  useEffect(() => {
    const kycSuccess = localStorage.getItem("kycStateSuccess");

    if (
      !accountData.KYCVerificationDate &&
      !contractOverviewList.length &&
      contractPRList.length &&
      accountHasContent &&
      accountData.Type === "Individual" &&
      !kycSuccess
    ) {
      navigate("/oauth/kyc/request_for_information_update");
    }
  }, [accountData, contractOverviewList, contractPRList, accountHasContent]);

  return (
    <>
      {list.length > 0 ? (
        <>
          <div className="nn-container table-container home-contracts">
            <Fragment>
              <div className="table-label-with-navigation">
                <h2 className="table-label">{t("global.my-contracts")}</h2>
                <Link
                  onClick={() => navigate("/myContracts")}
                  text={t("global.see-all-your-contracts")}
                  variant="PrimaryStandAloneStrong"
                ></Link>
              </div>
              <Table
                localeText={{ noRowsLabel: t("global.no-results-found") }}
                columns={[
                  {
                    field: "ProductName",
                    flex: 1,
                    maxWidth: 300,
                    headerName: t("contracts.product-name"),
                    sortable: false,
                  },
                  {
                    field: "DisplayNumber",
                    flex: 1,
                    maxWidth: 280,
                    headerName: t("contracts.insurance-number"),
                    sortable: false,
                  },
                  {
                    field: "ExpiredDate",
                    flex: 1,
                    maxWidth: 220,
                    headerName: t("contracts.expiration-date"),
                    sortable: false,
                    renderCell: (params) => {
                      if (
                        params.row.ExpiredDate == null ||
                        params.row.IsGroupPolicy === true ||
                        params.row.IsGroupPensionPolicy
                      ) {
                        return "-";
                      }
                      const valueFormatted = setDateFormatForDateTime(
                        params.row.ExpiredDate,
                      );
                      return `${valueFormatted}`;
                    },
                  },
                  {
                    field: "Details",
                    headerName: "",
                    flex: 1,
                    maxWidth: 130,
                    align: "right",
                    sortable: false,
                    renderCell: ({ row }) => {
                      const isRestrictedAccess =
                        RESTRICTED_ACCESS_CONTRACTS.some((contract) =>
                          row.Number.includes(contract),
                        );

                      return (
                        <DetailsNavigation
                          details={row}
                          isDisabled={
                            sessionStorage.getItem("support") &&
                            isRestrictedAccess
                          }
                        />
                      );
                    },
                  },
                ]}
                rows={list.map((elem) => {
                  return elem;
                })}
                getRowId={(row) => row.Number}
                disableColumnReorder={true}
              />
            </Fragment>
          </div>
        </>
      ) : (
        <></>
      )}
      {contractPRList.length > 0 ? (
        <>
          <div className="nn-container table-container home-contracts">
            <Fragment>
              <div className="table-label-with-navigation">
                <h2 className="table-label">{"Οι Αιτήσεις μου"}</h2>
              </div>
              <Table
                columns={[
                  {
                    field: "ProductName",
                    flex: 1,
                    maxWidth: 300,
                    headerName: t("contracts.product-name"),
                    sortable: false,
                  },
                  {
                    field: "ApplicationNumber",
                    flex: 1,
                    maxWidth: 280,
                    headerName: t("contract.application-number"),
                    sortable: false,
                  },
                  {
                    field: "ExpiredDate",
                    flex: 1,
                    maxWidth: 220,
                    headerName: t("contracts.expiration-date"),
                    sortable: false,
                    renderCell: () => {
                      return "-";
                    },
                  },
                ]}
                rows={contractPRList.map((elem) => {
                  return elem;
                })}
                getRowId={(row) => row.Number}
                disableColumnReorder={true}
              />
            </Fragment>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContractList;
