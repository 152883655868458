import { Icon, SpinnerLoader } from "@nn-design-system/react-component-library";
import React, { useState } from "react";
import agent from "../../../Api/agent";
import { downloadDocument } from "../../CommonComp/Functions";

export default function DownloadClaimHRDocument({ params, contract, setShowModal }) {
  const [isLoading, setIsLoading] = useState(false);

  const downloadClaimDocument = (claimNo) => {
    setIsLoading(true);
    const claimsDocument = agent.hr.getPolicycPayemntsHR({
      contractNumber: contract,
      invoiceNumber: claimNo,
      invoiceType: "ClaimPayment",
    });

    claimsDocument
      .then(async (result) => {
        if (result) {
          await downloadDocument(result[0]?.DocumentLink, result[0]?.Description);
        } else {
          setShowModal(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "error payments response");
      });
  };

  return (
    <a className="active" onClick={() => downloadClaimDocument(params.row.ClaimPaymentNo)}>
      {isLoading ? <SpinnerLoader variant="Medium" /> : <Icon name="ArrowDownLine" variant={"Medium"} color="#EE7F00" />}
    </a>
  );
}
