import React, { useState } from "react";
import { Paragraph, Table } from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import { PaymentsSelectedDetailPanelHR } from "./PaymentsSelectedDetailPanelHR";
import {
  setDateFormatForDateTime,
  stringToAmount,
} from "../../CommonComp/Functions";
import { useTranslation } from "react-i18next";
const PaymentsSelectedTableHR = ({ element, contract }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const columns = [
    {
      field: "InvoiceNumber",
      headerName: t("hr.document-number"),
      flex: 1,
      headerAlign: "left",
      align: "left",
      sortable: false,
      headerClassName: "textAlignLeft",
    },
    {
      field: "InstallmentFrom",
      headerName: t("hr.billing-period-date"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1.4,
      sortable: false,
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
      renderCell: (params) => {
        return (
          <div className="">
            <Paragraph hasMargin={false}>
              {setDateFormatForDateTime(params.row.InstallmentFrom)} -
            </Paragraph>
            <Paragraph hasMargin={false}>
              {setDateFormatForDateTime(params.row.InstallmentTo)}
            </Paragraph>
          </div>
        );
      },
    },
    {
      field: "InstallmentGrossPremium",
      headerName: t("hr.charge-amount"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
      headerClassName: "textAlignLeft",
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "PaidAmount",
      headerName: t("online.payment.payment-amount"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return stringToAmount(props.value);
      },
    },
    {
      field: "PaymentDate",
      headerName: t("hr.payment-date-pension-table"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => {
        return setDateFormatForDateTime(props.value);
      },
    },
    {
      field: "StatusDescription",
      headerName: t("global.condition"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
  ];
  const rows = element.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      mt="30px"
      hasPagination
      hasFooterPagination
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      itemsPerPageProps={{
        itemsPerPage: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
        ],
        name: "itemsPerPage",
        onChange: (test) => {
          setItemsPerPage(test);
        },
        value: itemsPerPage,
      }}
      page={pageNumber}
      pageNavigationProps={{
        name: "pageNavigation",
        onChange: (value) => {
          setPageNumber(value);
        },
        value: pageNumber,
      }}
      pageSize={itemsPerPage}
      getDetailPanelContent={(rowData) => (
        <div className="">
          <PaymentsSelectedDetailPanelHR
            contract={contract}
            detail={rowData.row}
            id={rowData.id}
          />
        </div>
      )}
      getDetailPanelHeight={() => {
        return "auto";
      }}
    ></Table>
  );
};

export default observer(PaymentsSelectedTableHR);
