import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Label,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
const TimeoutModal = () => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(true);
  const navigate = useNavigate();

  const closemodal = () => {
    setModalShow(false);
    navigate(`/home`);
  };

  return (
    <PrefabModal
      isOpen={modalShow}
      headingText={
        <p className="header-cookie">{t("commons.automatic-disconnection")}</p>
      }
      width="500px"
      height="auto"
      onClose={closemodal}
    >
      <div title={t("commons.close-msg")} className="disconnect-timeout">
        <Label
          title={t("commons.close-msg")}
          text={t("commons.inactive-alert-msg")}
          variant="SubLabel"
        />
        <Button
          mt={"15px"}
          title={t("commons.close-msg")}
          testId="button"
          type="Button"
          variant="PrimaryMedium"
          onClick={closemodal}
        >
          {t("commons.back-to")} myΝΝ portal
        </Button>
      </div>
    </PrefabModal>
  );
};

export default TimeoutModal;
