import { Label, Paragraph } from "@nn-design-system/react-component-library";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ConvertDecimalToString,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import HoverPopover from "../CommonComp/HoverPopover";
import ElementDownload from "../Document/ElementDownload";

export const PaymentsList = ({ paymentElem, documentDetails }) => {
  const { t } = useTranslation();
  return (
    <div className="list-container">
      <div>
        <Label hasMargin={false} text={t("file-upload.document-type")} />
        <Paragraph hasMargin={false}>
          {paymentElem.InstallmentFrom
            ? setDateFormatForDateTime(paymentElem.InstallmentFrom)
            : "-"}
        </Paragraph>
      </div>
      <div>
        <Label hasMargin={false} text={t("document.date-issue")} />
        <Paragraph hasMargin={false}>
          {paymentElem.PaymentDate
            ? setDateFormatForDateTime(paymentElem.PaymentDate)
            : "-"}
        </Paragraph>
      </div>
      <div>
        <Label text={t("document.download")} />
        <Paragraph hasMargin={false}>
          <b>A.</b> {paymentElem.PaymentCodeA}
        </Paragraph>
        {paymentElem.PaymentCodeB ? (
          <Paragraph hasMargin={false}>
            <b>B.</b> {paymentElem.PaymentCodeB}
          </Paragraph>
        ) : null}
      </div>
      <div>
        <Label text={t("online.payment.payment-amount")} />
        <Paragraph hasMargin={false}>
          {ConvertDecimalToString(paymentElem.PaidAmount)}
        </Paragraph>
      </div>
      <div>
        <Label text={t("payments.dosage-amount")} />
        <Paragraph hasMargin={false}>
          {ConvertDecimalToString(paymentElem.InstallmentGrossPremium)}
        </Paragraph>
        {documentDetails ? (
          <span className="installment-download-icon">
            <HoverPopover
              popoverElement={
                <ElementDownload
                  DocumentLink={documentDetails?.paymentDocLink}
                  DocumentType={documentDetails?.paymentDocType}
                  IconVariant="Small"
                />
              }
            >
              <div className="installment-download-popover-text">
                {t("payments.notice-payment")}
              </div>
            </HoverPopover>
          </span>
        ) : (
          <></>
        )}
      </div>
      <div>
        <Label text="Ποσό Δόσης" />
        <Paragraph hasMargin={false}>{paymentElem.StatusDescription}</Paragraph>
      </div>
    </div>
  );
};
