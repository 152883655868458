import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  setDateFormatForDateTime,
  ConvertDecimalToString,
} from "../CommonComp/Functions";
useTranslation;
import { Label, Paragraph } from "@nn-design-system/react-component-library";

const ContractSelectedCoverageRow = (props) => {
  const { t } = useTranslation();
  const [clauses, setClauses] = useState([]);
  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    if (props.Clauses.length) {
      let filteredClauses = props.Clauses.filter(
        (ClauseElem) =>
          ClauseElem.ClientNumber === props.insured.ClientId &&
          props.element.CoverageCode === ClauseElem.CoverageCode,
      );

      setClauses(filteredClauses);
    }

    if (props.Benefits.length) {
      let filteredBenefits = props.Benefits.filter(
        (BenefitElem) =>
          BenefitElem.ClientId === props.insured.ClientId &&
          props.element.CoverageCode === BenefitElem.CoverageCode,
      );

      setBenefits(filteredBenefits);
    }
  }, []);

  const checkForValues = (amount, percentage, classORNumberOfVisits) => {
    if (amount != 0) {
      return (
        <Paragraph mt="10px" mb="10px" ml="8px" hasMargin={false}>
          {ConvertDecimalToString(amount)}
        </Paragraph>
      );
    } else if (percentage !== "") {
      return (
        <Paragraph mt="10px" mb="10px" ml="8px" hasMargin={false}>
          {percentage} %
        </Paragraph>
      );
    } else if (classORNumberOfVisits !== "" && classORNumberOfVisits !== "0") {
      return (
        <Paragraph mt="10px" mb="10px" hasMargin={false}>
          {classORNumberOfVisits}
        </Paragraph>
      );
    } else if (amount === 999999999.99) {
      return <span>{t("contract.unlimited")}</span>;
    } else if (amount === 111111111.11) {
      return <span>0.00</span>;
    } else {
      return <Paragraph mt="10px" mb="10px" hasMargin={false}></Paragraph>;
    }
  };

  const children = () => {
    return (
      <div className="collapsible-children-contract">
        <Label text={t("contract.allowance")} />
        {props.insured && benefits.length
          ? benefits.map((benefitElement, i) => {
              return (
                <Fragment key={i}>
                  <div className="contract-description">
                    <div>
                      <Paragraph mt="10px" mb="10px" hasMargin={false}>
                        {benefitElement.Description}
                      </Paragraph>
                    </div>
                    <div>
                      {checkForValues(
                        benefitElement.Amount,
                        benefitElement.Percentage,
                        benefitElement.ClassORNumberOfVisits,
                      )}
                    </div>
                  </div>
                </Fragment>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <Fragment>
      {children()}

      <>
        {clauses.length ? (
          <div className="collapsible-children-contract">
            <Label text={t("contract.special-conditions")} />
            {clauses.map((ClauseElem, i) => {
              return (
                <Fragment key={i}>
                  <div className="special-terms-desc active">
                    <div>{ClauseElem.Description}</div>
                  </div>
                  <span className="period">
                    <span className="period-from">
                      {t("contract.from-effective-date")}{" "}
                    </span>
                    {setDateFormatForDateTime(ClauseElem.DateFrom)}
                    <span className="period-to"> {t("contract.up-to")} </span>
                    {setDateFormatForDateTime(ClauseElem.DateTo)}
                  </span>
                </Fragment>
              );
            })}
          </div>
        ) : null}
      </>
    </Fragment>
  );
};

export default ContractSelectedCoverageRow;
