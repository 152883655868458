import React from "react";
import { observer } from "mobx-react-lite";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ConvertDecimalToStringWithCurrency } from "../CommonComp/Functions";
import ContractSelectedDocumentTable from "./ContractSelectedDocumentTable";
import ContractSelectedOseka from "./ContractSelectedOseka";
import ContractSelectedRelativeDocuments from "./ContractSelectedRelativeDocuments";

const InvestmentAccountContentForVisibleFields = (props) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <>
        <div className="selected-contract-account-investment">
          <div className="account-value">
            {props.contractDetails.VisibleFields.includes("AccountValue") ? (
              <div>
                <p>{t("contract.account-value")}</p>
                <span>
                  {ConvertDecimalToStringWithCurrency(
                    props.contractDetails.AccountValue,
                    props.contractDetails.CurrencyCode,
                  )}
                </span>
              </div>
            ) : null}
            {props.contractDetails.VisibleFields.includes("SurrenderValue") ? (
              <div>
                <p>{t("contract.takeover-value")}</p>
                <span>
                  {ConvertDecimalToStringWithCurrency(
                    props.contractDetails.SurrenderValue,
                    props.contractDetails.CurrencyCode,
                  )}
                </span>
                <span className="takeover-value">
                  {t("contract.redemption-penalties-msg")}
                </span>
              </div>
            ) : null}

            {props.contractDetails.VisibleFields.includes("ExpiredAmount") ||
            props.contractDetails.VisibleFields.includes(
              "IsGuaranteeProgrammCapital",
            ) ? (
              props.contract.IsNonExpiredAmount ? null : (
                <div>
                  {props.contract.IsGuaranteeExpiredAmount ? (
                    <>
                      {props.contract.IsGuaranteeProgrammCapital ? (
                        <p>{t("contract.guaranteed-program-capital")}</p>
                      ) : (
                        <p>{t("contract.guaranteed-maturity-capital")}</p>
                      )}
                      <span>
                        {ConvertDecimalToStringWithCurrency(
                          props.contractDetails.ExpiredAmount,
                          props.contractDetails.CurrencyCode,
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <p>{t("contract.closing-capital")}</p>
                      <span>
                        {ConvertDecimalToStringWithCurrency(
                          props.contractDetails.ExpiredAmount,
                          props.contractDetails.CurrencyCode,
                        )}
                      </span>
                    </>
                  )}
                </div>
              )
            ) : null}
          </div>

          {props.contractDetails.VisibleFields.includes(
            "OsekaInvestmentLinks",
          ) ? (
            <ContractSelectedOseka OsekaInvestmentLinks={props.OsekaLinks} />
          ) : null}

          {props.contractDetails.VisibleFields.includes("RelativeDocuments") ? (
            <ContractSelectedRelativeDocuments
              filteredDoc={props.filteredDoc}
              adHoc={props.adHoc}
              productCategory={props.contract.ProductCode}
            />
          ) : null}

          {props.contractDetails.VisibleFields.includes("Transactions") ? (
            <ContractSelectedDocumentTable
              Transactions={props.contractDetails.Transactions}
            />
          ) : null}
        </div>
      </>
    </Fragment>
  );
};

export default observer(InvestmentAccountContentForVisibleFields);
