import { Checkbox, Icon, Circle } from "@nn-design-system/react-component-library";
import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import InboxStore from "../../Stores/InboxStore";

const InboxMessageElement = ({ elem, activeClass, messageArrayWithPag, handleSelectMessage }) => {
  const inboxStore = useContext(InboxStore);
  const { importantMessageCount, messageCategories } = inboxStore;
  const [checkboxState, setCheckboxState] = React.useState("NotChecked");

  React.useEffect(() => {
    setCheckboxState("NotChecked");
  }, [elem]);

  const goToDetails = (val) => {
    clickToDetails(val);
    if (val.IsRead === false) {
      inboxStore.headerMessageCount(importantMessageCount - 1);
      inboxStore.isReadPost(val.DocumentId, "read", val.ClassName);
      changeIsReadStatus(val);
    }
  };

  const changeIsReadStatus = (val) => {
    messageArrayWithPag.map((element) => {
      if (element.NodeAlias === val.NodeAlias) {
        element.IsRead = true;
      }
    });
  };

  const navigate = useNavigate();

  const clickToDetails = (detail) => {
    navigate(`/myInbox/details`, { state: { ...detail } });
  };

  const getCategoryName = (inputCategoryId) => {
    if (messageCategories) {
      let category = messageCategories.find((c) => c.NodeId === inputCategoryId);
      return category.Title;
    }
  };

  const handleSelection = (e) => {
    if (e.target.checked) {
      setCheckboxState("Checked");
    } else {
      setCheckboxState("NotChecked");
    }
    handleSelectMessage({ message: elem, value: e.target.checked });
  };

  const checkIfCanBeArchived = (val) => {
    let inputDate = new Date(val);
    let today = new Date();
    if (inputDate < today) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <tr>
      <td>
        <div className="checkBox-container">
          {checkIfCanBeArchived(elem.MessageExpireWhen) && !elem.IsArchived ? (
            <Checkbox variant="Small" onChange={handleSelection} dataState={checkboxState} labelText="" />
          ) : (
            <></>
          )}
          <div className="sm">
            <span className={`datetime ${activeClass}`}>
              {(elem.dateTime = new Date(elem.MessagePublishedWhen).toLocaleDateString("el-GR"))}
            </span>
            <span className="category">{(elem.category = getCategoryName(elem.NodeParentId))}</span>
          </div>
        </div>
      </td>
      <td onClick={() => goToDetails(elem)} className="title-name-container">
        <span className={`title ${activeClass}`}>
          {elem.IsAlert ? (
            <div>
              <Icon ml={"-28px"} mb={"-30px"} mt={"8px"} color="#EA650D" name="Alert" variant="Small" />
            </div>
          ) : elem.IsRead ? null : (
            <Circle ml={"-28px"} mb={"-30px"} mt={"8px"} variant="SmallAttentional"></Circle>
          )}
          {elem.Title}
        </span>
        <span className={`${activeClass}`}>{elem.Description}</span>
      </td>
      <td>
        <span className="category">{(elem.category = getCategoryName(elem.NodeParentId))}</span>
      </td>
      <td>
        <span className={`datetime ${activeClass}`}>
          {(elem.dateTime = new Date(elem.MessagePublishedWhen).toLocaleDateString("el-GR"))}
        </span>
      </td>
    </tr>
  );
};

export default InboxMessageElement;
