import React from "react";
import { VictoryBar, VictoryChart, VictoryAxis } from "victory";
import { LIGHT_GREY, colorsList } from "../../HR/Utils/Constants";
import { PortfolioChartLegend } from "./PortfolioChartLegend";
import { Paragraph } from "@nn-design-system/react-component-library";

const PortfolioBarChart = ({ contracts }) => {
  const data = React.useMemo(() => {
    return contracts
      .filter(({ UnitAllocationPercentage }) => UnitAllocationPercentage > 0)
      .map((contract, index) => ({
        x: index + 1,
        y: contract.UnitAllocationPercentage,
        color: colorsList[index],
        legendName: contract.FundName,
        legendPercentage: contract.UnitAllocationPercentage,
      }));
  }, [contracts]);

  if (!data.length) {
    return null;
  }

  return (
    <section className="barChartContainer">
      <header>
        <span className="barChartTitle">{`${contracts[0].ProductDescription} - Επένδυση Ασφαλίστρων`}</span>
        <Paragraph hasMargin={false} mt="8px">
          Ακολουθεί διάγραμμα που παρουσιάζει την κατανομή των Επενδύσεων για τα
          τακτικά Ασφάλιστρα /ή για τα Ασφάλιστρα των συμβολαίων εφάπαξ
          καταβολής, όπως έχει δηλωθεί στην εταιρία σύμφωνα με την Αίτηση
          Ασφάλισης ή με την τελευταία και πιο πρόσφατη αίτηση τροποποίησης. Για
          τα προϊόντα Smart Plan/Orange Genius/Orange Genius Plus Campaign/Smart
          Premium/Smart Move το διάγραμμα παρουσιάζει την κατανομή των
          Επενδύσεων όπως γίνεται με βάση την επενδυτική στρατηγική του
          προγράμματος
        </Paragraph>
      </header>
      <VictoryChart
        maxDomain={{ y: 100 }}
        domainPadding={50}
        padding={{ top: 20, bottom: 50, right: 20 }}
        width={800}
        height={150 + data.length * 60}
      >
        <VictoryAxis tickFormat={() => ""} />
        <VictoryBar
          horizontal
          barWidth={40}
          cornerRadius={{ topLeft: 4, topRight: 4 }}
          style={{
            data: {
              fill: ({ datum }) => datum.color,
            },
          }}
          data={data}
        />
        <VictoryAxis
          domain={[0, 100]}
          dependentAxis
          tickFormat={(x) => `${x}%`}
          style={{
            grid: {
              fill: LIGHT_GREY,
              stroke: LIGHT_GREY,
              pointerEvents: "",
              strokeWidth: 0.5,
            },
          }}
        />
      </VictoryChart>
      <PortfolioChartLegend data={data} />
    </section>
  );
};

export { PortfolioBarChart };
