import React from "react";
import { useTranslation } from "react-i18next";
import img from "../../assets/index";

const OnlinePaymentPromo = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <img src={img.online_payments} alt="registration" />
      </div>
      <div>
        <h1>
          <span className="light">{t("online.payment.pay")} </span>
          <span className="normal">{t("online.payment.insurance")} </span>
          <span className="dark">{t("online.payment.quick-safe")}</span>
        </h1>
      </div>
      <div>
        <p>{t("online.payment.debit-or-credit")}</p>
        <div className="master-visa">
          <img src={img.visa_icon} />
          <img src={img.master_icon} />
        </div>
      </div>
    </>
  );
};

export default OnlinePaymentPromo;
