import { Table } from "@nn-design-system/react-component-library";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import img from "../../../assets/index";
import { setDateFormatForDateTime } from "../../CommonComp/Functions";
import { CoverageTableHRNN } from "./CoverageTableHRNN";
import { useTranslation } from "react-i18next";

const CoverageTableHR = ({ element }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);
  const columns = [
    {
      field: "a",
      headerName: t("hr.insured-singular"),
      flex: 2,
      headerAlign: "left",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="name-cell-container">
            <div className="name-icon">
              <b className="hidden">{`${params.row.FirstName} ${params.row.LastName}`}</b>
              <img
                src={
                  params.row.Gender === "M"
                    ? img.male_gender
                    : img.female_gender
                }
                alt="call_icon"
              />
            </div>
            <span>{params.row.Email ? params.row.Email : "-"}</span>
          </div>
        );
      },
    },
    {
      field: "EmployeeNumber",
      headerName: t("hr.employee"),
      headerAlign: "left",
      align: "left",
      headerClassName: "textAlignLeft",
      flex: 1,
      sortable: false,
    },
    {
      field: "TaxNumber",
      headerName: t("hr.vat-number"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "BirthDate",
      headerName: t("hr.birth-date"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
      valueFormatter: (props) => setDateFormatForDateTime(props.value),
    },
    {
      field: "InsuredType",
      headerName: t("hr.insured-type"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1.7,
      sortable: false,
      cellClassName: () => "insurence-type-hr-cell",
      renderCell: (params) => {
        return (
          <span>
            {params.value ? params.value : t("hr.main-insured")}
            {!params.value && (
              <span className="small-no-type">
                {" "}
                {t("hr.dependent-members")}: {params.row.DependigPersonCount}
              </span>
            )}
          </span>
        );
      },
    },
    {
      field: "IsActive",
      headerName: t("hr.sign-up-nn"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1.2,
      sortable: false,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      },
    },
  ];
  const rows = element.map((e, i) => {
    return {
      id: i,
      ...e,
    };
  });
  return (
    <Table
      localeText={{ noRowsLabel: t("global.no-results-found") }}
      hasPagination
      hasFooterPagination
      rows={rows}
      columns={columns}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      hasAutoHeight
      itemsPerPageProps={{
        itemsPerPage: [
          {
            text: "5",
            value: 5,
          },
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "50",
            value: 50,
          },
        ],
        name: "itemsPerPage",
        onChange: (test) => {
          setItemsPerPage(test);
        },
        value: itemsPerPage,
      }}
      page={pageNumber}
      pageNavigationProps={{
        name: "pageNavigation",
        onChange: (value) => {
          setPageNumber(value);
        },
        value: pageNumber,
      }}
      pageSize={itemsPerPage}
      getDetailPanelContent={(rowData) => {
        if (!!rowData.row.CoverageList?.length) {
          return (
            <div className="coverage-hr-table">
              <CoverageTableHRNN data={rowData.row.CoverageList} />
            </div>
          );
        }
      }}
      getDetailPanelHeight={() => {
        return "auto";
      }}
    ></Table>
  );
};
export default observer(CoverageTableHR);
