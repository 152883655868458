import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, Select, Table } from "@nn-design-system/react-component-library";
import PaymentStore from "../../Stores/PaymentStore";
import DocumentsStore from "../../Stores/DocumentsStore";
import "../../Css/CommonCss/Filters.css";
import "../../Css/payments-table.css";
import { PaymentsTableNN } from "./PaymentsTableNN";
import { useWindowDimensions } from "../../Hooks/useWindowDimensions";
import { PaymentsList } from "./PaymentsList";
import { useTranslation } from "react-i18next";

const PaymentsTable = () => {
  const { t } = useTranslation();
  const paymentStore = useContext(PaymentStore);
  const { paymentList } = paymentStore;
  const [newPaymentList, setNewPaymentList] = useState(null);
  const [finalPaymentList, setFinalPaymentList] = useState(paymentList);
  const documentStore = useContext(DocumentsStore);
  const { documentList } = documentStore;
  const [dropDate, setDropDate] = useState("");
  const [dropStatus, setDropStatus] = useState("");
  const [dropDownDateOptions, setDropDownDateOptions] = useState([]);
  const [dropDownStatusOptions, setDropDownStatusOptions] = useState([]);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 767) return setIsSmallSize(true);
    setIsSmallSize(false);
  }, [width]);

  useEffect(() => {
    paymentListPerCon(paymentList);
  }, [paymentList]);

  useEffect(() => {
    if (newPaymentList) {
      dropDownMenuArray();
      dropDownMenuArray2();
    }
  }, [newPaymentList]);

  const columns = [
    {
      field: "ProductName",
      headerName: t("contracts.product-name"),
      flex: 1.7,
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      sortable: false,
    },
    {
      field: "DisplayNumber",
      headerName: t("document.insurance-policy-number"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "PaymentFrequency",
      headerName: t("payments.frequency-payment"),
      headerAlign: "left",
      headerClassName: "textAlignLeft",
      align: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "PaymentMethod",
      headerName: t("payments.means-payment"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      sortable: false,
    },
  ];

  let rows = paymentList
    ?.map((element, i) => {
      if (element.Payments.length !== 0) {
        if (
          finalPaymentList?.some((p) => p.ContractNumber === element.Number)
        ) {
          return {
            id: i,
            ...element,
          };
        }
      }
    })
    .filter(Boolean);

  useEffect(() => {
    if (dropDate || dropStatus) {
      const newTestArray = newPaymentList.filter((x) => {
        if (x.InstallmentFrom) {
          if (dropDate && !dropStatus) {
            return x.InstallmentFrom.substring(0, 4) === dropDate;
          } else if (!dropDate && dropStatus) {
            return x.StatusDescription === dropStatus;
          } else {
            return (
              x.InstallmentFrom.substring(0, 4) === dropDate &&
              x.StatusDescription === dropStatus
            );
          }
        } else {
          return x.StatusDescription === dropStatus;
        }
      });
      setFinalPaymentList(newTestArray);
    } else {
      setFinalPaymentList(newPaymentList);
    }
  }, [dropDate, dropStatus, newPaymentList, paymentList]);

  const dropDownHandlerDate = (e) => {
    if (e.target.value == "false") {
      setDropDate(false);
    } else {
      setDropDate(e.target.value);
    }
  };

  const dropDownHandlerStatus = (e) => {
    if (e.target.value == "false") {
      setDropStatus(false);
    } else {
      setDropStatus(e.target.value);
    }
  };

  const paymentListPerCon = (objList) => {
    let newArray = [];
    objList.map((elem) => {
      if (elem.Payments.length !== 0) {
        elem.Payments.map((elemOfElem) => {
          newArray.push(elemOfElem);
        });
      }
    });
    setFinalPaymentList(newArray);
    setNewPaymentList(newArray);
  };

  const dropDownMenuArray = () => {
    let unique_values = [];
    if (newPaymentList != null) {
      let Array_values = [
        ...new Set(newPaymentList.map((p) => p.StatusDescription)),
      ];
      Array_values.map((element) => {
        if (element != null) {
          unique_values.push(element);
        }
      });
      var uniqueArrayValues = [...new Set(unique_values)];
    }
    setDropDownStatusOptions(uniqueArrayValues);
  };

  const dropDownMenuArray2 = () => {
    let unique_values = [];
    if (newPaymentList != null) {
      let Array_values = [
        ...new Set(newPaymentList.map((p) => p.InstallmentFrom)),
      ];
      Array_values.map((element) => {
        if (element != null) {
          unique_values.push(element.substring(0, 4));
        }
      });
      var uniqueArrayValues = [...new Set(unique_values)];
    }
    setDropDownDateOptions(uniqueArrayValues);
  };

  function dropDownListItem(array, text) {
    let list = array.map((elementDrop) => {
      return {
        text: elementDrop,
        value: elementDrop,
      };
    });
    list.unshift({ text: text, value: false });
    return list;
  }
  return (
    <div className="nn-container">
      {paymentList && finalPaymentList && documentList ? (
        <>
          <div className="flex-container-nn">
            <div className="payment-select-container">
              <Select
                placeholderText={t("payments.search-period")}
                labelText={t("payments.select-period")}
                name="select"
                onChange={dropDownHandlerDate}
                options={dropDownListItem(
                  dropDownDateOptions,
                  t("payments.all-years"),
                )}
                testId="select"
                value={dropDate}
              />
            </div>

            <div className="payment-select-container">
              <Select
                placeholderText={t("payments.all-payments")}
                labelText={t("payments.status-payment")}
                name="select"
                onChange={dropDownHandlerStatus}
                options={dropDownListItem(
                  dropDownStatusOptions,
                  t("payments.all-payments"),
                )}
                testId="select"
                value={dropStatus}
              />
            </div>
          </div>
          <Table
            localeText={{ noRowsLabel: t("global.no-results-found") }}
            rows={rows}
            density="comfortable"
            columns={columns}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            hasAutoHeight
            getRowHeight={() => "auto"}
            getDetailPanelContent={(rowData) => (
              <>
                {isSmallSize ? (
                  finalPaymentList
                    .filter(
                      (elem) => elem.ContractNumber === rowData.row.Number,
                    )
                    .map((elem, i) => {
                      return <PaymentsList key={i} paymentElem={elem} />;
                    })
                ) : (
                  <PaymentsTableNN
                    data={finalPaymentList.filter(
                      (elem) => elem.ContractNumber === rowData.row.Number,
                    )}
                  />
                )}
              </>
            )}
            getDetailPanelHeight={() => {
              return "auto";
            }}
            hideFooter
          ></Table>

          <div id="payments-table">
            <p className="clarification">{t("payments.updated-payment-msg")}</p>
            <p className="clarification">
              {t("payments.more-info-msg")}{" "}
              <Link
                href="https://www.nnhellas.gr/faq"
                testId="link-test-id"
                text="πατήστε εδώ."
                variant="PrimaryStandAlone"
              />
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default observer(PaymentsTable);
