import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dompurify from "dompurify";
import { observer } from "mobx-react-lite";
import SpecialPagesStore from "../../Stores/SpecialPagesStore";
import UserStore from "../../Stores/UserStore";
import AccountRegLoginHeader from "../Header/AccountRegLoginHeader";

const InfoPages = () => {
  const specialPagesStore = useContext(SpecialPagesStore);
  const { isAuthorized } = useContext(UserStore);
  const { infoPageResponse, infoPublicPageResponse } = specialPagesStore;
  const [content, setContent] = useState();
  const [titleArray, setTitleArray] = useState();
  const [classArray, setClassArray] = useState(["light", "normal", "dark"]);
  const path = useLocation();

  const sanitizer = dompurify.sanitize;

  const setResponseArray = (valArray) => {
    let strigArray = valArray.Title.split(" ");
    if (strigArray.length > 3) {
      let combineArray = [...strigArray];
      strigArray = [];
      if (combineArray.length === 4) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2]);
        strigArray.push(combineArray[3]);
      }
      if (combineArray.length === 5) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2] + " " + combineArray[3]);
        strigArray.push(combineArray[4]);
      }
      if (combineArray.length === 6) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2] + " " + combineArray[3]);
        strigArray.push(combineArray[4] + " " + combineArray[5]);
      }
    }
    return strigArray;
  };

  useEffect(() => {
    if (isAuthorized) {
      specialPagesStore.loadInfoPage(path.pathname);
    }
    if (isAuthorized === false) {
      specialPagesStore.loadInfoPublicPage(path.pathname);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (infoPageResponse) {
      setContent(infoPageResponse);
      let strigArray = setResponseArray(infoPageResponse);
      setTitleArray(strigArray);
    }
  }, [infoPageResponse]);

  useEffect(() => {
    if (infoPublicPageResponse) {
      setContent(infoPublicPageResponse);
      let strigArray = setResponseArray(infoPublicPageResponse);
      setTitleArray(strigArray);
    }
  }, [infoPublicPageResponse]);

  return (
    <>
      {content ? (
        <>
          {isAuthorized === false ? (
            <>
              <div className="nn-container">
                <header>
                  <AccountRegLoginHeader />
                </header>
              </div>
              <div class="nn-container">
                <div id="registration-body-info">
                  <h2 className="modal-title">
                    {titleArray.map((elementTitle, i) => {
                      return (
                        <span key={i} className={classArray[i]}>
                          {elementTitle}{" "}
                        </span>
                      );
                    })}
                  </h2>
                  <div dangerouslySetInnerHTML={{ __html: sanitizer(content.Text, { ADD_ATTR: ["target"] }) }} />
                </div>
              </div>
            </>
          ) : (
            <div class="nn-container">
              <div id="tc-cookies-info">
                <h2 className="modal-title">
                  {titleArray.map((elementTitle, i) => {
                    return (
                      <span key={i} className={classArray[i]}>
                        {elementTitle}{" "}
                      </span>
                    );
                  })}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: sanitizer(content.Text, { ADD_ATTR: ["target"] }) }} />
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default observer(InfoPages);
